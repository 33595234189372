<template>
  <div class="form p-12">
    <div class="f-500">Cargo Order: Your vessel is scheduled to load/discharge the following cargo(es); you are to
      comply with the shore nominated quantity. Authorization from our client is required for any deviation to
      this order.
    </div>

    <div class="f-600 black mt-4 flex justify-between">
      <div></div>
      <div style="height: 30px;">
        <el-button size="small" type="primary" @click="add" :disabled="disabled">Add</el-button>
      </div>
    </div>
    <el-table :data="dataForm.listItem" class="mt-4">
      <el-table-column label="Shore Nomination" min-width="100">
        <template slot-scope="scope">
          <el-input v-model="scope.row.shoreNomination" :disabled="disabled"  size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Grade" min-width="100">
        <template slot-scope="scope">
          <el-input v-model="scope.row.grade" :disabled="disabled"  size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Ship's Nomination" min-width="100">
        <template slot-scope="scope">
          <el-input v-model="scope.row.shipNomination" :disabled="disabled"  size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <i v-if="dataForm.listItem.length>1 && !disabled" class="el-icon-circle-close cursor-pointer fs-15" style="color: red"
             @click="doDel(scope.$index)"></i>
        </template>
      </el-table-column>
    </el-table>
    <div style="color: red;" class="mt-4 mb-4 fs-12">
      *Please fill in at least 1 product and its qualities.
    </div>
    <div class="mt-4 f-500">Remarks:</div>
    <div class="mt-4">
      <el-input v-model="dataForm.aftformCargoNomination.remarks" :disabled="disabled" :maxlength="600" show-word-limit type="textarea"></el-input>
    </div>
    <div class="mt-4 f-500">
      I acknowledge that the shore nominated quantities conform to my owner's/charterer's instructions.
    </div>
    <div class="mt-6 f-600" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{ sign.terminal.name }}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{ sign.terminal.createTime }}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;"/>
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{ sign.barge.name }}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{ sign.barge.createTime }}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;"/>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";

export default {
  name: "CargoNomination",
  props: {
    disabled: {
      type: Boolean
    },
    sign: {
      type: Object
    }
  },
  data() {
    return {
      dataForm: {
        aftformCargoNomination: {}
      }
    }
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    save() {
      const data = {
        ...this.dataForm,
        nominationId: this.params.id,
        aftformCargoNomination: {
          ...this.dataForm.aftformCargoNomination,
          nominationId: this.params.id,
        },
        listItem: this.dataForm.listItem.map(item => {
          return {
            ...item,
            nominationId: this.params.id,
          }
        })
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    doDel(index) {
      this.dataForm.listItem.splice(index, 1)
    },
    add() {
      this.dataForm.listItem.push({})
    },
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 21
      }).then(res => {
        this.dataForm = res.data
        this.dataForm.listItem = this.dataForm.listItem && this.dataForm.listItem.length > 0 ? this.dataForm.listItem : [{}]
      })
    }
  }
}
</script>


<style scoped lang="scss">
.form {
  font-size: 15px;
  color: #82889C;
  background-color: #fff;
}
</style>
