<template>
  <div class="w-full pr-2 pl-2" style="background-color: white; border-radius: 10px;">
    <div class="w-full" style="overflow-x: scroll;">
      <div class="statusLine pl-5 pr-5 pt-10 pb-10 flex justify-between flex-nowrap text-xs">
        <div v-for="(item,index) in list" :key="item.id"  :class="item.type" :style="returnNameStyle(item, index)" class="item cursor-pointer" @click="clickItem(item, index)">
          <div v-if="!item.children" class="name">{{ item.name }}</div>
          <div v-else class="name" style="display: flex;justify-content: space-between">
            <div v-for="itemC in item.children" class="children">
              <div class="circle-children" :class="returnClassChild(itemC)">
                <div class="baby"></div>
                <!--i class="el-icon-check" v-if="statusLineLogic().checkChild( itemC.id )=='hasFinish'"></i-->
              </div>
              {{ itemC.name }}
            </div>
          </div>
          <div class="circle" :class="returnClass(item,index)">
            <!--i class="el-icon-check" v-if="statusLineLogic().check(index)=='hasFinish'"></i-->
            <div class="baby"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatusLine',
  data() {
    return {
      statusObj:{},
      list: [
        {
          name: 'Barging Nomination',
          id: '0',
          size: 'big',
          type: 'top'
        },
        {
          name: 'Barge ETA',
          id: '1',
          size: 'small',
          type: 'btm'
        },
        {
          name: 'Assign Berth',
          id: '2',
          size: 'small',
          type: 'btm'
        },
        {
          name: 'Book',
          id: '2null',
          size: 'small',
          type: 'btm'
        },
        {
          name: 'Before Loading Forms',
          id: '2-1',
          size: 'big',
          type: 'top'
        },
        {
          name: '',
          id: '2-4',
          size: 'small',
          type: 'btm',
          children: [
            {
              id: '2-2',
              name: 'Terminal Sign'
            },
            {
              id: '2-3',
              name: 'Barge Sign'
            }
          ]
        },
        {
          name: 'After Loading Forms',
          id: '2-5',
          size: 'big',
          type: 'top'
        },
        {
          name: '',
          id: '2-8',
          size: 'small',
          type: 'btm',
          children: [
            {
              id: '2-6',
              name: 'Terminal Sign'
            },
            {
              id: '2-7',
              name: 'Barge Sign'
            }
          ]
        },
        {
          name: 'Certificates',
          id: '3',
          size: 'big',
          type: 'top'
        },
        {
          name: 'Terminal Sign CQ',
          id: '3-2',
          size: 'small',
          type: 'btm'
        },
        {
          name: 'Barge Sign CQ',
          id: '3-3',
          size: 'small',
          type: 'btm'
        },
        {
          name: 'Completed',
          id: '4',
          size: 'big',
          type: 'top'
        },
      ],
      oneList: [],
      status:'-1',
    }
  },
  props:{
    value:{
      type:String,
      default:'-1'
    },
    loadingType:{
      type: Number,
      default: 0
    },
    signType: {
      type: Number,
      default: 0
    },
    nominationFormStatus: {
      type: Object,
      default: () => ({})
    },
    data:{
      type:Object,
    }
  },
  watch: {
    value:{
      handler(newV){
        if(newV){
          this.status = newV
          this.handleData()
          this.updateList()
        }
      },
      immediate:true
    },
  },
  methods: {
    clickItem(item, index){
      if( this.statusLineLogic().check(index) == ''){
        return
      }
      let obj  ={
        showEdit:false,
        showPdf:false,
        showAfter:false,
      }
      switch (item.id){
        case '0':
          obj = {
            showEdit: true,
            showPdf: false
          }
          break;
        case '1':
          obj = {
            showEdit: true,
            showPdf: false
          }
          break;
        case '2':
          obj = {
            showEdit: true,
            showPdf: false
          }
          break;
        case '2null':
          obj = {
            showEdit: true,
            showPdf: false
          }
        break;
        case '2-1':
          obj = {
            showEdit: false,
            showPdf: false,
            showBefore:true
          }
          break;
        case '2-2':
          obj = {
            showEdit: false,
            showPdf: false,
            showBefore:true
          }
          break;
        case '2-3':
          obj = {
            showEdit: false,
            showPdf: false,
            showBefore:true
          }
          break;
        case '2-4':
          obj = {
            showEdit: true,
            showPdf: false,
            showBefore:true
          }
          break;
        case '2-5':
          obj = {
            showEdit: false,
            showPdf: false,
            showAfter: true
          }
          break;
        case '2-6':
          obj = {
            showEdit: false,
            showPdf: false,
            showAfter: true
          }
          break;
        case '2-7':
          obj = {
            showEdit: false,
            showPdf: false,
            showAfter: true
          }
          break;
        case '2-8':
          obj = {
            showEdit: false,
            showPdf: false,
            showAfter: true
          }
          break;
        case '3':
          obj = {
            showEdit: true,
            showPdf: true,
            // showAfter: true
          }
          break;
        case '3-2':
          obj = {
            showEdit: true,
            showPdf: true,
            // showAfter: true
          }
          break;
        case '3-3':
          obj = {
            showEdit: true,
            showPdf: true,
            // showAfter: true
          }
          break;
        case '4':
          obj = {
            showEdit: true,
            showPdf: false
          }
          break;
      }
      this.$emit('clickItem',obj)
    },
    updateList() {
      this.oneList.map(item => {
        this.list.map(row => {
          if (item.id === row.id) {
            this.$set(row, 'isSuccess', item.isSuccess)
            this.$set(row, 'isActive', item.isActive)
          } else {
            row.children && row.children.map(rowC => {
              if (item.id === rowC.id) {
                this.$set(rowC, 'isSuccess', item.isSuccess)
                this.$set(rowC, 'isActive', item.isActive)
              }
            })
          }
        })
      })
    },
    handleData() {
      this.oneList = []
      this.list.map(item => {
        if (item.children) {
          item.children.map(row => {
            this.oneList.push(row)
          })
        }
        const obj = {
          id: item.id,
          name: item.name,
          size: item.size,
          type: item.type
        }
        this.oneList.push(obj)
      })
      if(this.status==='-1'){
        this.oneList[0].isActive=true
      }else{
        const idx = this.oneList.findIndex(item => item.id === this.status)
        this.oneList.map((item, index) => {
          if (index <= idx) {
            if(this.oneList[idx].id==='2-3'){
              if(item.id==='2-2'){
                item.isSuccess = false
              }else{
                item.isSuccess = true
              }
            }else if(this.oneList[idx].id==='2-7'){
              if(item.id==='2-6'){
                item.isSuccess = false
              }else{
                item.isSuccess = true
              }
            }else{
              item.isSuccess = true
            }
          } else {
            item.isSuccess = false
          }
          item.isActive = false
        })
        if (idx < this.oneList.length - 2) {
          this.oneList[idx + 1].isActive = true
        }
      }
    },
    statusLineLogic() {
      let status = this.status;
      return {
        getOriginalStatus: () => status[0]=='2' ? '2' : ( status[0]=='3' ? '3' : status),
        create: (index) => index==0 ? 'active' : '',
        bargeETA: (index) => index==1 ? 'active' : (index<1 ? 'hasFinish' : ''),
        assignBerth: (index) => index==2 ? 'active' : (index<2 ? 'hasFinish' : ''),
        book: (index) => index==3 ? 'active' : (index<3 ? 'hasFinish' : ''),
        beforeLoadingForms: (index) => {
          let str = ''
          let loadingFormSigned = this.loadingFormSigned()
          switch( index ) {
            case 0:
            case 1: 
            case 2: 
            case 3: str = 'hasFinish'; break;
            case 4: str = loadingFormSigned.before.partial ? 'hasFinish' : 'active'; break;
            case 5: str = loadingFormSigned.before.done ? 'hasFinish' : loadingFormSigned.before.partial ? 'active' : ''; break;
            case 6: str = loadingFormSigned.after.partial ? 'hasFinish' : loadingFormSigned.before.done ? 'active' : ''; break;
            case 7: str = loadingFormSigned.after.done ? 'hasFinish' : loadingFormSigned.after.partial ? 'active' : ''; break;
            case 8: 
            case 9:
            case 10:
          //  case 10: str = this.statusLineLogic().certificates(index); break;
            default: str = '';
          }
          return str
        },
        certificates: (index)  => {
          let str = ''
          let certificateSigned = this.certificateSigned()
          switch( index ) {
            case 0:
            case 1: 
            case 2:
            case 3: str = 'hasFinish';
                    break;
            case 4: 
            case 5: 
            case 6: 
            case 7: str = this.statusLineLogic().beforeLoadingForms(index)
                    break;
            case 8: str = certificateSigned.uploadDocument ? 'hasFinish' : 'active'; break;
            case 9: str = certificateSigned.terminal ? 'hasFinish' : certificateSigned.uploadDocument ? 'active' : ''; break;
            case 10: str = certificateSigned.barge ? 'hasFinish' : certificateSigned.terminal ? 'active' : ''; break;
            default: str = '';
          }
          return str
        },
        completed: (index) => {
          let str
          switch( index ) {
            case 4: 
            case 5: 
            case 6: 
            case 7: str = this.statusLineLogic().beforeLoadingForms(index); break;
            default: str = 'hasFinish';
          }
          return str
        },
        check: (index) => {
          let parent = this.statusLineLogic()
          let orignalStatus = parent.getOriginalStatus()
          let res = ''
          switch( orignalStatus ) {
            case '0': res = parent.bargeETA(index); break;
            case '1': res = parent.assignBerth(index); break;
            case '2': res = this.loadingType == null ? parent.book(index) : parent.beforeLoadingForms(index); break;
            case '3': res = parent.certificates(index); break;
            case '4': res = parent.completed(index); break;
            default: res = parent.create(index)
          }
          return res
        },
        checkChild: (id) => {
          let loadingFormSigned = this.loadingFormSigned()
          if(id == '2-2') {
            return loadingFormSigned.before.terminal ? 'hasFinish' : ''
          } else  if(id == '2-3') {
            return loadingFormSigned.before.barge ? 'hasFinish' : ''
          } else if(id == '2-6') {
            return loadingFormSigned.after.terminal ? 'hasFinish' : ''
          } else if(id == '2-7') {
            return loadingFormSigned.after.barge ? 'hasFinish' : ''
          }
          return ''
        }
      }
    },
    returnClass(item, index) {
      const arr = [item.size, index === this.list.length - 1 ? 'end' : (index === 0 ? 'start' : '')]
      let check = this.statusLineLogic().check(index)
      arr.push(check)

      // only for before loading form first node
      arr.push(item.id == '2-1' ? 'loadingFormStart' : '')
      // for all loading forms (before & after)
      arr.push( item.id.includes('2-') && check != 'hasFinish' ? 'dashed' : '')

      // only for certificate fisrt node
      if(item.id == '3') {
        arr.push('loadingFormEnd')
        let checkPrevious = this.statusLineLogic().check(index - 1)
        if(checkPrevious != 'hasFinish') {
          arr.push('dashed')
        }
      }

      if(this.status == '4') {
        arr.push('completed')
      }
      
      return arr
    },
    returnNameStyle(item, index) {
      let check = this.statusLineLogic().check(index) 
      if (check == 'hasFinish') {
        return {
          fontWeight: 400,
          fontSize: '12px',
          color: '#49454F',
          flex: item.children ? 1.5 : 1
        }
      } else if (check == 'active') {
        return {
          fontWeight: 500,
          fontSize: '14px',
          color: '#1D1B20',
          flex: 1.5
        }
      }
      return {
        fontWeight: 400,
        fontSize: '12px',
        color: '#8F8F8F',
        flex: item.children ? 1.5 : 1
      }
    },
    returnClassChild(item) {
      return this.statusLineLogic().checkChild( item.id );
    },
    loadingFormSigned() {
      let obj = {
        before: {
          terminal: this.loadingType != null && ( this.loadingType == 2 || this.loadingType > 3 ),
          barge: this.loadingType != null && ( this.loadingType == 3 || this.loadingType > 3 ),
          partial: false,
          done: false,
        },
        after: {
          terminal: this.loadingType != null && ( this.loadingType == 6 || this.loadingType > 7 ),
          barge: this.loadingType != null && ( this.loadingType == 7 || this.loadingType > 7 ),
          partial: false,
          done: false,
        }
      }
      obj.before.partial = obj.before.terminal || obj.before.barge
      obj.before.done = obj.before.terminal && obj.before.barge
      obj.after.partial = obj.after.terminal || obj.after.barge
      obj.after.done = obj.after.terminal && obj.after.barge
      return obj
    },
    certificateSigned() {
      let obj = {
        uploadDocument: this.signType > 0,
        terminal: this.signType > 1,
        barge: this.signType == 3,
      }
      return obj
    },
  }
}
</script>
<style scoped lang="scss">
.statusLine {
  min-width: 1500px;
  background-color: white;
  align-items: center;
  .item {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    height: 20px;
    color: #CFCFCF;

    .name {
      text-wrap: nowrap;
      width: 100%;
      text-align: center;
      position: absolute;
      left: 0;
      top: calc(-100% - 5px);
    }

    .circle {
      width: 20px;
      height: 20px;
      border: 4px solid #CFCFCF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 12px;
        color: #fff;
      }

      .baby {
        width: 0px;
        height: 0px;
      }
    }

    .circle.hasFinish {
      border: 2px solid #1CBB19;
    }

    .circle.active {
      border: 2px solid #008CDB;
    }

    .circle:not(.end)::after {
      content: '';
      display: block;
      width: calc(50% - 10px);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(12px) translateY(-50%);
      height: 2px;
      background-color: #CFCFCF;
      z-index: 999;
      border-radius: 4px;
    }

    .small:not(.end)::after {
      transform: translateX(10px) translateY(-50%);
    }

    .circle:not(.start)::before {
      content: '';
      display: block;
      width: calc(50% - 10px);
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateX(-12px) translateY(-50%);
      height: 2px;
      background-color: #CFCFCF;
      z-index: 999;
      border-radius: 4px;
    }

    .small:not(.start)::before {
      transform: translateX(-10px) translateY(-50%);
    }

    .hasFinish::after {
      background-color: #1CBB19 !important;
    }

    .hasFinish::before {
      background-color: #1CBB19 !important;
    }

    .active::before {
      background-color: #1CBB19 !important;
    }

    // for loading form (before & after) portion
    // dashed line
    .dashed::before, .dashed::after {
      background-color: #FFFFFF !important;
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #CFCFCF, #CFCFCF);
      background-size: 15px 2px, 100% 2px;
    }
    
    .dashed.completed::before, .dashed.completed::after {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #1CBB19, #1CBB19);
      background-size: 15px 2px, 100% 2px;
    }

    .active.dashed::before {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #1CBB19, #1CBB19);
      background-size: 0px 2px, 100% 2px;
    }

    .loadingFormStart.dashed::before, .loadingFormEnd.dashed::after {
      background-size: 0px 2px, 100% 2px;
    }

    .loadingFormEnd.dashed::before {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #CFCFCF, #CFCFCF);
      background-size: 15px 2px, 100% 2px;
    }

    .loadingFormEnd.dashed.completed::before {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #1CBB19, #1CBB19);
      background-size: 15px 2px, 100% 2px;
    }

    .hasFinish.loadingFormEnd.dashed::after {
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #1CBB19, #1CBB19);
      background-size: 0px 2px, 100% 2px;
    }

    .big {
      width: 25px;
      height: 25px;

      i {
        font-size: 14px;
      }
    }

    .hasFinish {
      border-color: #1CBB19;
      background-color: #1CBB19;

      .baby {
        width: 70%;
        height: 70%;
        background-color: white;
        border-radius: 50%;
      }
    }

    .active {
      border-color: 2px solid #008CDB;
      background-color: #008CDB;

      .baby {
        width: 90%;
        height: 90%;
        border: 2px solid white;
        background-color: #008CDB;
        border-radius: 50%;
      }
    }
  }

  .item.btm {
    .name {
      top: unset;
      bottom: calc(-100% - 5px);
    }
  }
}

.children {
  display: flex;
  align-items: center;

  .circle-children {
    width: 12px;
    height: 12px;
    border: 2px solid #CFCFCF;
    border-radius: 50%;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #fff;
    }

    .baby {
      width: 70%;
      height: 70%;
      background-color: white;
      border-radius: 50%;
    }
  }

  .circle-children.active {
    border-color: transparent;
    background-color: #008CDB;
  }

  .isActive.hasFinish {
    border-color: transparent;
    background-color: #1CBB19;
  }

  //.circle-children.big {
  //  width: 20px;
  //  height: 20px;
  //}
}
</style>
