<template>
  <el-dialog
    class="successDialog"
    :visible.sync="show"
    :width="isMobileScreen?'90%':'420px'"
    :show-close="false"
  >
    <div class="flex flex-col items-center">
      <p class="message">{{ message }}</p>
    </div>
    <div class="formtext">
      <el-form ref="form" :rules="rules" :model="formDate" label-width="100px">
        <el-form-item
          label-position="top"
          label-width="100"
          :label="this.label"
          prop="reason"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 10 }"
            v-model="formDate.reason"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex justify-center mt-5">
      <el-button
        v-if="confirmvisible"
        size="small"
        type="primary"
        @click="Confirm"
        >{{ $t("btn.confirm") }}</el-button
      >
      <el-button size="small" class="clo" type="primary" @click="close">{{
        $t("btn.close")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { cancelbarging, modificationform } from "@/services/user";
export default {
  props: {
    // 船舶的传值
    createdtable: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isMobileScreen: {
      type: Boolean,
      default: false
    },

  },
  data() {
    return {
      message: "Confirm to cancel barging nomination?",
      label: "Reason for cancelling",
      show: false,
      userTypes: "",
      formDate: {
        reason: "",
      },
      rules: {
        reason: [{ required: true, message: "", trigger: "blur" }],
      },
      confirmvisible: true,
    };
  },
  created() {
    this.userTypes = this.$store.state.userInfo.userType;
  },
  methods: {
    open(val) {
      this.show = true;
    },
    Confirm() {
      if (this.createdtable.status == 4) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            modificationform({
              id: this.$route.query.id,
              reason: this.formDate.reason,
            }).then((res) => {
              if (res.code == 200) {
                this.$emit("revocat", this.formDate);
                this.close();
              }
            });
          } else {
            return false;
          }
        });
      } else {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            cancelbarging({
              id: this.$route.query.id,
              reason: this.formDate.reason,
            }).then((res) => {
              if (res.code == 200) {
                this.$message.success(this.$t("status.success"));
                this.$emit("revocat", 1);
                this.close();
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    close() {
      this.show = false;
      // this.$emit("upup", this.userTypes);
    },
  },
};
</script>

<style lang="scss">
.successDialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog {
    border-radius: 10px;
  }
  .formtext {
    margin-top: 20px;
  }
  .message {
    font-size: 18px;
    text-align: center;
    color: #82889c;
    margin-top: 10px;
    word-break:keep-all;
  }
  .clo {
    background: #fff;
    color: #008CDB;
  }
}
</style>
