<template>
  <div class="p-4 form xl:p-12" style="background-color:#fff;">
    <div>
      <!-- 第一部分 -->
      <div>
        Part 5A - Tanker and Terminal - Pre-transfer Conference
      </div>

      <div class="mt-4">
        <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
        <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
      </div>
      
      <!-- 表格 -->
      <el-table :data="list5A" :header-cell-style="{ background:'#F2F6FC' }" class="mt-4" row-key="item">
        <el-table-column align="center" label="Item" prop="formItem" width="80"></el-table-column>
        <el-table-column label="Check" prop="formDescription">
          <template slot-scope="scope">
            <span style="color:red" v-if="! ['38','39','40','47'].includes(scope.row.formItem)">*</span>{{scope.row.formDescription}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="Terminal" prop="checkTerminal" width="100">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checkTerminal" :disabled="$store.state.userInfo.role!=='11'||disabled"
                         false-label="0" true-label="1"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Tanker" prop="checkTanker" width="100">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checkTanker" :disabled="$store.state.userInfo.role!=='8'||disabled"
                         false-label="0" true-label="1"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Remarks" prop="remarks" width="300">
          <template slot-scope="scope">
            <template
                v-if="scope.row.formDescription==='Cargo tank cleaning requirements, including crude oil washing (COW), are agreed.'">
              <span class="f-600 fs-12">Tank cleaning & COW <span
                  class="underline f-700">NOT ALLOWED</span> at JPUT</span>
            </template>
            <template v-else-if="scope.row.formDescription==='Cargo tank gas freeing arrangements agreed.'">
            <span class="f-600 fs-12">Tank gas-freeing <span
                class="underline f-700">NOT ALLOWED</span> alongside JPUT</span>
            </template>
            <template
                v-else-if="scope.row.formDescription==='Cargo and bunker slop handling requirements (including Security information) agreed.'">
              <span class="f-600 fs-12">JPUT do not handle bunker slop</span>
            </template>
            <template
                v-else-if="scope.row.formDescription==='Vapor return line operation parameters are agreed (for vapor recovery or emission control)'">
              <span class="f-600 fs-12">Not applicable at JPUT</span>
            </template>
            <template v-else>
              <el-input v-model="scope.row.remarks" :disabled="disabled"  size="mini"></el-input>
            </template>
          </template>
        </el-table-column>
      </el-table>

      <!-- 第二部分 -->
      <div class="mt-4">
        Additional Checks for Chemical tankers <br>
        Part 5B - Tanker and Terminal -Bulk Liquid Chemicals - Pre - transfer
      </div>
      <!-- 表格 -->
      <el-table :data="list5B" class="mt-4" row-key="item">
        <el-table-column align="center" label="Item" prop="formItem" width="80"></el-table-column>
        <el-table-column label="Check" prop="formDescription">
          <template slot-scope="scope">
            {{scope.row.formDescription}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="Terminal" prop="checkTerminal" width="100">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checkTerminal" :disabled="$store.state.userInfo.role!=='11'||disabled"
                         false-label="0" true-label="1"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Tanker" prop="checkTanker" width="100">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checkTanker" :disabled="$store.state.userInfo.role!=='8'||disabled"
                         false-label="0" true-label="1"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Remarks" prop="remarks" width="300">
          <template slot-scope="scope">
            <template
                v-if="scope.row.formDescription==='Procedures are in place to receive Nitrogen from terminal for inserting or purging. (Risk of N2 over-pressurizing tanks)'">
              <span class="f-600 fs-12">JPUT DO NOT supply N2</span>
            </template>
            <template v-else>
              <el-input v-model="scope.row.remarks" :disabled="disabled"  size="mini"></el-input>
            </template>
          </template>
        </el-table-column>
      </el-table>

      <!-- 第三部分 -->
      <div class="mt-4">
        Part 6 -Tanker and Terminal - Agreements - Pre - transfer
      </div>
      <el-table :data="list6" :header-cell-style="{ background:'#F2F6FC' }" class="mt-4" row-key="item">
        <el-table-column align="center" label="Item" prop="formItem" width="80"></el-table-column>
        <el-table-column label="Agreement" prop="formDescription"></el-table-column>
        <el-table-column label="Details" prop="details">
          <template slot-scope="scope">
            <template v-if="scope.row.formDescription==='Tanker manoeuvring readiness.'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Notice period (maximum) for full readiness to manoeuvre:</div>
                  <el-input v-model="scope.row.noticePerid" :disabled="disabled"
                            size="mini"></el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Period of disablement (if permitted):</div>
                  <el-input v-model="scope.row.periodOfDisablement" disabled
                            size="mini"></el-input>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Security protocols (Chapter 6 - ISPS Code)'">
              <div class="fs-12 f-600">
                <div class="flex items-center">
                  <div style="width: 160px"><span style="color:red">*</span>Security level (Tanker’s):</div>
                  <el-radio-group v-model="scope.row.securityLevel" :disabled="disabled">
                    <el-radio label="1">1</el-radio>
                    <el-radio label="2">2</el-radio>
                    <el-radio label="3">3</el-radio>
                  </el-radio-group>
                </div>
                <div class="flex items-center mt-4">
                  <div style="width: 160px"><span style="color:red">*</span>Local requirements:</div>
                  <el-radio-group v-model="scope.row.localRequirements" :disabled="disabled">
                    <el-radio label="1">1</el-radio>
                    <el-radio label="2">2</el-radio>
                    <el-radio label="3">3</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Effective tanker / terminal communications'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Primary system:</div>
                  <el-input v-model="scope.row.primarySystem" :disabled="true"
                            size="mini"></el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Backup system:</div>
                  <el-input v-model="scope.row.backupSystem" :disabled="true"
                            size="mini"></el-input>
                </div>
              </div>
            </template>
            <template
                v-else-if="
              scope.row.formDescription==='Operational supervision and watch keeping'||
              scope.row.formDescription==='Dedicated smoking areas and naked lights restrictions.'||
              scope.row.formDescription==='Emergency signals & shutdown'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Terminal:</div>
                  <el-input v-model="scope.row.writeTerminal" :disabled="true"
                            size="mini"></el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Tanker:</div>
                  <el-input v-model="scope.row.writeTanker" :disabled="scope.row.formItem==='42'?true:disabled"

                            size="mini"></el-input>
                </div>
              </div>
            </template>
            <template
                v-else-if="scope.row.formDescription==='Maximum wind, current and sea/swell criteria or other environmental factors'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Stop cargo transfer:</div>
                  <el-input v-model="scope.row.stopCargoTransfer" :disabled="disabled"  size="mini">
                    <template slot="prepend">At wind speed</template>
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Disconnect:</div>
                  <el-input v-model="scope.row.disconnect" :disabled="true"  size="mini">
                    <template slot="prepend">At wind speed</template>
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Un-berth:</div>
                  <el-input v-model="scope.row.unBerth" :disabled="disabled"  size="mini">
                    <template slot="prepend">At wind speed</template>
                  </el-input>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Limits for cargo,bunkers and ballast handling.'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Max transfer rates:</div>
                  <el-input v-model="scope.row.maxTransferRates" :disabled="true"  size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Topping off rates:</div>
                  <el-input v-model="scope.row.toppingOffRates" :disabled="true"  size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Max manifold pressure:</div>
                  <el-input v-model="scope.row.maxManifoldPressure" :disabled="true"  size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Cargo temperature</div>
                  <el-input v-model="scope.row.cargoTemperature" :disabled="disabled" size="mini"  type="number">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Other limitations:</div>
                  <el-input v-model="scope.row.otherLimitations" :disabled="disabled"  size="mini">
                  </el-input>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Pressure surge control'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Minimum number of cargo tanks open:</div>
                  <el-input v-model="scope.row.minCargoOpen" :disabled="disabled" size="mini"  type="number">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Tank switching protocols:</div>
                  <el-input v-model="scope.row.tankSwitchingProtocols" :disabled="disabled"
                            size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Full load rate:</div>
                  <el-input v-model="scope.row.fullLoadRate" :disabled="disabled"  size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Topping off rate:</div>
                  <el-input v-model="scope.row.toppingOffRate" :disabled="disabled"  size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Closing time of automatic valves:</div>
                  <el-input v-model="scope.row.closeTimeValves" :disabled="disabled"  size="mini">
                  </el-input>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Cargo transfer management procedure.'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Action notice period:</div>
                  <el-input v-model="scope.row.actionNoticePeriod" :disabled="disabled"  size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Transfer stop protocol:</div>
                  <el-input v-model="scope.row.transferStopProtocol" :disabled="disabled"
                            size="mini">
                  </el-input>
                </div>
              </div>
            </template>
            <template
                v-else-if="scope.row.formDescription==='Routine for regular checks on cargo transferred are agreed.'">
              <div class="grid grid-cols-1 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Routine transferred quantity checks:</div>
                  <el-input v-model="scope.row.quantityChecks" :disabled="disabled" :min="0"  size="mini"
                            type="number" :style="{width:'300px'}">
                    <template slot="prepend">Intervals of NOT MORE THAN</template>
                    <template slot="append">hours</template>
                  </el-input>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Tank venting system'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Procedure:</div>
                  <el-input v-model="scope.row.procedure" :disabled="disabled"  size="mini">
                  </el-input>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Closed operations.'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Requirements:</div>
                  <el-input v-model="scope.row.requirement" :disabled="disabled"  size="mini">
                  </el-input>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Vapour return line'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Operational parameters:</div>
                  <el-input v-model="scope.row.operaParameter" :disabled="true" placeholder="N.A." size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Max flow rate:</div>
                  <el-input v-model="scope.row.maxFlowRate" :disabled="true" placeholder="N.A." size="mini">
                  </el-input>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Nitrogen supply from terminal.'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12">P<span style="color:red">*</span>rocedures to receive:</div>
                  <el-input v-model="scope.row.proceduresReceive" :disabled="true" placeholder="N.A." size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Max pressure:</div>
                  <el-input v-model="scope.row.maxPressure" :disabled="true" placeholder="N.A." size="mini">
                  </el-input>
                </div>
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Flow rate:</div>
                  <el-input v-model="scope.row.flowRate" :disabled="true" placeholder="N.A." size="mini">
                  </el-input>
                </div>
              </div>
            </template>
            <template v-else-if="scope.row.formDescription==='Exceptions and additions'">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="f-600 fs-12"><span style="color:red">*</span>Special issues that both parties should be aware of:</div>
                  <el-input v-model="scope.row.specialIssues" :disabled="disabled"  size="mini">
                  </el-input>
                </div>
              </div>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <!-- 第四部分 -->
      <div class="mt-4">
        Part 7A - General-Tanker - Checks Pre - transfer
      </div>
      <el-table :data="list7A" :header-cell-style="{ background:'#F2F6FC' }" class="mt-4" row-key="item">
        <el-table-column align="center" label="Item" prop="formItem" width="80"></el-table-column>
        <el-table-column label="Check" prop="formDescription">
          <template slot-scope="scope">
            <span style="color:red">*</span>{{scope.row.formDescription}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="Terminal" prop="Terminal" width="100">
          <template slot-scope="scope">
            <!--          <el-checkbox true-label="1" v-model="scope.row.checkTerminal" false-label="0"-->
            <!--          ></el-checkbox>-->
          </template>
        </el-table-column>
        <el-table-column align="center" label="Tanker" prop="Tanker" width="100">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checkTanker" :disabled="$store.state.userInfo.role!=='8'||disabled" false-label="0" true-label="1"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Remarks" prop="remarks" width="300">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remarks" :disabled="disabled"  size="mini"></el-input>
          </template>
        </el-table-column>
      </el-table>

      <!-- 第五部分 -->
      <div class="mt-4">
        <div class="mb-2 underline underline-offset-4"><b>DECLARATION</b></div>
        We, the undersigned, have checked the items in the applicable Parts 1 to 7 as marked and signed below:
      </div>
      <el-table :data="other" :header-cell-style="{ background:'#F2F6FC' }" class="mt-4" row-key="item">
        <el-table-column label="Item" prop="formDescription">
          <template slot-scope="scope">
            <span style="color:red">*</span>{{scope.row.formDescription}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="Terminal" prop="Terminal" width="100">
          <template slot-scope="scope">
            <template v-if="scope.row.formDescription!=='Part 1B - Tanker - Checks Pre-arrival - If using an inert gas system'&&
          scope.row.formDescription!=='Part 7B - Tanker - Checks Pre-transfer - Crude Oil washing (COW) is planned. (Note: COW not allowed at JPUT)'&&
          scope.row.formDescription!=='Part 7C - Tanker - Checks Prior to tank cleaning and/or gas-freeing. (Note: Tank Cleaning & Gas-freeing not allowed at JPUT)'
">
              <el-checkbox v-model="scope.row.checkTerminal" :disabled="$store.state.userInfo.role!=='11'||disabled" false-label="0" true-label="1"
              ></el-checkbox>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Tanker" prop="Tanker" width="100">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checkTanker" :disabled="$store.state.userInfo.role!=='8'||disabled" false-label="0" true-label="1"
            ></el-checkbox>
          </template>
        </el-table-column>
<!--        <el-table-column label="Remarks" prop="remarks" width="300">-->
<!--          <template slot-scope="scope">-->
<!--            <el-input v-model="scope.row.remarks" :disabled="disabled"  size="mini"></el-input>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>

      <div class="mt-4">
        <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
        <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
      </div>

      <!-- 底部描述 -->
      <div class="py-5">
        In accordance with the guidance in Chapter 25 of ISOGTT, we have satisfied ourselves that the entries we have
        made are correct to the best of our knowledge and that the tanker and terminal are in agreement to undertake the
        transfer operation.
      </div>
      <div>
        We have also agreed to <b>carry out the repetitive checks</b> noted in Parts 8 and 9 of the ISOGTT SSSCL, which
        should occur at intervals of not more than
        <el-input v-model="hoursOne" :disabled="disabled" :style="{ width:'60px' }" type="number"
                  size="mini"></el-input>
        hours for the <b>TANKER</b> and not more than
        <el-input v-model="hoursTwo" :disabled="disabled" :style="{ width:'60px' }" type="number"
                  size="mini"></el-input>
        hours for the <b>TERMINAL</b>.
      </div>
      <div class="py-5">
        If, to our knowledge, the status of any item changes, we will immediately inform the other party.
      </div>
    </div>
    <!-- 签名 -->
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";

export default {
  name: 'PreTransfer',
  props: {
    disabled: {
      type: Boolean
    },
    sign:{
      type:Object
    }
  },
  data() {
    return {
      hoursOne: "",
      hoursTwo: "",
      params: {},
      list5A: [],
      list5B: [],
      list6: [],
      list7A: [],
      other: [],
      formType: '',
      checkedYes: false,
      checkedYesDisabled: false,
    }
  },
  created() {
    let role = this.getRole()
    this.checkedYesDisabled = this.disabled || ( role != '11' && role != '8' )
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 3
      }).then(res => {
        this.list5A = res.data.list.filter(item => item.descr === '5A')
        this.list5B = res.data.list.filter(item => item.descr === '5B')
        this.list6 = res.data.list.filter(item => item.descr === '6B')
        this.list6.forEach(item => {
          if (item.formDescription === 'Tanker manoeuvring readiness.') {
            item.periodOfDisablement = 'NOT PERMITTED'
          }
          if (item.formDescription === 'Effective tanker / terminal communications') {
            item.primarySystem = 'VHF Channel 01'
            item.backupSystem = 'backupSystem'
            // console.log(item.primarySystem)
            // console.log(item.backupSystem)
          }
          if (item.formDescription === 'Operational supervision and watch keeping') {
            item.writeTerminal = 'Loading Master'
          }
          if (item.formDescription === 'Dedicated smoking areas and naked lights restrictions.') {
            item.writeTerminal = 'STRICTLY NOT ALLOWED'
          }
          if (item.formDescription === 'Maximum wind, current and sea/swell criteria or other environmental factors') {
            item.stopCargoTransfer = item.stopCargoTransfer||'30 knots'
            item.disconnect = '35 knots'
            item.unBerth = item.unBerth||'35 knots (Terminal/Master Discretion)'
          }
          if (item.formDescription === 'Limits for cargo,bunkers and ballast handling.') {
            item.maxTransferRates = 'Refer to pre-loading/pre-discharging information'
            item.toppingOffRates = 'Refer to pre-loading/pre-discharging information'
            item.maxManifoldPressure = 'Refer to pre-loading/pre-discharging information'
          }
          if (item.formDescription === 'Emergency signals & shutdown') {
            item.writeTanker = 'VIA WALKIE-TALKIE - STOP, STOP, STOP OR 3-LONG BL..'
            item.writeTerminal = 'VIA WALKIE-TALKIE - STOP, STOP, STOP'
          }
          if (item.formDescription === 'Vapour return line') {
            item.operaParameter = 'N.A.'
            item.maxFlowRate = 'N.A.'
          }
          if (item.formDescription === 'Nitrogen supply from terminal.') {
            item.proceduresReceive = 'N.A.'
            item.maxPressure = 'N.A.'
            item.flowRate = 'N.A.'
          }
        })
        this.list7A = res.data.list.filter(item => item.descr === '7A')
        this.other = res.data.list.filter(item => item.descr === '7B')
        this.formType = res.data.formType
        this.hoursOne = res.data.hoursOne
        this.hoursTwo = res.data.hoursTwo
      })
    },
    getRole() {
      return this.$store.state.userInfo.role;
    },
    save() {
      const arr = this.list5A.concat(this.list5B).concat(this.list6).concat(this.list7A).concat(this.other)
      arr.map(item => {
        item.nominationId = this.params.id
      })
      const data = {
        list: arr,
        hoursOne: this.hoursOne,
        hoursTwo: this.hoursTwo,
        formType: this.formType,
        nominationId: this.params.id
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    checkedTanker() {
      this.list5A.map(item => {
        item.checkTanker = this.checkedYes ? '1' : '0'
      })
      this.list5B.map(item => {
        item.checkTanker = this.checkedYes ? '1' : '0'
      })
      this.list7A.map(item => {
        item.checkTanker = this.checkedYes ? '1' : '0'
      })
      this.other.map(item => {
        item.checkTanker = this.checkedYes ? '1' : '0'
      })
    },
    checkedTerminal() {
      this.list5A.map(item => {
        item.checkTerminal = this.checkedYes ? '1' : '0'
      })
      this.list5B.map(item => {
        item.checkTerminal = this.checkedYes ? '1' : '0'
      })
      this.other.map(item => {
        item.checkTerminal = this.checkedYes ? '1' : '0'
      })
    },
    checkedAllYes() {
      if( this.checkedYesDisabled != true ) {
        this.checkedYes = ! this.checkedYes
        let role = this.getRole()
        if( role ==='11' ) {
          this.checkedTerminal();
        } else if( role ==='8' ) {
          this.checkedTanker();
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  font-size: 14px;
  color: #82889C;
}

::v-deep .el-input-group__append {
  background-color: #82889C;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

::v-deep .el-checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5px;
}
</style>
