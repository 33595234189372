<template>
  <el-dialog
    class="successDialog"
    :visible.sync="show"
    width="420px"
    :show-close="false"
  >
    <div class="flex flex-col items-center" v-if="floorvisble">
      <p class="message">{{ messages }}</p>
    </div>
    <div class="flex flex-col items-center" v-else>
      <svg-icon iconClass="success" />
      <p class="message">{{ message }}</p>
    </div>
    <div class="flex justify-center mt-5" v-if="floorvisble">
      <el-button
        size="small"
        type="primary"
        @click="conbtn"
        v-if="convisible"
        >{{ $t("btn.confirm") }}</el-button
      >

      <el-button
        class="closebtn"
        plain
        size="small"
        type="primary"
        @click="close"
        >{{ $t("btn.close") }}</el-button
      >
    </div>
    <div class="flex justify-center mt-5" v-else>
      <el-button size="small" type="primary" @click="close">{{
        $t("btn.close")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import SvgIcon from "../SvgIcon.vue";
export default {
  components: { SvgIcon },
  data() {
    return {
      messages: "Confirm to send document to SGTradex?",
      message: "Document is sent to SGTradex",
      show: false,
      userTypes: "",
      floorvisble: true,
      convisible: true,
    };
  },
  created() {
    this.userTypes = this.$store.state.userInfo.userType;
  },
  methods: {
    open(val) {
      this.show = true;
    },
    close() {
      this.show = false;
      this.floorvisble = true;
      // this.$emit("upup", this.userTypes);
      // this.$router.push({
      //   url: "barging/index",
      //   query: {
      //     pageStatus: 0,
      //   },
      // });
    },
    conbtn() {
      this.floorvisble = false;
    },
  },
};
</script>

<style lang="scss">
.successDialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog {
    border-radius: 10px;
  }
  .svg-icon {
    width: 140px;
    height: 100px;
  }
  .message {
    font-size: 18px;
    text-align: center;
    color: #82889c;
    margin-top: 10px;
  }
  .closebtn {
    background-color: #fff;
  }
}
</style>
