<template>
  <div>
    <el-dialog class="signDocumentDialog" :visible.sync="show" :width="width"
      :title="showTitle ? $t('common.signDocument') : ''" :show-close="showTitle ? true : false"
      :before-close="handleClose">
      <div v-if="!showJuptConfirmTips && !showBrageConfrimTips" v-loading="saveBtnLoading"
        element-loading-text="Please do not close the browser. Signing in progress...">
        <el-form size="small" ref="form" class="signForm" :model="form" label-position="top" :rules="rules">
          <el-row v-if="! isMobile">
            <el-col :span="6" style="padding-right: 5px; min-width: 200px;">
              <el-form-item :label="$t('common.title')" prop="sigTitle" :label-width="formLabelWidth">
                <el-input size="small" v-model="form.sigTitle" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="Company" prop="signCompany" :label-width="formLabelWidth" v-if="isBarge">
                <el-select v-model="form.signCompany" placeholder="Select company" style="width: 100%">
                  <el-option v-for="item in signCompanyOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Stamp" :label-width="formLabelWidth" prop="stamp">
                <div class="flex flex-col justify-between w-full gap-y-2">
                  <div class="flex flex-col mb-2">
                    <el-image v-if="previewStamp.show" style="width: 139px; height: 139px;" :src="previewStamp.url" />
                    <div class="text-xs pt-3">
                      <span v-if="fileSize">Size: {{ this.fileSize }}</span>
                      <span v-if="showFileSizeError" slot="tip" style="color:red">.&nbsp;JPEG/PNG file size must be less than
                        2MB</span>
                    </div>
                  </div>
                  <el-upload ref="upload" :action="currAction" :headers="currHeaders" :show-file-list="false"
                    accept=".png, .jpg, .jpeg" :before-upload="handleBeforeUpload" :on-error="handleError" :on-progress="handleProgress"
                    :on-success="handleSuccess" :on-change="handleChange">
                    <el-button round size="small" slot="trigger">
                      {{ previewStamp.url ? "Update Stamp" : "Add Stamp" }}
                    </el-button>
                    <el-progress v-if="isUploadingStamp" slot="tip" :percentage="percentageLoading" class="mt-2"></el-progress>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="18" style="padding-left: 5px; max-width: 500px;">
              <el-form-item :label="$t('common.eSignature')" prop="esignature" :label-width="formLabelWidth">
                <div :style="{ 
                  position: 'relative'
                }">
                  <div style="
                      position: absolute;
                      top: 5px;
                      right: 5px;
                      border-radius: 50%;
                    " v-show="!showUpdatePicButton || updatePicbtnStatus === 1" size="20px" @click="clearSign">
                    <svg-icon iconClass="clear" />
                  </div>
                  <img style="height: 300px; width: 500px; border: 1px solid #ccd0de; border-radius: 10px" v-show="showUpdatePicButton && updatePicbtnStatus === 0"
                    :src="baseUrl + userInfo.signPicture" />
                  <vue-sign v-show="!showUpdatePicButton || updatePicbtnStatus === 1" ref="signRef" :width="500" :height="300" style="max-height: calc(100vh - 300px);" />
                  <div size="20px" style="position: absolute; top: 5px; right: 5px; border-radius: 50%;" v-if="showUpdatePicButton && !updatePicbtnStatus" round @click="handleUpdatePicClick">
                    <svg aria-hidden="true" class="svg-icon"><use xlink:href="#icon-clear"></use></svg>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-else>
            <el-form-item :label="$t('common.title')" prop="sigTitle" :label-width="formLabelWidth">
              <el-input size="small" v-model="form.sigTitle" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Company" prop="signCompany" :label-width="formLabelWidth" v-if="isBarge">
              <el-select v-model="form.signCompany" placeholder="Select company" style="width: 100%">
                <el-option v-for="item in signCompanyOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Stamp" :label-width="formLabelWidth" prop="stamp">
              <div class="flex flex-col justify-between w-full gap-y-2">
                <div class="flex flex-col mb-2">
                  <el-image v-if="previewStamp.show" style="width: 139px; height: 139px;" :src="previewStamp.url" />
                  <div class="text-xs pt-3">
                    <span v-if="fileSize">Size: {{ this.fileSize }}</span>
                    <span v-if="showFileSizeError" slot="tip" style="color:red">.&nbsp;JPEG/PNG file size must be less than
                      2MB</span>
                  </div>
                </div>
                <el-upload ref="upload" :action="currAction" :headers="currHeaders" :show-file-list="false"
                  accept=".png, .jpg, .jpeg" :before-upload="handleBeforeUpload" :on-error="handleError" :on-progress="handleProgress"
                  :on-success="handleSuccess" :on-change="handleChange">
                  <el-button round size="small" slot="trigger">
                    {{ previewStamp.url ? "Update Stamp" : "Add Stamp" }}
                  </el-button>
                  <el-progress v-if="isUploadingStamp" slot="tip" :percentage="percentageLoading" class="mt-2"></el-progress>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item :label="$t('common.eSignature')" prop="esignature" :label-width="formLabelWidth">
              <div :style="{ 
                position: 'relative' 
              }">
                <div style="
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    border-radius: 50%;
                  " v-show="!showUpdatePicButton || updatePicbtnStatus === 1" size="20px" @click="clearSign">
                  <svg-icon iconClass="clear" />
                </div>
                <img style="height: 200px; width: 405px; border: 1px solid #ccd0de; border-radius: 10px" v-show="showUpdatePicButton && updatePicbtnStatus === 0"
                  :src="baseUrl + userInfo.signPicture" />
                <vue-sign v-show="!showUpdatePicButton || updatePicbtnStatus === 1" ref="signRef" :width="405" :height="200" :isCrop="true" />
                <div size="20px" style="position: absolute; top: 5px; right: 5px; border-radius: 50%;" v-if="showUpdatePicButton && !updatePicbtnStatus" round @click="handleUpdatePicClick">
                  <svg aria-hidden="true" class="svg-icon"><use xlink:href="#icon-clear"></use></svg>
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
        <div class="flex justify-end">
          <el-button size="small" type="primary" :disabled="saveBtnLoading" @click="handleSubmitClick">{{ $t("btn.submit")
          }}</el-button>
        </div>
      </div>
      <div class="tipsText" v-if="showJuptConfirmTips">
        Sign document and submit to barge/surveyor for signing?
        <div class="flex justify-center mt-5">
          <el-button size="small" type="primary" plain @click="close">{{
            $t("btn.close")
          }}</el-button>
          <el-button size="small" type="primary" @click="handleSignClick">{{
            $t("btn.sign")
          }}</el-button>
        </div>
      </div>
      <div class="mvpTips" v-if="showBrageConfrimTips">
        <div class="content" :style="{ height: '341px' }">
          <div class="logo">
            <svg-icon class="color-icon" iconClass="color-logo" />
          </div>
          <div class="flex justify-center">
            <svg-icon class="avatar" iconClass="avatar" />
          </div>
          <div class="text-center text-sm" style="color: #82889c">
            Logged in as:
          </div>
          <div class="text-center text-sm font-bold" style="color: #82889c">
            {{ vesselName }}
          </div>
          <div class="flex justify-center" style="margin-top: 10px">
            <el-form size="small" class="passwordForm" style="width: 300px" ref="form" :model="passwordForm"
              label-position="top">
              <el-form-item :label="$t('ifm.psd')" prop="password" :rules="[
                {
                  required: true,
                  message: $t('tips.sr'),
                  trigger: ['change', 'blur'],
                },
              ]">
                <el-input size="small" type="password" v-model="passwordForm.password"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="flex justify-end mt-6">
          <el-button size="small" type="primary" :loading="brageConfirmBtnLoading" @click="handleBrageConfirmClick">{{
            $t("btn.comfirm") }}</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="Possible Network Error" :visible.sync="signNotSuccessful" width="30%">
      <span>It seems there is a network error. Please try to sign again.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSignNotSuccessful">Ok</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import VueSign from "@/components/vue-sign.vue";
import { uuid } from "@/utils/uuid";
import {
  singleleUploadFile,
  juptSign
} from "@/services";
import { mapState } from "vuex";
import { getToken, getMvpToken, setMvpToken } from "@/utils/auth";
import { getServerUrl } from "@/utils/getServerUrl";
import { bargeLogin, bargeAuthCode } from "@/services/user";
import axios from "axios";
import { ROLE_MAP } from '@/utils/constants.js';
export default {
  components: { SvgIcon, VueSign },
  props: {
    docs: {
      type: Array,
    },
    // 驳船订单id
    bid: {
      default: null,
    },
    // 船名
    vesselName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      ROLE_MAP,
      controller: null,
      fileSize: null,
      showFileSizeError: false,
      show: false,
      form: {
        sigTitle: null,
        signCompany: null,
        esignature: null,
        stamp: null,
      },
      isUploadingStamp: false,
      progress: 0,
      total: 1,
      previewStamp: {
        show: false,
        url: null,
        fileName: null,
      },
      formLabelWidth: "120px",
      showJuptConfirmTips: false,
      showBrageConfrimTips: false,
      showTitle: true,
      width: "420px",
      rules: {
        sigTitle: [
          { required: true, message: "Please input title", trigger: "blur" },
        ],
        signCompany: [
          { required: true, message: "Please choose company", trigger: "blur" },
        ],
        esignature: [{ required: true, message: "Signature required" }],
        stamp: [{ required: true, message: "Stamp required" }],
      },
      baseUrl: getServerUrl(),
      saveBtnLoading: false,
      mvpToken: null,
      // 印章路径
      stampPath: null,
      // 印章参数值
      stamp: null,
      showBrageConfrimPassWord: false,
      passwordForm: {
        password: null,
      },
      brageConfirmBtnLoading: false,
      updatePicbtnStatus: 0,
      signCompanyOptions: [],
      signNotSuccessful: false,
      isMobile: false,
    };
  },
  watch: {
    form: {
      handler(val) {
        // console.log(val)
      },
      deep: true
    }
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    percentageLoading() {
      const percentage = (this.progress / this.total);
      const percentageDisplay = percentage * 100;
      return Math.round(percentageDisplay);
    },
    showUpdatePicButton() {
      return this.userInfo.role == 3 && this.userInfo.signPicture;
    },
    isBarge() {
      return this.userInfo.userType == 4;
    },
    currAction() {
      if (this.userInfo.userType == 4) {
        return process.env.VUE_APP_MVP_BASE_URL + "/common/upload"; // MVP上传文件服务器地址
      } else {
        return getServerUrl() + "/common/upload"; // JPUT上传文件服务器地址
      }
    },
    currHeaders() {
      if (this.userInfo.userType == 4) {
        return { Authorization: "Bearer " + this.mvpToken };
      } else {
        return { Authorization: "Bearer " + getToken() }
      }
    }
  },
  methods: {
    async uploadToMvp(file) {
      let formdata = new FormData();
      formdata.append("file", file);
      return axios.post(process.env.VUE_APP_MVP_BASE_URL + "/common/upload", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + this.mvpToken
        },
      }).then((res) => {
        const { data } = res;
        if (data.code == 401) {
          this.showBrageConfrimTips = true;
          this.$message.warning({
            showClose: true,
            message: "Authentication is required to sign. Please re-authenticate with your password",
            duration: 30000,
          });
        }
        return data;
      }).catch(console.error);
    },
    async getStamp() {
      return axios.get(process.env.VUE_APP_MVP_BASE_URL + "/marinePass/doc/getShipStamp", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.mvpToken
        },
      }).then((res) => {
        let data = res.data;
        if (data && data.stampUrl) {
          this.previewStamp.show = true;
          this.previewStamp.url = process.env.VUE_APP_MVP_BASE_URL + data.stampUrl;
          this.stampPath = process.env.VUE_APP_MVP_BASE_URL + data.stampUrl;
          this.stamp = data.stampUrl;
          this.form.stamp = data.stampUrl;
        }
      }).catch(console.error);
    },
    handleStampClick() {
      let preViewImage = document.querySelector(".preViewImage").children[0];
      preViewImage.click();
    },
    getSignCompany() {
      return axios.get(process.env.VUE_APP_MVP_BASE_URL + "/system/user/selectCompanyList", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.mvpToken
        },
      }).then(res => {
        const { data } = res;
        if (data.code === 200) {
          this.signCompanyOptions = [];
          const companies = data.data
          for (let x = 0; x < companies.length; x++) {
            let newCompany = {
              label: companies[x].companyName,
              value: companies[x].companyId
            };
            this.signCompanyOptions.push(newCompany);
          }

          if (companies.length > 0) {
            this.form.signCompany = companies[0].companyId;
          }
        }

        if (data.code == 401) {
          this.showBrageConfrimTips = true;
        }
      }).catch(console.error)
    },
    async handleSubmitClick() {
      let resA;
      if (this.userInfo.userType === "4") {
        const file = await this.getSignFile()
        resA = await this.uploadToMvp(file);
        if (resA && resA.code == 200) {
          this.form.esignature = resA.fileName;
        }
      }
      if (
        this.userInfo.userType === "1" &&
        (!this.showUpdatePicButton ||
          this.updatePicbtnStatus === 1 ||
          (this.userInfo.userType == 1 && !this.userInfo.signPicture))
      ) {
        const file = await this.getSignFile()
        resA = await singleleUploadFile(file);
        if (resA && resA.code == 200) {
          this.form.esignature = resA.fileName;
        }
      }

      this.$refs.form.validate(async (res) => {
        if (!res) return;

        this.saveBtnLoading = true;
        let request;
        let params = {
          ...this.form,
        };
        if (this.userInfo.userType === "1") {
          params["bid"] = this.bid;
          request = juptSign(params);
          request.then(() => {
            this.$message.success("Signed successfully");
            this.show = false;
            this.saveBtnLoading = false;
            this.$router.push({
              path: "/barging/index"
            });
          }).finally(() => {
            this.saveBtnLoading = false;
          });
        }
        if (this.userInfo.userType === "4") {
          // 签名图片上传
          // let res = await uploadEsignature({
          //   mvpToken: this.mvpToken,
          //   esignature: resA.fileName,
          // });

          params['title'] = this.form.sigTitle;
          params['signCompany'] = this.form.signCompany;
          params["esignature"] = this.form.esignature;
          params["shipStamp"] = this.form.stamp;
          params["shipStampA"] = process.env.VUE_APP_MVP_BASE_URL + this.form.stamp;
          // delete params["stamp"];
          // request = bargeSign(params);

          const promises = [];
          for (let i = 0; i < this.docs.length; i++) {
            if (!this.docs[i].mvpDocIsSigned) {
              const docId = this.docs[i].mvpDocId;
              let promise = axios.post(process.env.VUE_APP_MVP_BASE_URL + "/marinePass/doc/signature", {
                docId: docId,
                esignature: params.esignature,
                shipStamp: params.shipStamp,
                shipStampA: params.shipStampA,
                title: params.title,
                rgbNum: 256,
                signCompany: params.signCompany
              }, {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': 'bearer ' + this.mvpToken
                }
              })
                .then(res => {
                  return res.data;
                })
                .catch(console.error);
              promises.push(promise);
            }
          }

          Promise.all(promises).then((results) => {
            let shouldReauthenticate = false;
            for (let i = 0; i < results.length; i++) {
              const data = results[i];
              if (data.code == 401) {
                this.showBrageConfrimTips = true;
                this.$message.warning({
                  showClose: true,
                  message: "Authentication is required to sign. Please re-authenticate with your password",
                  duration: 30000,
                });
                shouldReauthenticate = true;
                break;
              }
            }

            if (shouldReauthenticate) return;

            let isSuccess = results.every(result => result.code === 200);
            if (isSuccess) {
              this.$message.warning({
                showClose: true,
                message: "Processing signature. It may take a while to retrieve the result. Please check back later.",
                duration: 30000,
              });
              this.show = false;
              this.$router.push({
                path: "/barging/index"
              });
            } else {
              this.signNotSuccessful = true;
            }
          }).finally(() => {
            this.saveBtnLoading = false;
          });
        }
      });
    },
    open() {
      this.show = true;
      if (this.userInfo.userType === "4") {
        if (getMvpToken() != null) {
          this.mvpToken = getMvpToken();
          this.getStamp();
          this.getSignCompany();
        }
      }
    },
    close() {
      this.show = false;
    },
    handleCloseClick() {
      this.previewStamp.show = false;
    },
    handleClose(done) {
      this.$confirm('Are you sure to close?')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    handleSignNotSuccessful() {
      this.$router.push({
        path: "/barging/index"
      });
      this.show = false;
      this.signNotSuccessful = false;
    },
    async getSignFile() {
      return new Promise((resolve, reject) => {
        this.$refs.signRef.generate().then((res) => {
          var arr = res.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          let file = new File([u8arr], `${uuid()}.png`, { type: mime });
          resolve(file);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    handleSignClick() {
      this.showTitle = true;
      this.showJuptConfirmTips = false;
      this.width = this.isMobile ? "100%" : "750px";
    },
    handleBeforeUpload(file) {
      this.fileSize = `${(file.size / 1000000).toPrecision(4)}MB`

      if (file.size > 2000000) {
        this.showFileSizeError = true;
        return false;
      } else {
        this.previewStamp.url = null;
        this.previewStamp.show = false;
        this.previewStamp.fileName = null;
        this.form.stamp = null;
        this.showFileSizeError = false;
        return true;
      }
    },
    handleChange(file, fileList) {
      this.$refs.upload.abort();
    },
    handleSuccess(res) {
      this.isUploadingStamp = false;
      this.previewStamp = {
        show: true,
        fileName: res.originalFilename,
      };

      if (this.userInfo.userType == 4) {
        this.previewStamp.url = process.env.VUE_APP_MVP_BASE_URL + res.fileName;
        this.form.stamp = res.fileName;
      } else {
        this.previewStamp.url = getServerUrl() + res.fileName;
        this.form.stamp = res.fileName;
      }
    },
    handleError(err) {
      console.error(err);
    },
    handleProgress(event, file, fileList) {
      this.isUploadingStamp = true;
      this.progress = event.loaded;
      this.total = event.total;
    },
    handleUpdatePicClick() {
      this.updatePicbtnStatus = 1;
      this.form.esignature = null;
    },
    clearSign() {
      this.$refs.signRef.reset();
      this.form.esignature = null;
    },
    handleBrageConfirmClick() {
      this.brageConfirmBtnLoading = true;
      bargeLogin({
        username: this.userInfo.userName,
        password: this.passwordForm.password,
      })
        .then((res) => {
          if (res.token && res.twoFactorEnabled == "false") {
            this.$message.success(this.$t("status.success"));
            this.mvpToken = res.token;
            setMvpToken(this.mvpToken)
            this.showBrageConfrimTips = false;
            this.brageConfirmBtnLoading = false;
            this.getStamp();
            this.getSignCompany();
          } else {
            this.$prompt(this.$t("ifm.pevc"), {
              confirmButtonText: this.$t("btn.comfirm"),
              cancelButtonText: this.$t("btn.cancel"),
              inputPattern: /\S/,
              inputErrorMessage: this.$t("tips.sr"),
              beforeClose: (action, instance, done) => {
                if (action == "confirm") {
                  bargeAuthCode({
                    code: instance.inputValue,
                    userName: this.userInfo.userName,
                  }).then((resA) => {
                    if (resA.code == 200) {
                      this.$message.success(this.$t("status.success"));
                      this.mvpToken = resA.token;
                      setMvpToken(this.mvpToken)
                      this.showBrageConfrimTips = false;
                      done();
                      this.getStamp();
                      this.getSignCompany();
                    } else {
                      this.$message.error(this.$t("ifm.error"));
                    }
                  });
                } else {
                  done();
                }
              },
            });
          }
        })
        .finally(() => {
          this.brageConfirmBtnLoading = false;
          this.passwordForm.password = null;
        });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  created() {
    this.isMobile = ( this.screenType?.isMobileScreen ?? false ) || screen.width < 650

    if (this.userInfo.nickName && this.userInfo.role) {
      this.form.sigTitle = this.capitalizeFirstLetter(this.userInfo.nickName) + ', ' + ROLE_MAP[this.userInfo.role]
    }
    // 判断用户类型
    if (this.userInfo.userType === "1") {
      this.showJuptConfirmTips = true;
      this.showBrageConfrimTips = false;
      this.showTitle = false;
      this.width = this.isMobile ? "100%" : "750px";
      // get stamp
      if (this.userInfo.stamp) {
        this.previewStamp.show = true;
        this.previewStamp.url = getServerUrl() + this.userInfo.stamp;
        this.form.stamp = this.userInfo.stamp;
      }
      if (this.updatePicbtnStatus === 0 && this.userInfo.role == 3 && this.userInfo.signPicture) {
        this.form.esignature = this.userInfo.signPicture;
      }
    }
    if (this.userInfo.userType === "4") {
      this.showTitle = true;
      this.showJuptConfirmTips = false;
      this.showBrageConfrimTips = false;
      if (getMvpToken() != null) {
        this.mvpToken = getMvpToken();
      }
      this.width = this.isMobile ? "100%" : "750px";
    }
  },
};
</script>

<style lang="scss">
.signDocumentDialog {
  max-width: 100%;
  max-height: 800px;

  .el-dialog__title {
    font-weight: 700;
    font-size: 16px;

    color: #82889c;
  }

  .el-dialog__close {
    font-weight: bold;
  }

  .el-form-item__label {
    color: #82889c;
    font-size: 14px;
    line-height: 28px;
  }

  .el-dialog__body {
    padding-top: 10px;
  }

  .el-button--default span {
    display: flex;
    align-items: center;
    color: $grayColor;

    .svg-icon {
      margin-right: 6px;
    }
  }

  .el-loading-spinner {
    left: 0% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.imageList {
  margin-top: 15px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 280px;
    font-size: 13px;
    background: #f4f6fa;
    border-radius: 7px;
    color: $grayColor;
    padding: 6px;

    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      background: #f56c6c;
      border-radius: 4px;

      .el-icon-close {
        color: white;
      }
    }
  }
}

.tipsText {
  font-size: 17px;
  text-align: center;
  color: #82889c;
}

.mvpTips {
  .content {
    height: 260px;
    border: 1px solid #82889c;
    border-radius: 10px;
    margin-top: 35px;

    .logo {
      position: relative;
      width: 133px;
      height: 92px;
      transform: translate(202.5px - 60.5px, -55%);
      background: white;
      padding: 10px;
    }

    .color-icon {
      position: absolute;
      width: 113px;
      height: 92px;
    }

    .avatar {
      width: 110px;
      height: 112px;
      transform: translate(0, -20px);
    }
  }
}

.signForm .el-form-item__error {
  margin-top: 3px;
}

.passwordForm>.el-form-item .el-form-item__label {
  padding: 0;
}
</style>
