<template>
  <div class="form p-12" style="background-color:#fff;">
    <!-- 第一部分 -->
    <div class="f-600" style="color:#4C565C ">
      Totaliser Readings (Before Loading)
    </div>

    <div class="item mt-4">
      <div class="fs-12 label">Reading Before Loading:</div>
      <div class="value">
        <el-input v-model="declarationRecordReport.readBeforeLoad" style="width: 200px;" disabled size="mini">
          <template slot="append">
            <span>m³</span>
          </template>
        </el-input>
      </div>
    </div>
    <div class="item mt-4">
      <div class="fs-12 label">Remarks (if any):</div>
      <div class="value" style="min-width:50%;">
        <el-input v-model="declarationRecordReport.remarks" :maxlength="600" disabled show-word-limit type="textarea"></el-input>
      </div>
    </div>
    <div class="item mt-4">
      <div class="fs-12 label">Image</div>
      <div v-if="declarationRecordReport.beforeImage == null">No Image</div>
      <div v-else>
        <div>
          <el-image
            v-if="declarationRecordReport.beforeImage"
            style="width: 170px; height: 170px;"
            :src="declarationRecordReport.beforeImage"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :initial-index="4"
            fit="fill"
          >
          </el-image>
          <input
            type="file"
            ref="beforeImage"
            accept="image/*"
            :multiple="false"
            :disabled="disabled"
            @change="(event) => handleFileChange('before', event)"
            style="opacity: 0; position: fixed; z-index: -1"
          />
        </div>
        <div>
          <el-button v-if="declarationRecordReport.beforeImage" size="mini" plain @click="() => handlePreviewImage(declarationRecordReport.beforeImage)">
            <svg-icon :icon-class="'zoom-in'"></svg-icon>
          </el-button>
        </div>
      </div>
    </div>
  
    <!-- 第二部分 -->
    <div class="mt-6 f-600" style="color:#4C565C ">
      Totaliser Readings (After Loading)
    </div>
    <div class="item mt-4">
      <div class="fs-12 label"><span style="color:red">*</span>Reading After Loading:</div>
      <div class="value">
        <el-input v-model="aftformMeterReadReport.readAfterLoad" style="width: 200px;" :disabled="disabled" size="mini"  type="number" @input="changeReading">
          <template slot="append">
            <span>m³</span>
          </template>
        </el-input>

        <p v-if="logisticsModify && latestHoseReading"><small>The last Reading Before Loading was: {{ latestHoseReading }}</small></p>
        <p v-if="logisticsModify && latestHoseReading && readingNumberNotValid" style="color: red;"><small>"Reading After Loading" value must be equal or lower than the last completed nomination’s "Reading Before Loading"</small></p>
      </div>
    </div>
    <div class="item mt-4">
      <div class="fs-12 label"><span style="color:red">*</span>Total Quantity Loaded:</div>
      <div class="value">
        <el-input v-model="aftformMeterReadReport.totalQuantityLoad" style="width: 200px;" disabled size="mini" type="number" >
          <template slot="append">
            <span>m³</span>
          </template>
        </el-input>
      </div>
    </div>
    <div class="item mt-4">
      <div class="fs-12 label">Remarks (if any):</div>
      <div class="value" style="min-width:50%;">
        <el-input v-model="aftformMeterReadReport.afterRemarks" :disabled="disabled" :maxlength="600" show-word-limit  type="textarea"></el-input>
      </div>
    </div>
    <div class="item mt-4">
      <div class="fs-12 label">Image</div>
      <div v-if="checkIsLogisticsModifyImage()">No Image</div>
      <div v-else>
        <div>
          <el-image
            v-if="aftformMeterReadReport.afterImage"
            style="width: 170px; height: 170px;"
            :src="aftformMeterReadReport.afterImage"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :initial-index="4"
            fit="fill"
          >
          </el-image>
          <input
            type="file"
            ref="afterImage"
            accept="image/*"
            :multiple="false"
            :disabled="disabled"
            @change="(event) => handleFileChange('after', event)"
            style="opacity: 0; position: fixed; z-index: -1"
          />
        </div>
        <div>
          <el-button size="mini" plain @click="() => handleOpenWindow($refs.afterImage)" :disabled="disabled">
            <svg-icon :icon-class="'upload'"></svg-icon>
          </el-button>
          <el-button v-if="aftformMeterReadReport.afterImage" size="mini" plain @click="() => handlePreviewImage(aftformMeterReadReport.afterImage)">
            <svg-icon :icon-class="'zoom-in'"></svg-icon>
          </el-button>
          <el-button v-if="aftformMeterReadReport.afterImage" size="mini" plain @click="() => handleRemoveFile('after')" :disabled="disabled">
            <svg-icon :icon-class="'delete'"></svg-icon>
          </el-button>
        </div>
      </div>
    </div>
    <!-- 描述 -->
    <div class="mt-10">
      <div>
        1. I have examined and taken the readings of the meter before and after the loading operation.
      </div>
      <div>
        2. I hereby confirm that the quantity of product loaded on board my vessel as stated in this Meter Reading
        Report is correct.
      </div>
    </div>
    <!-- 签名 -->
    <!-- 第一个签名 -->
    <div class="mt-14 f-600" style="color:#4C565C ">
      Signature (Before Loading)
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminalB.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminalB.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminalB.eSignature" :src="sign.terminalB.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.bargeB.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.bargeB.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.bargeB.eSignature" :src="sign.bargeB.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>


    <!-- 第二个签名 -->
    <div class="mt-14 f-600" style="color:#4C565C ">
      Signature (After Loading)
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :width="isMobileScreen ? '90vw' : '500px'" title="Preview">
      <div class="text-center w-full">
        <img w-full :src="dialogImageUrl" alt="Preview Image" style="margin: 0px auto" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {saveOrUpdateForms, selectFromById, getLatestHoseReading} from "@/services/form";
import {singleleUploadFile} from "@/services";
import { before } from "lodash";

export default {
  name: 'MeterReadingReport',
  props: {
    disabled: {
      type: Boolean
    },
    sign:{
      type:Object
    },
    nominationData: {
      type:Object,
      default: {}
    },
    modifyReason:{
      type: String,
    },
    logisticsModify:{
      type: Boolean,
    }
  },
  data() {
    return {
      aftformMeterReadReport: {},
      declarationRecordReport:{},
      formType: '',
      params: {},
      dialogVisible: false,
      dialogImageUrl: '',
      latestHoseReading: null,
      readingNumberNotValid: null,
    }
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    changeReading(){
      if(this.aftformMeterReadReport.readAfterLoad!==''){
        this.aftformMeterReadReport.totalQuantityLoad = Number(this.aftformMeterReadReport.readAfterLoad) - Number(this.declarationRecordReport.readBeforeLoad)
      }
      if(this.logisticsModify){
        this.getLatestHoseLoadingReading()
      }
    },
    save() {
      if(typeof this.latestHoseReading === "number" && Number(this.aftformMeterReadReport.readAfterLoad) > this.latestHoseReading ){
        this.readingNumberNotValid = true
        this.$emit('update')
        this.$message.error('Failed to Save')
      }else{
        const data = {
          aftformMeterReadReport: {
            ...this.aftformMeterReadReport,
            nominationId: this.params.id,
          },
          declarationRecordReport:{
            ...this.declarationRecordReport,
            nominationId: this.params.id,
          },
          nominationId: this.params.id,
          formType: this.formType,
          reasonToModifyTotalQuantityLoaded: this.modifyReason
        }
        saveOrUpdateForms(data).then(res=>{
          this.readingNumberNotValid = false
          this.getDetail()
          this.$emit('update')
          this.$message.success('Save Success')
        }).catch(()=>{
          this.$emit('update')
        })
      }
    },
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 16
      }).then(res => {
        this.aftformMeterReadReport = res.data.aftformMeterReadReport
        this.declarationRecordReport=  res.data.declarationRecordReport
        this.formType = res.data.formType
        if(this.logisticsModify){
          this.getLatestHoseLoadingReading()
        }
      })
    },
    handleOpenWindow( refsObject ) {
      refsObject.click()
    },
    handlePreviewImage( url ) {
      if( url ) {
        this.dialogImageUrl = url
        this.dialogVisible = true
      }
    },
    handleFileChange(index, event) {
      let files = event.target.files
      if (files.length) {
        singleleUploadFile( files[0] ).then((res) => {
          if( res.code == 200 ) {
            if(index == 'before') {
              this.aftformMeterReadReport.beforeImage = res.url
            } else {
              this.aftformMeterReadReport.afterImage = res.url
              this.dialogImageUrl = res.url
            }
          } 
        })
      }
    },
    handleRemoveFile(index) {
      if( index == 'before' ) {
        this.aftformMeterReadReport.beforeImage = ''
      } else {
        this.aftformMeterReadReport.afterImage = ''
      }
    },
    async getLatestHoseLoadingReading(){
      if(!this.declarationRecordReport.hose || this.declarationRecordReport.hose == ""){
        // this.latestHoseReading = "Not found"
        return
      }
      const data = {
        nominationId: this.params.id,
        status: 2,
        hose: this.declarationRecordReport.hose
      }
      await getLatestHoseReading(data).then(res => {
        if(res.data){
          this.latestHoseReading = res.data
        }else{
          this.latestHoseReading = "Not found"
        }
      })
    },
    checkIsLogisticsModifyImage(){
      if(this.logisticsModify){
        return false
      }
      if(!this.isLoadingOrCoSigning && this.aftformMeterReadReport.afterImage == null){
        return true
      }
    },
  },
  computed: {
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    },
    isLoadingOrCoSigning() {
      return [2,3].includes(this.nominationData.status)
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  font-size: 14px;
  color: #82889C;
}

.item {
  display: flex;
  // align-items: center;

  .label {
    width: 140px;
  }

  .value {
    max-width: calc(100% - 140px);
  }
}

::v-deep .el-input-group__append {
  background-color: #82889c;
  color: #fff;
}

.avatar-uploader, .avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>