import { render, staticRenderFns } from "./CargoNomination.vue?vue&type=template&id=74578841&scoped=true&"
import script from "./CargoNomination.vue?vue&type=script&lang=js&"
export * from "./CargoNomination.vue?vue&type=script&lang=js&"
import style0 from "./CargoNomination.vue?vue&type=style&index=0&id=74578841&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74578841",
  null
  
)

export default component.exports