<template>
  <div class="form p-12">
    <div class="content">
      I<span style="color:red">*</span>,
      <el-input v-model="declarationUsePurchaseSale.name" :disabled="disabled||$store.state.userInfo.role!='8'" placeholder="Name (user has to fill in)" size="mini"></el-input>
      , Master of<span style="color:red">*</span>
      <el-input v-model="declarationUsePurchaseSale.vesselName" :disabled="disabled||$store.state.userInfo.role!='8'" placeholder="Vessel Name" size="mini"></el-input>
      , hereby understand and will endeavor to ensure that while the Vessel is alongside the Terminal, there will not be
      any Use, Purchase and Sale of any Duty-Unpaid Items (which may include contrabands or any other prohibited items**
      to be used, purchased or sold in Singapore) onboard the Vessel.<br />
      <br />
      I also acknowledge that this requirement has been clearly communicated to my crew and me by the representative of
      the Terminal, and will, to the best of my ability, comply with this requirement when the Vessel is alongside the
      Terminal.<br />
      <br />
      I also understand and am fully aware that the Terminal has zero tolerance towards the Use, Purchase or Sale of any
      Duty-Unpaid Items within the Terminal or onboard any vessels alongside the Terminal.<br />
      <br />
      ** The items include duty-unpaid cigarettes, alcohol, prescribed or non prescribed drugs and are not limited to items listed in:<br />
      <ul>
        <li>https://www.custom.gov.sg/businesses/importing-goods/controlled-and-prohibited-goods-for-import</li>
        <li>Customs Act 2004</li>
      </ul>
    </div>
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";

export default {
  name: "PurchaaseAndSale",
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
    }
  },
  data(){
    return {
      declarationUsePurchaseSale:{},
      formType:'',
      params:{}
    }
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods:{
    save(){
      const data = {
        declarationUsePurchaseSale:{
          ...this.declarationUsePurchaseSale,
          nominationId : this.params.id
        },
        nominationId : this.params.id,
        formType:this.formType
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    getDetail(){
      selectFromById({
        nId: this.params.id,
        fId: 10
      }).then(res=>{
        this.declarationUsePurchaseSale = res.data.declarationUsePurchaseSale
        this.formType = res.data.formType
      })
    }
  }
}
</script>


<style scoped lang="scss">
.form {
  font-size: 15px;
  color: #82889C;
  background-color: #fff;
}

.content {
  //font-weight: 400;

  .el-input {
    width: 200px;
    margin: 0 5px;
  }
}
ul{
  padding-left: 15px;
  list-style-type: disc;
}
</style>
