<template>
  <div class="form p-12">
    <div class="f-400">
      Dear Sir,<br/>
      <br/>
      To facilitate a safe and effective loading/discharging operation, you are required to have <span
        style="color: #4C565C;font-weight: 700">at least 3 watch crew</span>
      in attendance on deck at all times.<br/>
      <br/>
      You are to ensure that the deck watch personnel have adequate knowledge to carry out the loading/discharging
      operation properly and the means available to take corrective action should any hazardous situation occur.<br/>
      <br/>
      If the above requirement is not complied with, the Terminal reserves the right to stop all cargo operations and to
      order your vessel off the berth for appropriate action to be taken by the charterer/owner concerned.<br/>
      <br/>
    </div>
    <el-checkbox v-model="form.check" :disabled="disabled||$store.state.userInfo.role!='8'" false-label="0" true-label="1"><span style="color:red">*</span>I have read and
      acknowledged.
    </el-checkbox>
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";

export default {
  name: "RequiredOnDeck",
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
     }
  },
  data() {
    return {
      formType: '',
      form: {}
    }
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    save() {
      const data = {
        declarationCrewRequired: {
          ...this.form,
          nominationId: this.params.id
        },
        formType: this.formType,
        nominationId: this.params.id
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 9
      }).then(res => {
        this.form = res.data.declarationCrewRequired
        this.formType = res.data.formType
      })
    }
  }
}
</script>


<style scoped lang="scss">
.form {
  background-color: #fff;
  font-size: 15px;
  color: #82889C;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
  color: #82889C;
  font-weight: 600;
}
</style>
