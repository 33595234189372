<template>
  <div class="form p-12">
    <div>
      Dear Sir,<br/>
      <br/>
      Accountability for the safe conduct of operations while your tanker is at our terminal rests jointly with you, as
      Master of the tanker, and with the Terminal representative. Nevertheless, since our personnel, property and other
      shipping may also suffer serious damage in the event of accident on board your tanker, before operations starts,
      your full co-operation and understanding is required to ensure the safety requirements set out in the Ship/Shore
      Safety Checklist are followed.<br/>
      <br/>
      These safety requirements are based no safe practices that are widely accepted by the oil terminal and tanker
      industries. We therefore expect you, and all under your command, to adhere strictly to them throughout your
      tanker’s stay alongside this terminal. We will ensure that our personnel do likewise and co-operate fully with you
      in the mutual interest of safe and efficient operation.<br/>
      <br/>

      Before the start of operations, and then from time to time for our mutual safety, a member of our Terminal staff,
      together with a Responsible Officer where appropriate, will make a routine inspection of your tanker.<br/>
      <br/>

      Where corrective action is needed we will not agree to operations starting. If they have start, we may require the
      operations to stop immediately.<br/>
      <br/>

      Similarly, if you consider that safety is being endangered by any action on the part of our Terminal staff or by
      any equipment under our control, you should request the operations to be stopped immediately.<br/>
      <br/>

      There can be no compromise with safety.<br/>
      <br/>
    </div>
    <div class="item">
      <div class="label"><span style="color:red">*</span>The senior terminal representative on duty is:</div>
      <el-input v-model="declarationSafetyRequirements.terminalRepresentative" disabled></el-input>
    </div>
    <div class="item mt-6 mb-6">
      <div class="label"><span style="color:red">*</span>Telephone number:</div>
      <el-input v-model="declarationSafetyRequirements.phoneNum" disabled></el-input>
    </div>
    <div class="item">
      <div class="label"><span style="color:red">*</span>UHF/VHF communication channel:</div>
      <el-input v-model="declarationSafetyRequirements.channel" disabled></el-input>
    </div>
    <div class="mt-4">
      IN THE EVENT OF CONTINUED OR FLAGRANT DISREGARD OF THESE SAFETY REQUIREMENTS BY ANY TANKER, WE RESERVE THE RIGHT
      TO STOP ALL OPERATIONS AND TO ORDER THAT TANKER OFF THE BERTH FOR APPROPRIATE ACTION TO BE TAKEN BY THE CHARTERERS
      AND OWNERS CONCERNED.<br />
      <br/>
      <span style="font-size: 12px">Please acknowledge receipt of this letter by countersigning.</span>
    </div>
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {selectFromById} from "@/services/form";

export default {
  name: 'SafatyRequirements',
  props:{
    sign:{
      type:Object
    }
  },
  data(){
    return {
      declarationSafetyRequirements:{},
      formType:''
    }
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods:{
    save() {
      this.$emit('update')
    },
    getDetail(){
      selectFromById({
        nId: this.params.id,
        fId: 8
      }).then(res=>{
        this.formType = res.data.formType
        this.declarationSafetyRequirements = res.data.declarationSafetyRequirements
      })
    }
  }
}
</script>
<style scoped lang="scss">
.form {
  background-color: #fff;
  font-size: 15px;
  color: #82889C;
}

.item {
  display: flex;
  align-items: center;

  .label {
    width: 30%;
  }

  .el-input {
    width: 300px;
  }
}
</style>
