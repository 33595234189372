<template>
  <div class="p-4 form xl:p-12" style="background-color:#fff;">
    <div class="flex justify-between items-center">
      <div>Part 8 - Tanker - Repetitive Checks - During and After Transfer</div>
      <el-button size="mini" type="primary" @click="addTime" :disabled="disabled||$store.state.userInfo.role!='8'">add</el-button>
    </div>

    <div class="mt-4">
      <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
      <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
    </div>

    <el-table ref="table" :data="list" :header-cell-style="{ background: '#F2F6FC' }" class="mt-4">
      <el-table-column align="center" label="Item Ref." prop="formItem" width="80"></el-table-column>
      <el-table-column label="Check" min-width="300">
        <template slot-scope="scope">
          <template v-if="scope.$index===0">
            <div>
              Interval time <span style="color:red">*</span> <el-input v-model="scope.row.check"  :disabled="disabled||$store.state.userInfo.role!='8'" size="mini" style="width: 60px;"/> hours
            </div>
          </template>
          <template v-else>
              <span style="color:red" v-if="scope.$index > 2">*</span>{{scope.row.formDescription}}
          </template>
        </template>
      </el-table-column>
      <el-table-column v-if="beforeAfterFlag==1" v-for="(item,index) in beforeLength" align="center" label="Time" width="200">
        <template slot="header" slot-scope="scope">
          <div class="flex items-center justify-center">Time</div>
        </template>
        <template slot-scope="scope">
          <el-date-picker v-if="scope.$index===0" v-model="scope.row.time[0][index]" :disabled="true" format="yyyy-MM-dd HH:mm:ss" size="mini" style="width: 100%;" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          <el-checkbox v-else v-model="scope.row.time[0][index]" :disabled="true" false-label="0" true-label="1"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column v-for="(item,index) in length" align="center" label="Time" width="200">
        <template slot="header" slot-scope="scope">
          <div class="flex items-center justify-center">Time <i v-if="((beforeAfterFlag==0 && length>2) || (beforeAfterFlag==1 && length>1)) && !disabled && $store.state.userInfo.role=='8'" class="el-icon-circle-close ml-2 cursor-pointer" style="color: red;font-size: 16px" @click="deleteTime(index)"></i></div>
        </template>
        <template slot-scope="scope">
          <el-date-picker v-if="scope.$index===0" v-model="scope.row.time[beforeAfterFlag][index]" :disabled="disabled||$store.state.userInfo.role!='8'" format="yyyy-MM-dd HH:mm:ss" size="mini" style="width: 100%;" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          <el-checkbox v-else v-model="scope.row.time[beforeAfterFlag][index]" :disabled="disabled||$store.state.userInfo.role!='8'" false-label="0" true-label="1"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="Remarks" width="250">
        <template slot-scope="scope">
          <el-input v-model="scope.row.remarks" :disabled="disabled||$store.state.userInfo.role!='8'" size="mini"></el-input>
        </template>
      </el-table-column>
    </el-table>

    <div class="mt-4">
      <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
      <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
    </div>

    <!-- 签名 -->
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature (before Loading)
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.bargeB.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.bargeB.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.bargeB.eSignature" :src="sign.bargeB.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>

    <div v-if="beforeAfterFlag==1" class="mt-4 f-500" style="color:#4C565C ">
      Signature (after Loading)
    </div>
    <div v-if="beforeAfterFlag==1" class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";

export default {
  name: 'RepetitiveChecksTanker',
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
    },
    beforeAfterFlag: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      list: [],
      params: {},
      formType: "",
      length: 0,
      beforeLength:0,
      checkedYes: false,
      checkedYesDisabled: false,
    };
  },
  created() {
    this.checkedYesDisabled = this.disabled || this.editDisabled()
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    getRole() {
      return this.$store.state.userInfo.role;
    },
    editDisabled() {
      return this.getRole() != '8'
    },
    filterEmptyString( flag ) {
      let dateArray = this.list[0].time[flag]
      this.list.map(item => {
        let array = item.time[flag]
        item.time[flag] = array.filter((val, index) => {
          return dateArray[index] != ''
        })
        item.time = Object.assign({}, item.time);
      })
    },
    save(){
      this.filterEmptyString( this.beforeAfterFlag )
      const data = {
        list:this.list,
        formType:this.formType,
        nominationId: this.params.id,
        beforeAfterFlag:this.beforeAfterFlag == 0 ? '1':'2'
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    defaultTime( number ) {
      let array = []
      for( var i=0; i<(number ?? 5); i++)
        array.push('')
      return array;
    },
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 4
      }).then(res => {
        this.list = res.data.list
        this.formType = res.data.formType
        if (this.list[0].time) {
          this.filterEmptyString(0)
          this.filterEmptyString(1)
          this.list.map(item => {
            
            item.time[0] = item.time[0] && item.time[0].length ? item.time[0] : this.defaultTime(0)
            item.time[1] = item.time[1] && item.time[1].length ? item.time[1] : this.defaultTime(0)

            let used = this.beforeAfterFlag ? ( item.time[0].length + item.time[1].length ) : item.time[0].length
            let available = 5 - used
            if( this.disabled  || this.editDisabled() ) {
              available = used ? 0 : 5
            } else {
              available = available > 1 ? available : 2
            }

            item.time[this.beforeAfterFlag] = item.time[this.beforeAfterFlag].concat( this.defaultTime( available ) )

          })
          this.length = this.list[0].time[this.beforeAfterFlag].length
          this.beforeLength = this.beforeAfterFlag == 1 ? this.list[0].time[0].length : 0
        } else {        
          this.length = 5
          this.list.map(item => {
          // 这用于checkbox，值的属性很重要，属性错误，checkbox不能操作
          // item.time = Array.from({length: 5}).fill('')
            item.time = [this.defaultTime(5), this.defaultTime(0)]
            item.nominationId=this.params.id
          })
        }
      })
    },
    addTime(){
      this.length+=1
      this.list.map(item=>{
        item.time[this.beforeAfterFlag].push('')
      })
    },
    deleteTime(index){
      this.length-=1
      if(this.beforeAfterFlag === 0 && this.length===1){
        this.list.map(item=>{
          item.time[this.beforeAfterFlag]=[]
        })
      }else if(this.beforeAfterFlag === 1 && this.length===0){
        this.list.map(item=>{
          item.time[this.beforeAfterFlag]=[]
        })
      }else{
        this.list.map(item=>{
          item.time[this.beforeAfterFlag].splice(index,1)
        })
      }
      this.$nextTick(()=>{
        this.$refs.table.doLayout()
      })
    },
    checkedTanker() {
      this.list.map( ( item, index ) => {
        if(index > 0) {
          // 这用于checkbox，值的属性很重要，属性错误，checkbox不能操作
          let anotherIndex = this.beforeAfterFlag ? 0 : 1
          let anotherArray = item.time[anotherIndex]
          let array = [];
          for(let i = 0; i < item.time[this.beforeAfterFlag].length; i++) {
            array.push( this.list[0].time[this.beforeAfterFlag][i] != '' && this.checkedYes ? '1' : '' )
          }
          item.time = this.beforeAfterFlag ? [anotherArray, array] : [array, anotherArray]
        }
      })
    },
    checkedAllYes() {
      if( this.checkedYesDisabled != true ) {
        this.checkedYes = ! this.checkedYes
        this.checkedTanker();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.form {
  font-size: 14px;
  color: #82889C;
}
</style>
