<template>
  <div class="form p-12">
    <div>
      To facilitate communications between ship and shore during cargo operation, please acknowledge receipt of this
      walkie talkie from Jurong Port Universal Terminal Pte. Ltd. This walkie talkie shall be returned to JPUTPL upon
      completion of the operation.
    </div>
    <div class="mt-6">
      <span style="font-size: 12px;white-space: nowrap" class="mr-4"><span style="color:red">*</span>Transceiver Serial Number:</span>
      <el-input  v-model="declarationWalkieTalkie.transceiverNum" :disabled="disabled||$store.state.userInfo.role!='11'"  size="mini" style="width: 300px;"></el-input>
    </div>
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";

export default {
  name: "WalkieTalkie",
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
     }
  },
  data(){
    return {
      declarationWalkieTalkie:{},
      formType:'',
      params:{}
    }
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods:{
    save(){
      const data = {
        declarationWalkieTalkie:{
          ...this.declarationWalkieTalkie,
          nominationId : this.params.id
        },
        nominationId : this.params.id,
        formType:this.formType
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    getDetail(){
      selectFromById({
        nId: this.params.id,
        fId: 11
      }).then(res=>{
        this.declarationWalkieTalkie = res.data.declarationWalkieTalkie
        this.formType = res.data.formType
      })
    }
  }
}
</script>


<style scoped lang="scss">
.form {
  font-size: 14px;
  color: #999;
  background-color: #fff;
}
</style>
