<template>
  <div class="form p-12">
    <div>
      <div class="f-600">Loading</div>
      <div class="mt-4">
        To facilitate a safe and expeditious loading operation, we require a copy of your cargo layout plan and the
        information below.<span style="color:red">*</span>For your information and guidance, we are able to load at:
      </div>
      <div class="mt-4 w-full md:w-1/5">
        <el-input v-model="dataForm.aftformInformation.loading" size="small" type="number" :disabled="disabled">
          <template slot="append">
            Kl/h
          </template>
        </el-input>
      </div>
      <div class="mt-6 f-600">Discharging</div>
      <div class="mt-4">
        To facilitate a safe and expeditious discharging operation, we require a copy of your cargo layout plan and
        the information below. <span style="color:red">*</span>For your information and guidance, our maximum pressure is 10.5kg/cm² and the maximum
        freeboard is:
      </div>
      <div class="mt-4 w-full md:w-1/5">
        <el-input v-model="dataForm.aftformInformation.discharging" size="small" type="number" :disabled="disabled">
          <template slot="append">
            metres
          </template>
        </el-input>
      </div>
    </div>

    <div class="f-600 black mt-4 flex justify-between">
      <div style="height: 30px;">
        <el-button size="small" type="primary" @click="add" :disabled="disabled">Add</el-button>
      </div>
    </div>
    <el-table :data="dataForm.listItem" class="mt-4">
      <el-table-column label="Product" min-width="150">
        <template slot-scope="scope">
          <!--el-input v-model="scope.row.name"  size="mini" :disabled="disabled"></el-input-->
          <el-autocomplete v-model="scope.row.name" :disabled="disabled" size="mini" :fetch-suggestions="querySearchAsync">
            <template #default="{ item }">
              <div v-if="item.old" class="flex justify-between">
                <div class="value">{{ item.value }}</div>
                <div>
                  <i class="el-icon-circle-close cursor-pointer fs-15" style="color: red" @click="deleteProduct( scope.$index, item.data )"></i>
                </div>
              </div>
              <div v-else class="flex justify-between" @click="addMoreProduct(item.value)">
                <div class="value">{{ item.value }}</div>
                <div>
                  <el-button plain type="primary" style="padding:0; border-width: 0px; border-radius: 25px">
                    <i class="el-icon-circle-plus cursor-pointer fs-15"></i>
                  </el-button>
                </div>
              </div>
            </template>
          </el-autocomplete>
        </template>
      </el-table-column>
      <el-table-column label="Customer" min-width="150">
        <template slot-scope="scope">
          <el-input  v-model="scope.row.customer"  size="mini" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Quantity" min-width="150">
        <template slot-scope="scope">
          <el-input  v-model="scope.row.quantity"  size="mini" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Manifold No.from the bow" min-width="150">
        <template slot-scope="scope">
          <el-input  v-model="scope.row.fromTheBow"  size="mini" :disabled="disabled"></el-input>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <i v-if="dataForm.listItem.length>1 && !disabled" class="el-icon-circle-close cursor-pointer fs-15" style="color: red"
             @click="doDel(scope.$index)"></i>
        </template>
      </el-table-column>
    </el-table>
    <div style="color: red;" class="mt-4 mb-4 fs-12">
      *Please fill in at least 1 product and its qualities.
    </div>
    <div class="flex flex-wrap w-full mt-4 justify-between">
      <div class="w-full md:w-1/2 md:pr-5">
        <div class="f-600">Loading</div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Time required for deballasting:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.timeDeballas" size="mini" type="number" :disabled="disabled">
              <template slot="append">
                <div class="w-12">hours</div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Maximum loading rate:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.maxLoadingRate" size="mini" type="number" :disabled="disabled">
              <template slot="append">
                <div class="w-12">Kl/h</div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Ship or shore stop:</div>
          <div class="value">
            <el-select v-model="dataForm.aftformInformation.shipShoreStop" size="mini" :disabled="disabled" style="width: 100%;">
              <el-option v-for="item in shipOrShoreStopOptions" :key="item.key" :value="item.key" :label="item.label"/>
            </el-select>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Notice required for stoppage:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.noticeStoppage" size="mini"  type="number" :disabled="disabled" @change="(e)=>changeIpt(e,'noticeStoppage')">
              <template slot="append">
                <div class="w-12">minutes</div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Rate required for topping up:</div>
          <div class="value">
            <el-input  v-model="dataForm.aftformInformation.rateTopUp" size="mini" type="number" :disabled="disabled">
              <template slot="append">
                <div class="w-12">Kl/h</div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Time required for topping up:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.timeTopUp" size="mini"  type="number" :disabled="disabled" @change="(e)=>changeIpt(e,'timeTopUp')">
              <template slot="append">
                <div class="w-12">minutes</div>
              </template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="w-full mt-4 md:w-1/2 md:pl-5 md:mt-0">
        <div class="f-600">Discharging</div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Number of pumps:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.numPumps" size="mini"  type="number" :disabled="disabled"></el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Maximum discharge rate:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.maxDischargeRate" size="mini" type="number" :disabled="disabled">
              <template slot="append">
                <div class="w-12">Kl/h</div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Maximum pump pressure:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.maxPumpPressure" size="mini" type="number" :disabled="disabled">
              <template slot="append">
                <div class="w-12">kg/cm²</div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Maximum manifold pressure:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.maxManifoldPressure" size="mini" type="number" :disabled="disabled">
              <template slot="append">
                <div class="w-12">kg/cm²</div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Time required for stripping:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.timeStrip" size="mini" type="number" :disabled="disabled">
              <template slot="append">
                <div class="w-12">hours</div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Time required for discharging:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.timeDischarge" size="mini" type="number" :disabled="disabled">
              <template slot="append">
                <div class="w-12">hours</div>
              </template>
            </el-input>
          </div>
        </div>
        <div class="item mt-4">
          <div class="fs-12 f-600 label"><span style="color:red">*</span>Time required for ballasting:</div>
          <div class="value">
            <el-input v-model="dataForm.aftformInformation.timeBallaste" size="mini" type="number" :disabled="disabled">
              <template slot="append">
                <div class="w-12">hours</div>
              </template>
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 f-600" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";
import {productAutocomplete} from "@/utils/tools"

export default {
  name: "PreLoading",
  data() {
    return {
      dataForm: {
        aftformInformation:{}
      },
      params: {},
      autocomplete: null,
      shipOrShoreStopOptions:[
        {
          key: "Ship",
          label: "Ship",
        },
        {
          key: "Shore",
          label: "Shore",
        },
        {
          key: "Both sides to monitor",
          label: "Both sides to monitor",
        },
      ],
    }
  },
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
    }
  },
  created() {
    this.autocomplete = productAutocomplete()
    this.autocomplete.target( productAutocomplete().targets.ALL )
  },
  async mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    save() {
      const data = {
        ...this.dataForm,
        aftformInformation: {
          ...this.dataForm.aftformInformation,
          nominationId: this.params.id,
        },
        nominationId: this.params.id,
        listItem: this.dataForm.listItem.map(item => {
          return {
            ...item,
            nominationId: this.params.id,
          }
        })
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    changeIpt(e,key){
      if(e<0){
        this.dataForm.aftformInformation[key]=0
      }
      if(e>60){
        this.dataForm.aftformInformation[key]=60
      }
    },
    doDel(index) {
      this.dataForm.listItem.splice(index, 1)
    },
    add() {
      this.dataForm.listItem.push({})
    },
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 19
      }).then(res => {
        this.dataForm = res.data
        let object = this.dataForm.aftformInformation
        Object.keys(object).forEach( key => object[key] = object[key] == '' ? '0' : object[key] )
        this.dataForm.aftformInformation = object
        this.dataForm.listItem = this.dataForm.listItem && this.dataForm.listItem.length > 0 ? this.dataForm.listItem : [{}]
      })
    },
    async querySearchAsync( queryString, cb ) {
      await this.autocomplete.search( queryString, cb )
    },
    addMoreProduct( name ) {
      this.autocomplete.add( name )
    },
    async deleteProduct( index, data ) {
      await this.autocomplete.delete( data.id, data.name, ( query ) => {
        this.dataForm.listItem[index].name = query
      })
    },
  }
}
</script>


<style scoped lang="scss">
.form {
  font-size: 15px;
  color: #82889C;
  background-color: #fff;
}

::v-deep .el-input-group__append {
  background-color: #82889c;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.item {
  display: flex;
  align-items: center;

  .label {
    width: 230px;
    flex-shrink: 0;
  }

  .value {
    //width: 240px;
    flex: 1;
    .el-input{
      width: 100%;
      //width: 240px;
    }
  }
}
</style>
