<template>
  <div class="containerForm" v-loading="formDataloading">
    <div class="head flex items-center">
      <i class="el-icon-document fileIcon"></i>
      <p class="font-bold">{{ $t("table.BargingInformation") }}</p>
    </div>
    <div class="form">
      <el-form ref="elForm" :model="formData" :rules="rules" size="small" :label-width="isMobileScreen?null:'200px'" label-position="left"
        :disabled="fromdisabled">
        <el-form-item :label="'ID :'" prop="id">
          <el-input :disabled="true" v-model="formData.id">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('table.customer') + ':'" prop="customerCompanyName">
          <el-input :disabled="true" v-model="formData.customerCompanyName">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('table.ETA') + ':'" prop="noEta">
          <el-date-picker v-model="formData.noEtas" :picker-options="pickerOptions" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
            format="dd/MM/yyyy HH:mm:ss" value-format="dd/MM/yyyy HH:mm:ss" @change="Timehangder">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('table.barge') + ':'" prop="bargeName">
          <el-select v-model="formData.bargeName" clearable placeholder="Type here to search..." filterable bargeNameloading remote
            :remote-method="handleSearchBarge" class="bargeNameinput" @change="imochange"
            :loading="isBargeOptionsLoading"
            :disabled="allowChangeBarge()"
            v-cancel-read-only>
            <el-option v-for="item in bargeoptions" :key="item.value" :disabled="item.unFlag == 1"
              :label="item.bargeName ? `${item.imo} (${item.bargeName})` : `${item.imo}`" :value="item.imo">
            </el-option>
          </el-select>
          <div v-if="mvpCompanyData" style="position: absolute; top: 0; right: 7px; display: flex; align-items: center; height: 100%">
            <img width="25" :src="iconOnlyImg" :title="'Company name: '+ mvpCompanyData.name" />
          </div>
        </el-form-item>
        <!-- <el-form-item :label="$t('table.contact') + ':'" prop="contact">
          <el-input v-model="formData.contact" clearable size="small" :style="{ width: '100%' }"></el-input>
        </el-form-item> -->
        <el-form-item label="Nominated Qty Max:" prop="nominatedQtyMax">
          <div class="flex justify-between" v-bind:class="{'flex-col':isMobileScreen}">
            <div class="flex w-full">
              <el-input ref="qtys" oninput="value=value.replace(/[^\d]/g,'')" v-model="formData.nominatedQtyMax" clearable
                size="small" :style="{ width: '100%' }">
                <div slot="append">MT</div>
              </el-input>
            </div>
            <div class="flex" v-if="allowToChangeQuantity" v-bind:class="{'pt-1':isMobileScreen, 'pl-2':!isMobileScreen}">
              <el-button size="mini" type="primary" plain :disabled="false" @click="changeQuantityDialogVisible = true">Change Quantity</el-button>
            </div>
          </div>
          <div v-if="requestChangeQuantity" style="color:#FF5C00; font-size:11px">Requested to change quantity to {{ changeQuantityData.newNominatedQtyMax }} MT </div>
        </el-form-item>
        <el-form-item :label="$t('table.BalanceTank') + ':'" prop="balanceInTank">
          <el-input ref="Tanks" oninput="value=value.replace(/[^\d]/g,'')" v-model.number="formData.balanceInTank"
            clearable size="small" :style="{ width: '100%' }">
            <div slot="append">MT</div>
          </el-input>
        </el-form-item>
        <el-form-item v-if="userInfo.userType !='4'"  :label="$t('table.tanks') + ':'" prop="tlist">
          <!--  v-model="formData.tank" formData.tlist-->
          <el-select multiple  v-model="formData.tlist" placeholder="" clearable size="small" :style="{ width: '100%' }">
            <el-option v-for="(item) in tankOptions" :key="item.value" :label="item.label"
              :value="item.value"
              :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('table.product') + ':'" prop="product">
          <el-select size="small" v-model="formData.product" placeholder="" clearable :style="{ width: '100%' }">
            <el-option v-for="(item, index) in productOptions" :key="index" :label="item.label" :value="item.value"
              :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('table.surveyors') + ':'" prop="slist">
          <!--  v-model="formData.surveyor"   formData.slist -->
          <el-select size="small" v-model="formData.slist"  multiple  placeholder="" :style="{ width: '100%' }">
            <el-option v-for="(item) in surveyorOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('table.doc') + ':'" prop="doc">
          <el-select size="small" v-model="formData.doc" placeholder="" clearable :style="{ width: '100%' }">
            <el-option v-for="(item, index) in docOptions" :key="index" :label="item.label" :value="item.value"
              :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('table.block') + ':'" prop="blockNomination">
          <el-switch size="small" v-model="formData.blockNomination" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('table.remarks') + ':'" prop="remarks">
          <el-input size="small" v-model="formData.remarks" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }"
            :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <el-form-item :label="$t('table.hideFromTerminal') + ':'"
          v-if="(userInfo.userType == 2 || userInfo.role == 5) && (Object.keys(createdtable).length < 1 || (createdtable.status == 0 || createdtable.status == 1))"
          class="formItemHideFromTerminal">
          <el-switch size="small" v-model="formData.hideFromTerminal" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="information">
      <div class="flex head">
        <svg-icon iconClass="boat-two" />
        <p class="title font-bold">{{ $t("table.Opeinfo") }} </p>
      </div>
      <div class="mt-2">
        <el-form ref="elOperationForm" :model="operationData" :rules="rules" size="small" :label-width="isMobileScreen?null:'200px'"
          label-position="left" :disabled="elvisible">
          <el-form-item :label="$t('table.bargeReadiness') + ':'" prop="bargeReadinesss">
            <el-date-picker v-model="operationData.bargeReadiness" :picker-options="pickerOptions" type="datetime" prefix-icon="el-icon-date"
              :style="{ width: '100%' }" format="dd/MM/yyyy  HH:mm:ss" value-format="dd/MM/yyyy HH:mm:ss" size="small"
              @change="Timehangder">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('table.typeofPilot') + ':'" prop="typeOfPilot" class="typeOfPilot">
            <el-select v-model="operationData.typeOfPilot" placeholder="" clearable size="small"
              :style="{ width: '100%' }" @change="typeOfPilotChange(operationData.typeOfPilot)">
              <el-option v-for="(item, index) in pilotOptions" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
            <span class="text" v-if="textvalidate" v-show="formData.blockNomination == '1' ? false : true">
              <p class="textb">
                Please ensure that the master is pilot exempted and that the barge LOA is below 122 meter. Please produce
                a pilot exemption certificate when asked to.
              </p>
            </span>
          </el-form-item>
          <el-form-item v-if="contactdisabled" :label="$t('table.psacontactnumber') + ':'" prop="contactNumber">
            <vue-tel-input :disabled="elvisible" v-model="operationData.contactNumber" mode="international" :style="{ width: '100%' }" @input="onPSAContactNumberChange" required disabledFormatting validCharactersOnly></vue-tel-input>
            <div style="color: #f56c6c; font-size: 12px" v-if="PSAContactNumberIsValid == false">The contact number provided is invalid</div>
          </el-form-item>
          <el-form-item :label="$t('table.contactnumber') + ':'">
            <div v-for="(item, index) in bargeContactNumbers" :key="'contact-' + index" style="position: relative;">
              <vue-tel-input v-model="item.contactNumber" mode="international" @input="(number, phoneObject) => onContactNumberChange(item, phoneObject)" :disabled="elvisible" required disabledFormatting validCharactersOnly></vue-tel-input>
              <el-link v-if="!elvisible" type="danger" style="position: absolute; right: 8px; top: -1px" @click="onContactNumberRemove(index)">Remove</el-link>
              <div style="color: #f56c6c; font-size: 12px" v-if="item.phoneNumberIsValid == false">The contact number provided is invalid</div>
            </div>
            <div v-if="!elvisible" style="display: flex; justify-content: flex-end; cursor: pointer;" @click="addMoreContactNumber">
              <div style="display: flex; flex-direction: row; align-items: center; gap: 5px; padding-top: 4px; color: #82889c;"><i class="el-icon-circle-plus-outline" style="font-size: 20px"></i><div>Click here to add more contact number</div></div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div :class="!textvalidate ? 'information' : 'mt-16'">
      <div class="flex head">
        <i class="el-icon-location-outline fileIcon"></i>
        <p class="title font-bold">{{ $t("table.jettyInformation") }} </p>
      </div>
      <div class="mt-2">
        <el-form ref="elinformaForm" :model="elinformaData" :rules="rules" size="small" :label-width="isMobileScreen?null:'200px'"
          label-position="left" :disabled="Jettyelvisible">
          <el-form-item :label="$t('table.estimatedBerthTiming') + ':'" prop="estimatedBerthTimings">
            <el-date-picker v-show="formData.blockNomination == '1' ? false : true"
              v-model="elinformaData.estimatedBerthTimings" prefix-icon="el-icon-date" format="dd/MM/yyyy HH:mm:ss"
              value-format="dd/MM/yyyy HH:mm:ss" type="datetime" :style="{ width: '100%' }" @change="onEstimatedBerthTimingChange">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('table.jettyNo') + ':'" prop="bargeReadiness">
            <el-select v-show="formData.blockNomination == '1' ? false : true" v-model="elinformaData.jettyNo" clearable
              placeholder="" size="small" :style="{ width: '100%' }">
              <el-option v-for="(item, index) in jettyNoOptios" :key="index" :label="item.berthName"
                :value="item.berthName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('table.berthAssignTiming') + ':'" prop="berthAssignTimings">
            <el-date-picker v-show="formData.blockNomination == '1' ? false : true"
              v-model="elinformaData.berthAssignTimings" prefix-icon="el-icon-date" format="dd/MM/yyyy HH:mm:ss"
              value-format="dd/MM/yyyy HH:mm:ss" type="datetime" :style="{ width: '100%' }" @change="Timehangder">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="information">
      <div class="flex head">
        <i class="el-icon-ship fileIcon"></i>
        <p class="title font-bold">Fresh Water </p>
      </div>
      <div class="mt-2">
        <el-form ref="elFreshWaterForm" :model="elFreshWaterData" :rules="rules" size="small" :label-width="isMobileScreen?null:'200px'" label-position="left">
          <el-form-item label="Fresh Water:" prop="freshWater">
            <el-switch v-model="elFreshWaterData.freshWater" :disabled="allowEditFreshWater"/>
          </el-form-item>
          <el-form-item label="Fresh Water Available:" prop="freshWaterAvailable">
            <el-select v-model="elFreshWaterData.freshWaterAvailable" @change="updateWaterInput" placeholder="Select" size="mini" :style="{ width: '100%' }" :disabled="allowEditFreshWaterAvailable">
              <el-option
                v-for="item in freshWaterAvailableOptions"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Water Voucher:" prop="waterVoucher">
            <el-input v-model="elFreshWaterData.waterVoucher" :disabled="allowEditWaterVoucher"/>
          </el-form-item>
          <el-form-item label="Water Meter:" prop="waterMeter">
            <el-input v-model="elFreshWaterData.waterMeter" :disabled="allowEditWaterVoucher"/>
          </el-form-item>
          
        </el-form>
      </div>
    </div>
    <success-dialog v-on="$listeners" ref="successDialog" @upup="upup" />
    <checkBargeDialog ref="checkBargeDialog" @confirm="handleSureConfirm" :message="message" :isMobileScreen="isMobileScreen"/>
    <!-- <BargeReady @Bargeclick="Bargeclick" v-if="upIndex === 1" /> -->
    <el-dialog title="Send email for onboarding"
      :visible.sync="emailDialogVisible"
      :width="isMobileScreen ? '100%' : '500px'"
    >
      <div>
        <el-form ref="emailForm" :model="emailData" :rules="emailRules" size="small" :label-width="'100px'" label-position="left">
          <el-form-item :label="'Email:'" prop="customerEmail">
            <el-input v-model="emailData.customerEmail" clearable size="small" :style="{ width: '100%' }"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="emailDialogVisible = false">{{ $t("btn.close") }}</el-button>
          <el-button type="primary" size="mini" @click="handleSendEmail">{{ $t("btn.submit") }}</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog title="Change quantity" :visible.sync="changeQuantityDialogVisible" :width="isMobileScreen ? '100%' : '500px'">
      <div>
        <p class="pb-2">After Submitting, the terminal will approve the change before it is updated in the barging nomination</p>
        <el-form ref="changeQuantityForm" :model="changeQuantityData" :rules="changeQuantityRules" size="small" label-position="top">
          <el-form-item prop="changeQuantity">
            <template>
              <div><span style="color: red;">*</span>New Quantity</div>
              <el-input ref="qtys" oninput="value=value.replace(/[^\d]/g,'')" v-model="changeQuantityData.newNominatedQtyMax" clearable
                size="small" :style="{ width: '100%' }">
                <div slot="append">MT</div>
              </el-input>
            </template>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="changeQuantityDialogVisible = false">{{ $t("btn.close") }}</el-button>
          <el-button type="primary" size="mini" @click="handleSubmitChangeQuantity">{{ $t("btn.submit") }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import checkBargeDialog from "./check-barge-dialog.vue";
import { searchBargeBer, resetBerthReason } from '@/services/index';
import { searchBarge, updateNominatedQtyMax } from '@/services/user';
import moment from "moment";
import iconOnlyImg from "@/assets/icon-only.png";
import {
  getMvpCompany,
  BargeAdd,
  Amendnomination,
  Booknomination,
  formTankselect,
  JettyAll,
  formall,
  SendEmail,
  submitFreshWaterAvailable,
} from "@/services/user";
import { getSurveyoOptions } from "@/services";
import SuccessDialog from "@/components/common/success-dialog.vue";
import { mapState } from "vuex";
import { VueTelInput } from 'vue-tel-input';
import axios from "axios";
export default {
  components: { SvgIcon, SuccessDialog, checkBargeDialog, VueTelInput },
  props: {
    // 船舶的传值
    createdtable: {
      type: Object,
      default() {
        return {};
      },
    },
    BargeData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 码头的传值
    terminaldatas: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // value1:[],
      // value2:[],
      source: null,
      iconOnlyImg,
      phoneObject: null,
      bargeContactNumbers: [],
      tempJettyNo: '',
      emailData: {
        customerEmail: "",
      },
      formData: {
        customerCompanyName: "",
        noEta: "",
        bargeName: "",
        contact: "",
        balanceInTank: "",
        product: "",
        doc: "",
        block: false,
        blockNomination: "",
        remarks: "",
        nominatedQtyMax: "",
        noEtas: "",
        vesselName: "",
        id: "",
        hideFromTerminal: "0",
        tlist: [],
        slist: [],
        loadingType: null,
        status: 0,
        
      },
      emailRules: {
        customerEmail: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
        ],
      },
      rules: {
        noEta: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
        ],
        bargeName: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
        ],
        balanceInTank: [
          {
            required: true,
            message: "",
            trigger: "blur",
          }
        ],
        nominatedQtyMax: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
        ],
        tlist: [
          {
            required: true,
            message: "",
            trigger: "blur",
          },
        ],
        jettyNo: [
          {
            required: true,
            trigger: "change",
          },
        ],
        product: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        slist: [],
        doc: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        remarks: [],
        typeOfPilot: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        contactNumber: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
      bargeoptions: [],
      productOptions: [
        {
          label: "LSFO",
          value: "LSFO",
        },
        {
          label: "FO380",
          value: "FO380",
        },
        {
          label: "LSMGO",
          value: "LSMGO",
        },
        {
          label: "FO500",
          value: "FO500",
        },
        {
          label: "FO180",
          value: "FO180",
        },
        {
          label: "HSMGO",
          value: "HSMGO",
        },
        {
          label: "B24 VLSFO",
          value: "B24 VLSFO",
        },
      ],
      surveyorOptions: [],
      docOptions: [
        {
          label: "CQ",
          value: "CQ",
        },
        {
          label: "BL",
          value: "BL",
        },
      ],
      operationData: {
        bargeReadiness: null,
        typeOfPilot: "",
        contactNumber: "",
        bargeContactNumber: "",
        bargeContactNumbers: null,
        freshWater: false,
        id: "",
      },
      elinformaData: {
        jettyNo: "",
        berthAssignTiming: "",
        berthAssignTimings: "",
        estimatedBerthTiming: "",
        estimatedBerthTimings: "",
        id: "",
      },
      elinformaDataFromRes: {
        jettyNo: "",
        berthAssignTiming: "",
        berthAssignTimings: "",
        estimatedBerthTiming: "",
        estimatedBerthTimings: "",
        id: "",
      },
      pilotOptions: [
        {
          label: "PSA",
          value: "PSA",
        },
        {
          label: "IHP",
          value: "IHP",
        },
      ],
      jettyNoOptios: [],
      isEdit: true,
      showOperationInput: true,
      showJettyInput: true,
      formVisible: 0,
      academyData: [],
      timeout: null,
      fromdisabled: false,
      OperationFormVisible: true,
      contactdisabled: false,
      userTypes: "",
      elvisible: false,
      Jettyelvisible: true,
      barges: "",
      shipinfos: {},
      // 模糊搜索
      bargeNameloading: false,
      // 提示文字信息
      textvalidate: false,
      // form船舶loading
      isBargeOptionsLoading: true,
      // formloading
      formDataloading: false,
      message: "",
      mvpCompanyData: null,
      tankOptions: [],
      surveyorOptions: [],
      pickerOptions: {
        disabledDate: this.disabledDate
      }, 
      PSAContactNumberIsValid: null,
      emailDialogVisible: false,
      requestChangeQuantity: false,
      changeQuantityDialogVisible: false,
      changeQuantityData: {
        id: null,
        newNominatedQtyMax: null,
      },
      changeQuantityRules: {
        newNominatedQtyMax: [
          {
            required: true,
            message: "The field is required",
            trigger: "blur",
          },
        ],
      },
      elFreshWaterData: {
        freshWater: false,
        freshWaterAvailable: null,
        waterVoucher: "",
        waterMeter: "",
        id: "",
      },
      elFreshWaterDataFromRes: {
        freshWater: false,
        freshWaterAvailable: null,
        waterVoucher: "",
        waterMeter: "",
        id: "",
      },
      freshWaterAvailableOptions: [
        { id: 0, value: 0, label: 'Not Selected', },
        { id: 1, value: 1, label: 'Yes', },
        { id: 2, value: 2, label: 'No', },
      ],
      grt: null,
    };
  },
  watch: {
    createdtable() {
      this.Setrloe();
      this.GetselectList();
    },
  },
  created() {
    this.Setrloe();
    this.GetselectList();
    if (this.userInfo.userType =='4'&& this.rules?.tlist?.length>0) {
      this.rules.tlist[0].required =false
    }
  },
  directives: {
    cancelReadOnly (el) {
      const input = el.querySelector('.el-input__inner');
      input.removeAttribute('readonly');
    }
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    },
    bargeImo: function () {
      if (this.formData.bargeName) return this.formData.bargeName;
      return null;
    },
    allowToChangeQuantity() {
      return this.userTypes == 2 && 
        (
          (this.createdtable.status == 2 && this.createdtable.loadingType != null) || 
          this.createdtable.status == 3 || this.createdtable.status == 4
        )
    },
    allowEditFreshWater() {
      const isBargeRole = this.$store.state.userInfo.role === '8';    
      const isJettyAssigned =  this.createdtable.status == 2 && this.createdtable.loadingType == null;
      if(isBargeRole && isJettyAssigned){
        return false
      }
      return true;
    },
    allowEditFreshWaterAvailable() {
      // this.elFreshWaterData.freshWater = true;
      const isCCRRole = this.$store.state.userInfo.role === '2';    
      if(isCCRRole && this.elFreshWaterData.freshWater == 1){
        return false;
      }
      return true
    },
    allowEditWaterVoucher() {
      const isCCRRole = this.$store.state.userInfo.role === '2';    
      if(isCCRRole && this.elFreshWaterData.freshWater == 1 && this.elFreshWaterData.freshWaterAvailable == 1){
        return false;
      }
      return true
    },
  },
  methods: {
    updateWaterInput(index){
      if(index == 1){
        this.elFreshWaterData.waterVoucher = this.elFreshWaterDataFromRes.waterVoucher;
        this.elFreshWaterData.waterMeter = this.elFreshWaterDataFromRes.waterMeter == "No water meter available"? "" : this.elFreshWaterDataFromRes.waterMeter;
      }else if(index == 0){
        this.elFreshWaterData.waterVoucher = ""
        this.elFreshWaterData.waterMeter = ""
      }else if(index == 2){
        this.elFreshWaterData.waterVoucher = ""
        this.elFreshWaterData.waterMeter = "No water meter available"
      }
    },
    onPSAContactNumberChange(value, phoneObject) {
      this.PSAContactNumberIsValid = phoneObject.valid == true
    },
    onContactNumberChange: function (item, phoneObject) {
      item.phoneNumberIsValid = phoneObject.valid == true
    },
    onContactNumberRemove: function (_index) {
      this.bargeContactNumbers = this.bargeContactNumbers.filter((contact, index) => {
        return index != _index;
      });
    },
    addMoreContactNumber: function () {
      this.bargeContactNumbers.push({
        contactNumber: "",
        phoneNumberIsValid: null
      });
    },
    noEtaInSeconds: function () {
      if (typeof this.formData.noEtas == "string" && this.formData.noEtas) {
        return moment(this.formData.noEtas, "DD/MM/YYYY hh:mm:ss").unix();
      } else if (typeof this.formData.noEta == "string" && this.formData.noEta) {
        return moment(this.formData.noEta, "DD/MM/YYYY hh:mm:ss").unix();
      } else if (typeof this.formData.noEta == "number" && this.formData.noEta) {
        return this.formData.noEta / 1000;
      } else {
        return null;
      }
    },
    handleImoChange(imo) {
      if (this.source) {
        this.source.cancel();
      }
      const CancelToken = axios.CancelToken;
      this.source = CancelToken.source();

      let timestamp = this.noEtaInSeconds();

      if (imo == null || timestamp == null || isNaN(timestamp)) return;

      this.mvpCompanyData = null;

      getMvpCompany({ imo, timestamp, cancelToken: this.source.token })
        .then(res => {
          this.mvpCompanyData = res.data;
          this.$message.success("Account found in Marine Vessel Pass: " + this.mvpCompanyData.name);
        }).catch((e) => {
          this.emailDialogVisible = true
        });
    },
    handleSureConfirm() {
      if (this.effectCallback) {
        this.effectCallback();
        this.$refs.checkBargeDialog.close();
      }
    },
    Setrloe() {
      this.userTypes = this.$store.state.userInfo.userType;
      // 船舶的值
      if (this.userTypes == 4) {
        this.operationData.id = this.createdtable.id;
        this.fromdisabled = true;
        this.OperationFormVisible = false;
        this.elvisible = this.createdtable.blockNomination >= '2';
      }
      // 码头的值
      if (this.userTypes == 1) {
        if (
          this.$store.state.userInfo.role == 2 &&
          (this.createdtable.status == 1 || this.createdtable.status == 2)
        ) {
          this.Jettyelvisible = false;
        }
        this.elvisible = true;
        this.fromdisabled = true;
      }
      // 客户的值
      if (this.userTypes == 2) {
        this.OperationFormVisible = false;
        this.elvisible = true;
        if (this.$store.state.userInfo.role == 4) {
          this.fromdisabled = true;
        }
      }
      if (this.$route.query.id) {
        this.formDataloading = true;
        this.getdatalist();
      } else {
        this.formData.customerCompanyName = this.$store.state.userInfo.companyName
      }
    },
    // 数据详情列表
    getdatalist() {
      formall(this.$route.query.id).then(async (res) => {
        if (res.data.bargeContactNumber) {
          this.bargeContactNumbers = res.data.bargeContactNumber.split(",").map(c => ({
            contactNumber: c,
            phoneNumberIsValid: true,
          }));
        }
        
        this.contactdisabled = res.data.typeOfPilot === 'PSA';
        //if ((res.data.status == 0 || res.data.status == 1) && (this.$store.state.userInfo.userType == 2 && this.$store.state.userInfo.role != 4)) {
        if (this.$store.state.userInfo.userType == 2 && this.$store.state.userInfo.role == 5 && res.data.blockNomination < '2') {
          this.fromdisabled = false;
        } else {
          this.fromdisabled = true;
        }
        Object.keys(this.formData).forEach((key) => {
          this.formData[key] = res.data[key];
        
        });
        
        this.bargeoptions.forEach((item) => {
          if (item.imo == this.formData.bargeName) {
            let timestamp = this.noEtaInSeconds();
            if (this.source) {
              this.source.cancel();
            }
            const CancelToken = axios.CancelToken;
            this.source = CancelToken.source();
            getMvpCompany({ imo: item.imo, timestamp, cancelToken: this.source.token })
              .then(res => {
                this.mvpCompanyData = res.data;
              });
            this.shipinfos = item;
            // this.shipinfos.lengthOverall = res.data.lengthOverall
          }
        });
        this.formData.noEtas = res.data.noEta;
        this.formData.tlist =res.data?.tlistNomination?.map((tank)=>{
          return tank.tid;
        })
        this.formData.slist = res.data?.slistNomination?.map((tank)=>{
          return tank.sid;
        })
        if (this.tankOptions?.length<1 && res.data.tlistNomination!=null &&res.data.tlistNomination.length>0) {
          this.tankOptions = res.data.tlistNomination.map((item)=>{
          return{
              label:item.tankNumber,
              value:item.tid
            }
          });
        }
        this.grt = res.data.grt
        this.shipinfos.lengthOverall = res.data.lengthOverall
        this.shipinfos.imo = res.data.bargeName
        this.$emit("shipinfos", this.shipinfos);
        // 控制船舶填写权限
        /*
        if (
          (res.data.status == 0 || res.data.status == 1) &&
          this.$store.state.userInfo.userType == 4
        */
       if(
        this.$store.state.userInfo.userType == 4 && res.data.blockNomination < '2'
        ) {
          
          this.elvisible = false;
        } else {
          this.elvisible = true;
        }
        Object.keys(this.operationData).forEach((key) => {
          if (res.data[key]) {
            this.operationData[key] = res.data[key];
          }
        });
        // 控制船舶填写权限
        if (
          (res.data.status == 1 || res.data.status == 2) &&
          this.$store.state.userInfo.userType == 1 &&
          this.$store.state.userInfo.role == 2
        ) {
          this.modifyJettyInformationPermission();
        } else {
          this.Jettyelvisible = true;
        }
        Object.keys(this.elinformaData).forEach((key) => {
          this.elinformaData[key] = res.data[key];
          this.elinformaData.berthAssignTimings = res.data.berthAssignTiming;
          this.elinformaData.estimatedBerthTimings = res.data.estimatedBerthTiming;
        });
        Object.keys(this.elinformaDataFromRes).forEach((key) => {
          this.elinformaDataFromRes[key] = this.elinformaData[key];
        });
        Object.keys(this.elFreshWaterData).forEach((key) => {
          if (res.data[key]) {
            this.elFreshWaterData[key] = res.data[key];
          }
        });
        Object.keys(this.elFreshWaterDataFromRes).forEach((key) => {
          this.elFreshWaterDataFromRes[key] = this.elFreshWaterData[key];
        });
        this.tempJettyNo = this.elinformaData.jettyNo
        this.formDataloading = false;
        this.modifyBargeInformationPermission();
      });

      if (this.createdtable.typeOfPilot == "IHP") {
        this.textvalidate = true;
      } else {
        this.textvalidate = false;
      }
    },
    // 下拉列表
    GetselectList() {
      // Tank列表
      formTankselect().then((res) => {
        if (res.code == 200 && res.data!==null && res.data?.length>0) {
          this.tankOptions = res.data.map((item)=>{
          return{
              label:item.tankNumber,
              value:item.id
            }
          });
        }
      });
      // formSurveyorselect(7, 3).then((res) => {
      //   if (res.code == 200) {
      //     this.surveyorOptions = res.data;
      //   }
      // });
      // 码头Jetty下拉
      JettyAll().then((res) => {
        if (res.code == 200) {
          this.jettyNoOptios = res.rows;
        }
      });
      // Barge下拉
      // getSelectVessel().then((res) => {
      //   if (res.code == 200) {
      //     this.bargeoptions = res.data;
      //     this.bargelogdaing = false;
      //   }
      // });
      getSurveyoOptions().then((res) => {
        if (res.code == 200 && res.data!==null && res.data.length>0){
          this.surveyorOptions = res.data.map((item)=>{
          return{
              label:item.nickName,
              value:item.userId
            }
          });
        }
       
      });
    },
    async handleBerthAssignment(data, callback) {
      const unassignBerth = async () => {
        let res = await Amendnomination(data);
        if (res.code == 200) {
          callback()
          this.tempJettyNo = this.elinformaData.jettyNo
        }
      }
      if (!this.Jettyelvisible && this.elinformaData.jettyNo != null && this.elinformaData.jettyNo.trim() == '' && this.tempJettyNo.trim() != '') {
        try {
          await this.$prompt('Reason', 'Unassign Berth', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            inputType: 'textarea',
            inputPattern: /^(?!\s*$).+/,
            inputErrorMessage: 'Reason is required',
            closeOnClickModal: false,
          })
            .then(({ value }) => {
              resetBerthReason({ bid: this.formData.id, remark: value }).then(() => {
                unassignBerth()
              });
            })
            .catch(() => { });
        } catch (error) {
          this.$emit("loading", "3")
        }
      } else {
        unassignBerth()
      }
    },
    async handleBook( callback ) {
      let res = await Booknomination( this.$route.query.id )
      if (res.code == 200) {
      //  this.$refs.successDialog.open();
      //  this.$emit("loading", "1");
      //  this.$refs.successDialog.message = "Booked successfully!";
        callback();
      }
    },
    submitForm(callback) {
      // 控制 Tank 和 Qty 只能输入数字
      this.userTypes = this.$store.state.userInfo.userType;
      const QtyNumber = this.$refs.qtys;
      this.formData.nominatedQtyMax = QtyNumber.value;
      const TankNumber = this.$refs.Tanks;
      this.formData.balanceInTank = TankNumber.value;
      // todo
      if (this.userTypes == "4") {
        this.operationData.freshWater = this.elFreshWaterData.freshWater
        this.$refs["elOperationForm"].validate((valid) => {
          if( this.PSAContactNumberIsValid == false ) {
            return this.$message.error("Invalid PSA Contact Number");
          }

          if (this.bargeContactNumbers.length > 0) {
            var phoneNumberIsValid = false;
            var result = [];
            this.bargeContactNumbers.forEach((el) => {
              result.push(el.contactNumber);
              phoneNumberIsValid = el.phoneNumberIsValid;
            })
            if (!phoneNumberIsValid) return alert('Please check the provided contact numbers');
            this.operationData.bargeContactNumber = result.join(",");
            this.operationData.bargeContactNumbers = this.bargeContactNumbers;
          } 

          if (this.bargeContactNumbers.length == 0) {
            this.operationData.bargeContactNumber = "";
            this.operationData.bargeContactNumbers = null;
          }

          if (this.operationData.contactNumber) {
            this.operationData.contactNumber = this.operationData.contactNumber;
          }

          if (!valid) return;
          callback();

          let dateFormatted = moment(this.operationData.bargeReadiness, "DD/MM/YYYY hh:mm:ss").format()
          let bargeReadinessValue = new Date(dateFormatted).valueOf()
          
          Amendnomination({
            ...this.operationData,
            lengthOverall: sessionStorage.getItem('lengthOverall'),
            bargeReadiness: bargeReadinessValue > 0 ? bargeReadinessValue : null
          })
            .then((res) => {
              if (res.code == 200) {
                this.$refs.successDialog.show = true;
                this.$refs.successDialog.message =
                  "Information submitted successfully!";
                this.resetForm();
                this.$emit("loading", "1");
              }
            })
            .catch(() => {
              this.$emit("loading", "3");
            });
        });
      }
      if (this.userTypes == "2") {
        if (this.$route.query.id) {
          this.formData.id = this.$route.query.id;
          this.operationData.id = this.$route.query.id;
          this.elinformaData.id = this.$route.query.id;
          this.$refs["elForm"].validate((valid) => {
            if (!valid) return;
            callback();
            Amendnomination({
              ...this.formData,
              lengthOverall: sessionStorage.getItem('lengthOverall'),
              grt: this.grt
            })
              .then((res) => {
                if (res.code == 200) {
                  this.$refs.successDialog.open();
                  this.$refs.successDialog.message =
                    "Changes saved successfully!";
                  this.$emit("loading", "1");
                }
              })
              .catch(() => {
                this.$emit("loading", "3");
              });
          });
        } else {
          this.$refs["elForm"].validate((valid) => {
            if (!valid) return;
            callback();
            BargeAdd({
              ...this.formData,
              lengthOverall: sessionStorage.getItem('lengthOverall'),
              grt: this.grt
            })
              .then((res) => {
                if (res.code == 200) {
                  this.$refs.successDialog.open();
                  this.$refs.successDialog.message =
                    "Barging Nomination created successfully!";
                  this.$refs.successDialog.userAdd = 1;
                  this.$emit("loading", "1");
                }
              })
              .catch((error) => {
                if( error.toString() == 'Error: Selected ship not active in Marine Vessel Pass (MVP). Please contact admin for onboarding.') {
                  this.emailDialogVisible = true
                }
                this.$emit("loading", "3");
              });
          });
        }
      }
      if (this.userTypes == "1") {
        const freshWaterChange = JSON.stringify(this.elFreshWaterData) !== JSON.stringify(this.elFreshWaterDataFromRes)
        const jettyInfoChange = JSON.stringify(this.elinformaData) !== JSON.stringify(this.elinformaDataFromRes)
        if (this.userInfo.role == "2" && !jettyInfoChange && freshWaterChange) {
          this.handleSubmitFreshWaterAvailable()
          callback();
          return
        }
        if (this.userInfo.role == "2" && freshWaterChange) {
          this.handleSubmitFreshWaterAvailable()
        }
        this.$refs["elinformaForm"].validate((valid) => {
          this.$parent.$refs["shipTabView"].$refs["shipInfoForm"].validate((shipFormValid) => {
            if (!shipFormValid) return this.$message.error("Please fill in the overall length of the barge.");
            if (!valid) return;
            let submit = () => {
              this.elinformaData.berthAssignTiming = this.elinformaData.berthAssignTimings
              const berthAssignmentCallback = () => {
                this.$refs.successDialog.open();
                this.$emit("loading", "1");
                this.$refs.successDialog.message =
                  "Information submitted successfully!";
                // this.resetForm();
              }
              this.handleBerthAssignment(
                { ...this.elinformaData, lengthOverall: sessionStorage.getItem('lengthOverall') },
                berthAssignmentCallback
              )
            }
            if (this.elinformaData.berthAssignTimings && this.elinformaData.jettyNo) {
              searchBargeBer({
                berthName: this.elinformaData.jettyNo,
                lengthOverall: sessionStorage.getItem('lengthOverall')
              }).then(result => {
                if (result.code == 200) {
                  submit();
                } else {
                  this.message = result.msg;
                  this.effectCallback = submit;
                  this.$refs.checkBargeDialog.open();
                }
              })
            } else {
              submit();
            }
          })

        });
      }
    },
    resetForm() {
      // this.$refs["elForm"].resetFields();
      // this.$refs["elOperationForm"].resetFields();
      // this.$refs["elinformaForm"].resetFields();
    },
    handleSendEmail() {
      this.$refs["emailForm"].validate((valid) => {
        if(valid) {
          SendEmail( this.emailData ).then(res => {
            this.emailDialogVisible = false
          })
        }
      })
    },
    imochange() {
      this.operationData = {};
      this.textvalidate = false;
      this.handleImoChange(this.bargeImo);
      this.bargeoptions.forEach((item) => {
        if (item.imo == this.bargeImo) {
          this.shipinfos = item;
          this.formData.vesselName = item.bargeName;
          this.grt = item.grossTonnageGt;
        }
      });
      this.$emit("shipinfos", this.shipinfos);
    },
    // 表单时间函数
    Timehangder(val) {
      if (!val) {
        this.elinformaData.berthAssignTiming = null;
        this.elinformaData.berthAssignTimings = null;
        return;
      }
      let a = val.split("/");
      let b = a[2].slice(0, 4);
      let d = a[2].slice(4);
      let c = b + "/" + a[1] + "/" + a[0] + d;
      if (this.userTypes == 2) {
        this.formData.noEta = new Date(c).valueOf();
        this.formData.noEtas = val;
        this.handleImoChange(this.bargeImo);
      }
      if (this.userTypes == 4) {
        this.operationData.bargeReadiness = new Date(c);
      }
      if (this.userTypes == 1) {
        this.elinformaData.berthAssignTiming = new Date(c).valueOf();
        this.elinformaData.berthAssignTimings = val;
      }
    },
    onEstimatedBerthTimingChange(val) {
      if (!val) {
        this.elinformaData.estimatedBerthTiming = null;
        this.elinformaData.estimatedBerthTimings = null;
        return;
      }
      if (this.userTypes == 1) {
        let a = val.split("/");
        let b = a[2].slice(0, 4);
        let d = a[2].slice(4);
        let c = b + "/" + a[1] + "/" + a[0] + d;
        this.elinformaData.estimatedBerthTiming = new Date(c).valueOf();
        this.elinformaData.estimatedBerthTimings = val;
      }
    },

    // 船舶表单控制
    typeOfPilotChange(val) {
      val == "PSA" ? (this.contactdisabled = true) : (this.contactdisabled = false);
      val == "IHP" ? (this.textvalidate = true) : (this.textvalidate = false);
      if( val == "IHP" ) {
       this.operationData.contactNumber = ""
       this.PSAContactNumberIsValid = true 
      }
    },
    upup() {
      if (this.userTypes == 4) {
        this.$emit("upup", "4");
      }
      if (this.userTypes == 2) {
        this.$emit("upup", "2");
      }
      if (this.userTypes == 1) {
        this.$emit("upup", "1");
      }
    },
    async handleSearchBarge(query) {
      if (query !== '') {
        this.isBargeOptionsLoading = true;
        try {
          const res = await searchBarge(query);
          this.bargeoptions = res.data;
        } catch (error) {
          this.bargeoptions = [];
        } finally {
          this.isBargeOptionsLoading = false;
        }
      } else {
        this.bargeoptions = [];
      }
    },
    allowChangeBarge() {
      // customer is not allow to modify barge after barge ready
      return this.userTypes == 2 && this.$store.state.userInfo.role == 5 && this.createdtable.status > 0;
    },
    modifyBargeInformationPermission() {
      // if block nomination is booked, all barge's  information can not modify
      if(this.formData.blockNomination >= "2") {
        this.fromdisabled = true
      }
    },
    modifyJettyInformationPermission() {
      this.Jettyelvisible = true
      if( this.formData.status <= 3 ) {
        if( this.formData.blockNomination < "2" ) {
          this.Jettyelvisible = false
        } else if(this.formData.blockNomination == "2" && ( this.formData.loadingType == null || this.formData.loadingType < 4 )) {
          this.Jettyelvisible = false
        }
      }
    },
    disabledDate( date ) {
      const currentDate = new Date();
      return date < currentDate.setDate(currentDate.getDate() - 1);
    },
    handleSubmitChangeQuantity() {
      this.$refs["changeQuantityForm"].validate(async (valid) => {
        if( valid ) {
          this.changeQuantityData.id = this.createdtable.id
          await updateNominatedQtyMax(this.changeQuantityData).then(res => {
            this.requestChangeQuantity = true
            this.changeQuantityDialogVisible = false
          })
        }
      })
    },
    async handleSubmitFreshWaterAvailable(){
      const data = {
        ...this.elFreshWaterData
      }
      delete data.freshWater;
      await submitFreshWaterAvailable(data).then(res => {
        if(res.code == 200){
          this.$message.success('Save Fresh Water Detail Success');
        }
      })
    }
    // 模糊查询
    // remoteMethod(query) {
    //   if (query !== "") {
    //     this.bargeNameloading = true;
    //     setTimeout(() => {
    //       this.bargeNameloading = false;
    //       this.bargeoptions = this.bargeoptions.filter((item) => {
    //         return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
    //       });
    //     }, 200);
    //   } else {
    //     this.options = [];
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.containerForm {
  padding: 10px 26px;
  background: #fff;

  .head {
    padding-bottom: 6px;
    border-bottom: 1px solid #d5dded;

    >.fileIcon {
      font-size: 20px;
    }

    >p {
      color: #4c565c;
      margin-left: 18px;
      font-size: 13px;
    }
  }

  .form {
    margin-top: 10px;
  }
}

::v-deep.bargeNameinput {
  width: 100%;

  .el-input__inner {
    color: #2e5efd !important;
  }
}

::v-deep .el-form-item--medium .el-form-item__label {
  color: #82889c;
  font-size: 12px;
}

::v-deep .el-input-group__append {
  background: #82889c;
  border-radius: 0px 1px 1px 0px;
  font-size: 12px;
  text-align: center;

  color: #ffffff;
}

::v-deep .el-switch {
  transform: scale(1.2);
}

.information {
  margin-top: 30px;

  .head {
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
    border-bottom: 1px solid #d5dded;
  }

  .svg-icon {
    width: 38px;
    height: 22px;
  }

  .title {
    color: #4c565c;
    margin-left: 9px;
    font-size: 13px;
  }
}

.formItemText {
  font-size: 12px;
  color: #82889c;
}

::v-deep .el-input--small .el-input__inner {
  height: 28px;
}

::v-deep .el-input--small textarea {
  height: 60px !important;
  min-height: 60px !important;
}

.typeOfPilot {
  position: relative;

  .text {
    // position: absolute;
    // top: 35px;
    // left: 0px;
    width: 100%;
    font-size: 10px;
    color: #ff0000;

    .textb {
      line-height: 14px;
    }
  }
}

::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 5px;
}

::v-deep .el-form-item--small .el-form-item__label {
  line-height: 28px;
}

::v-deep .el-form-item__error {
  color: transparent;
}

::v-deep .formItemHideFromTerminal {
  .el-form-item__content {
    margin-left: 0 !important;

    .el-checkbox__label {
      font-weight: normal;
    }
  }
}
</style>
