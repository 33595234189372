<template>
  <div style="background: white" class="p-6 laptop:p-4 mobile:p-2">
    <div class="head flex justify-between">
      <div>
        <i class="el-icon-tickets" />
        <span class="pl-4" style="color: #82889c; font-size: 13px">
          {{ $t("menu.quantity") }}
        </span>
      </div>
      <div>
        <el-button v-if="link && shouldDisplayCertificates" size="small" type="primary" @click="handleQuantityClick">
          {{ $t("common.link") }}
        </el-button>
      </div>
    </div>
    <p style="font-size: 11px; padding-top: 10px; padding-bottom: 10px; color: red;" v-if="isMvpVesselPayActive">Billing will be automatically charged to barge company's credit card after barge signs a Certificate of Quantity</p>
    <div style="border-top: 1px solid #d5dded" class="mt-4"></div>
    <el-table
      :header-cell-style="isMobileScreen ? getMobileViewHeaderStyle : getHeaderStyle"
      :cell-style="isMobileScreen ? getMobileViewCellStyle : getCellStyle"
      :data="quantityTableData"
      style="width: 100%"
      @row-click="handlePdfClick"
    >
      <el-table-column type="index" min-width="30" :label="$t('table.no')" />
      <el-table-column
        prop="originalName"
        label="File Name"
        align="left"
        min-width="90"
      />
      <el-table-column label="Signature Status" min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.mvpDocId">
            <b v-if="scope.row.mvpDocIsSigned == 1">Signed</b>
            <b v-else>Require Signature From Barge</b>
          </div>
          <b v-else>Require Signature From JPUT</b>
        </template>
      </el-table-column>
       <el-table-column v-if="link" width="90">
        <template slot-scope="scope">
          <el-button
            :size="isMobileScreen?'mini':'small'"
            type="primary"
            plain
            @click="handleRemoveLinkClick(scope.row, scope.$index)"
          >
            <span class="remove">{{ $t("btn.remove") }}</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="statusContainer mt-6 p-4" v-if="quantityTableData.length > 0">
      <div class="left flex-none w-30">Signature Status:</div>
      <div class="flex-grow flex flex-row">
        <div
          :class="[
            'flex flex-col',
            sign_type === 2 || sign_type === 3 ? 'active' : '',
          ]"
          style="flex: 0.5"
        >
          <div class="flex justify-center items-center">
            <span class="label">Terminal </span>
            <svg-icon
              v-if="sign_type === 2 || sign_type === 3"
              iconClass="done"
            />
          </div>
          <div class="bar"></div>
        </div>
        <div
          :class="['flex flex-col', sign_type === 3 ? 'active' : '']"
          style="flex: 0.5; margin-left: 4px"
        >
          <div class="flex justify-center items-center">
            <span class="label">Barge </span>
            <svg-icon v-if="sign_type === 3" iconClass="done" />
          </div>
          <div class="bar"></div>
        </div>
      </div>
    </div>
    <div class="head flex justify-between mt-4">
      <div>
        <i class="el-icon-tickets" />
        <span class="pl-4" style="color: #82889c; font-size: 13px">
          {{ $t("menu.quality") }}
        </span>
      </div>
      <el-button
        @click="handleQualityClick"
        v-if="link && shouldDisplayCertificates"
        size="small"
        type="primary"
      >
        {{ $t("common.link") }}
      </el-button>
    </div>
    <div style="border-top: 1px solid #d5dded" class="mt-4"></div>
    <el-table
       :header-cell-style="isMobileScreen ? getMobileViewHeaderStyle : getHeaderStyle"
      :cell-style="isMobileScreen ? getMobileViewCellStyle : getCellStyle"
      :data="qualityTableData"
      style="width: 100%"
      @row-click="handlePdfClick"
    >
      <el-table-column type="index" min-width="30" :label="$t('table.no')" />
      <el-table-column
        prop="originalName"
        label="File Name"
        align="left"
        min-width="80"
      />
      <el-table-column  v-if="link" width="90">
        <template slot-scope="scope">
          <el-button
            :size="isMobileScreen?'mini':'small'"
            type="primary"
            @click="handleRemoveLinkClick(scope.row, scope.$index, 1)"
            plain
          >
            <span class="remove">{{ $t("btn.remove") }}</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="head flex justify-between mt-4">
      <div>
        <i class="el-icon-tickets" />
        <span class="pl-4" style="color: #82889c; font-size: 13px">
          {{ $t("menu.billOfLading") }}
        </span>
      </div>
      <div>
        <el-button v-if="link && shouldDisplayCertificates" size="small" type="primary" @click="handleBillOfLadingClick">
          {{ $t("common.link") }}
        </el-button>
      </div>
    </div>
    <p style="font-size: 11px; padding-top: 10px; padding-bottom: 10px; color: red;" v-if="isMvpVesselPayActive">Billing will be automatically charged to barge company's credit card after barge signs a Bill of Lading</p>
    <div style="border-top: 1px solid #d5dded" class="mt-4"></div>
    <el-table
      :header-cell-style="isMobileScreen ? getMobileViewHeaderStyle : getHeaderStyle"
      :cell-style="isMobileScreen ? getMobileViewCellStyle : getCellStyle"
      :data="billOfLadingTableData"
      style="width: 100%"
      @row-click="handlePdfClick"
    >
      <el-table-column type="index" min-width="30" :label="$t('table.no')" />
      <el-table-column
        prop="originalName"
        label="File Name"
        align="left"
        min-width="90"
      />
      <el-table-column label="Signature Status" min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.mvpDocId">
            <b v-if="scope.row.mvpDocIsSigned == 1">Signed</b>
            <b v-else>Require Signature From Barge</b>
          </div>
          <b v-else>Require Signature From JPUT</b>
        </template>
      </el-table-column>
       <el-table-column v-if="link" width="90">
        <template slot-scope="scope">
          <el-button
            :size="isMobileScreen?'mini':'small'"
            type="primary"
            plain
            @click="handleRemoveLinkClick(scope.row, scope.$index, 2)"
          >
            <span class="remove">{{ $t("btn.remove") }}</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="statusContainer mt-6 p-4" v-if="billOfLadingTableData.length > 0">
      <div class="left flex-none w-30">Signature Status:</div>
      <div class="flex-grow flex flex-row">
        <div
          :class="[
            'flex flex-col',
            sign_type === 2 || sign_type === 3 ? 'active' : '',
          ]"
          style="flex: 0.5"
        >
          <div class="flex justify-center items-center">
            <span class="label">Terminal </span>
            <svg-icon
              v-if="sign_type === 2 || sign_type === 3"
              iconClass="done"
            />
          </div>
          <div class="bar"></div>
        </div>
        <div
          :class="['flex flex-col', sign_type === 3 ? 'active' : '']"
          style="flex: 0.5; margin-left: 4px"
        >
          <div class="flex justify-center items-center">
            <span class="label">Barge </span>
            <svg-icon v-if="sign_type === 3" iconClass="done" />
          </div>
          <div class="bar"></div>
        </div>
      </div>
    </div>
    <el-dialog
      :width="isMobileScreen?'95vw':'90vw'"
      :visible.sync="dialogVisible"
      :title="coqDialogTitle"
    >
      <div class="text-right pr-4">
        <el-button type="primary" @click="handleSaveClick" size="small">{{ $t("btn.save") }}</el-button>
      </div>
      
      <div class="flex h-full p-4" :gutter="isMobileScreen?20:10">
        <div style="flex: 0.4;overflow-y: auto;">
          <el-table
            stripe
            ref="multipleTable"
            :data="linkList"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="tableLoading"
            @selection-change="handleTableSelectionChange"
            @row-click="handleTableRowClick"
            :header-cell-style="isMobileScreen ? getMobileViewHeaderStyle : getHeaderStyle"
            :cell-style="isMobileScreen ? getMobileViewCellStyle : getCellStyle"
          >
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column
              prop="originalName"
              :label="$t('common.FileName')"
              min-width="80"
              align="left"
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              :label="$t('common.DateCreated')"
              show-overflow-tooltip
              min-width="120"
            >
            </el-table-column>
          </el-table>
        </div>
        <div style="flex: 0.6;">
          <pdf-view
            style="padding: 10px; width: 100%; height: 100%; overflow-y: scroll;"
            :dialog="false" 
            :pdfUrl="pdfViewSrc"
            v-model="pdfViewSrc"
          />
        </div>
      </div>
    </el-dialog>
    <pdf-view
      v-if="operationData.src"
      :title="$t('ifm.ViewCertificate')"
      :pdfUrl="operationData.src"
      v-model="showPdfView"
    />
  </div>
</template>

<script>
import PdfView from "@/components/common/pdf-view.vue";
import {
  addBoatLink,
  boatListFile,
  getCoqList,
  deleteBoatFile,
  getCQList,
  getBlList
} from "@/services";
import { mapState } from "vuex";
import SvgIcon from "@/components/SvgIcon.vue";
import { getCellStyle,getMobileViewCellStyle,getMobileViewHeaderStyle,getHeaderStyle } from "@/utils/getCellStyle";
export default {
  props: {
    // 驳船订单id
    bid: {
      default: null,
    },
    // link状态
    statu: {
      type: Number,
      default: 1,
    },
    // 签名类型
    sign_type: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      billOfLadingTableData: [],
      quantityTableData: [],
      qualityTableData: [],
      multipleSelection: [],
      dialogVisible: false,
      coqDialogTitle:'Link Certificates',
      linkCoqList: [],
      linkCqlist: [],
      linkBlList: [],
      pdfViewSrc: "",
      // 0->coq 1->cq 2->bl
      linkType: 0,
      selection: [],
      linkList: [],
      link: false,
      showPdfView: false,
      operationData: {
        src: "",
      },
      tableLoading: true,
    };
  },
  components: { PdfView, SvgIcon },
  computed: {
    ...mapState(["userInfo","screenType"]),
    ...mapState(["mvpVesselPayFlag"]),
    shouldDisplayCertificates() {
      return process.env.VUE_APP_SHOW_CERTIFICATES == "true" || process.env.VUE_APP_SHOW_CERTIFICATES === true;
    },
    isMvpVesselPayActive() {
      return this.mvpVesselPayFlag == '0';
    },
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  watch: {
    bid(val) {
      if (!isNaN(val)) {
        this.getLinkList();
        this.getBoatListFile();
      }
    },
    sign_type: {
      handler() {
        if (
          (this.sign_type == 1 || this.sign_type == 0) &&
          ( this.isLeader() || this.isCCR() )
        ) {
          this.link = true;
        }
        if (this.sign_type == 2 || this.sign_type == 3) {
          this.link = false;
        }
      },
      immediate: true,
    },
    quantityTableData: {
      handler() {
        this.$emit("lists");
      },
    },
    qualityTableData: {
      handler() {
        this.$emit("lists");
      },
    },
  },
  created() {
    if (!isNaN(this.bid)) {
      this.getLinkList();
      this.getBoatListFile();
    }
    this.$emit("lists");
  },
  mounted() {
    // console.log( 'userInfo:', this.userInfo )
  },
  methods: {
    getMobileViewHeaderStyle,
    getHeaderStyle,
    getCellStyle,
    getMobileViewCellStyle,
    handlePdfClick(item, row, event) {
      let target = event.target;
      if (target.tagName === "BUTTON" || target.classList.contains("remove"))
        return;
      this.operationData = {
        src: process.env.VUE_APP_BASE_API + item.url,
      };
      this.showPdfView = true;
    },
    // getCellStyle({ rowIndex }) {
    //   if (rowIndex % 2 === 1) {
    //     return {
    //       backgroundColor: "rgba(255,255,255,0.2)",
    //     };
    //   } else {
    //     return {
    //       backgroundColor: "rgba(228,228,228,0.2)",
    //     };
    //   }
    // },
    handleSaveClick() {
      // coq 上传附件
      if (!this.selection.length) return;

      let data = this.selection.map((item) => {
        return {
          bid: this.bid,
          type: this.linkType,
          pid: item.id,
          originalName: item.originalName,
          url: item.url,
        };
      });
      addBoatLink(data).then(() => {
        this.dialogVisible = false;
        this.$message.success(this.$t("status.success"));
        this.getBoatListFile();
      });
    },
    getBoatListFile() {
      if (!this.bid) return;
      boatListFile({ BId: this.bid }).then((res) => {
        this.quantityTableData = res.rows.filter((row) => row["type"] === 0);
        this.qualityTableData = res.rows.filter((row) => row["type"] === 1);
        this.billOfLadingTableData = res.rows.filter((row) => row["type"] === 2);
      });
    },
    getLinkList(type = 0) {
      if (!this.bid) return;
      getCoqList({ id: this.bid, status: 0 }).then((res) => {
        this.linkCoqList = this.handleUrl(res.rows);
        if (type === 0) {
          this.linkList = this.linkCoqList.slice();
          this.tableLoading = false;
        }
      });
      getCQList(this.bid).then((res) => {
        this.linkCqList = this.handleUrl(res.data);
        if (type === 1) {
          this.linkList = this.linkCqList.slice();
          this.tableLoading = false;
        }
      });
      getBlList({ id: this.bid, status: 0 }).then((res) => {
        this.linkBlList = this.handleUrl(res.rows);
        if (type === 2) {
          this.linkList = this.linkBlList.slice();
          this.tableLoading = false;
        }
      })

      if (this.statu == 4) {
        this.link = false;
      }
      if (
        (this.sign_type == 1 || this.sign_type == 0) &&
        ( this.isLeader() || this.isCCR() )
      ) {
        this.link = true;
      }
      if (this.sign_type == 2 || this.sign_type == 3) {
        this.link = false;
      }
    },
    isLeader() {
      return this.userInfo.userType == 1 && this.userInfo.role == 3
    },
    isCCR() {
      return this.userInfo.userType == 1 && this.userInfo.role == 2
    },
    handleTableSelectionChange(val) {
      this.selection = val;
      let last = this.selection[this.selection.length - 1];
      if (last) {
        this.pdfViewSrc = last.src;
      } else {
        this.pdfViewSrc = "";
      }
    },
    handleTableRowClick(row) {
      this.pdfViewSrc = row.src;
    },
    handleUrl(rows) {
      return rows.map((row) => {
        return { ...row, src: process.env.VUE_APP_BASE_API + row.url };
      });
    },
    handleQuantityClick() {
      this.pdfViewSrc = this.linkCoqList[0]?.src;
      this.linkType = 0;
      this.coqDialogTitle = this.$t('table.Lcq')
      this.dialogVisible = true;
      this.linkList = [];
      this.tableLoading = true;
      this.getLinkList(0);
    },
    handleQualityClick() {
      this.linkType = 1;
      this.pdfViewSrc = this.linkCqList[0]?.src;
      // this.linkList = this.linkCqList.slice();
      this.coqDialogTitle = this.$t('table.Lcoq')

      this.dialogVisible = true;
      this.getLinkList(1);
    },
    handleBillOfLadingClick() {
      this.linkType = 2;
      this.pdfViewSrc = this.linkBlList[0]?.src;
      this.coqDialogTitle = this.$t('table.Lbl');
      this.dialogVisible = true;
      this.getLinkList(2);
    },
    handleRemoveLinkClick(item, index, type = 0) {
      deleteBoatFile(item.id).then(() => {
        this.$message.success(this.$t("status.success"));
        if (type == 0) {
          this.quantityTableData.splice(index, 1);
        } else if (type == 1) {
          this.qualityTableData.splice(index, 1);
        } else if (type == 2) {
          this.billOfLadingTableData.splice(index, 1);
        }
      });
    },
    handlePreviewClick() {},
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-table::before {
  height: 0;
}
.Cerload {
  display: flex;
}
::v-deep .el-dialog__header {
    padding-bottom: 5px !important;
}
::v-deep .el-dialog__body {
  height: 80vh;
  padding-top: 10px;
}
::v-deep .el-dialog {
  margin-top: 5vh !important;
  margin-bottom: 0px !important;
}
.statusContainer {
  border: 1px solid #dfe1e7;

  .left {
    font-size: 12px;
    color: #82889c;
    font-weight: 600;
    line-height: 150%;
  }

  .active {
    .bar {
      background: #25cf36;
    }
    .label {
      color: #25cf36;
    }
  }
  .bar {
    height: 5px;
    background: #dedede;
  }
  .label {
    font-weight: 500;
    font-size: 13px;
    line-height: 26px;
    color: #dedede; 
  }
  .svg-icon {
    width: 12px;
    height: 10px;
    margin-left: 5px;
  }
}
::v-deep .el-table__cell > .cell{
  padding-left: 3px;
  padding-right: 2px;
  b{
    word-break:keep-all;
  }
}
::v-deep th.el-table__cell > .cell{
  padding-left: 3px;
  padding-right: 1px;
  b{
    word-break:keep-all;
  }

}
</style>
