<template>
  <div  v-loading="loading">
    <div class="flex justify-end py-3">
      <el-button v-if="allowLogisticsModifyForm" size="mini" style="font-size: 12px" type="primary" @click="saveLogisticsModifyForm">Save</el-button>
      <el-button v-if="showSignBtn()" size="mini" style="font-size: 12px" type="primary" @click="doSign">Sign</el-button>
      <el-button v-if="showSave()" size="mini"  style="font-size: 12px" type="primary" @click="save">Save</el-button>
      <el-button plain size="mini" style="font-size: 12px" type="primary" @click="goBack">Close</el-button>
    </div>
    <template v-if="showAfterOrBefore">
      <div class="hidden content md:block" style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
        <div class="w-full flex">
          <div
              v-for="(item,index) in list" :key="item.name" :class="returnClass(item,index)"
              class=" w-full text-center item  cursor-pointer p-2 f-500"
              style="border-top: none; "
              @click="clickBefore(item,index)">
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="flex">
          <div @click="clickBeforeChild(item,index)"
               :class="{activeChild:activeChild===index,start:index===0,end:index===list[active].children.length-1}"
               v-for="(item,index) in list[active].children" :key="item.name"
               class="w-full text-center item  cursor-pointer p-2"
               style="border-top: none;border-bottom: none;flex-wrap: wrap">
            <div class="name">{{ item.name }}</div>
            <div v-if="item.type" style="width: 100%;display: flex;align-items: center;justify-content: center">
              <template v-if="item.type==1">
                <div class="items-center" style="display: flex">
                  <div :style="{background:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                    <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                  </div>
                  <div style="font-size: 12px">Terminal</div>
                </div>
                <div class=" items-center ml-6" style="display: flex">
                  <div :style="{background:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                    <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                  </div>
                  <div style="font-size: 12px">Barge</div>
                </div>
              </template>
              <template v-if="item.type==2">
                <div class="items-center" style="display: flex">
                  <div :style="{background:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                    <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                  </div>
                  <div style="font-size: 12px">Terminal</div>
                </div>
              </template>
              <template v-if="item.type==8">
                <div class=" items-center" style="display: flex">
                  <div :style="{background:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                    <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                  </div>
                  <div style="font-size: 12px">Barge</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="block mt-5 before-form md:hidden w-full" style="border-top-left-radius: 10px;border-top-right-radius: 10px;background-color:#fff;">
        <div class="content mt-5" style="border-top-left-radius: 10px;border-top-right-radius: 10px;border:none;">
          <div class="flex">
            <div v-for="(item,index) in list"
                 :key="item.name" :class="{active:active===index,start:index===0,end:index===list.length-1}"
                 class=" w-full text-center item  cursor-pointer p-2 f-400"
                 style="border-top: none;height: unset;border: none "
                 @click="val=item.children[0].name;active=index;activeChild=0;activeCom=list[index].children[0].component">
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
        <el-select v-model="val" class="w-full" @change="changeCom">
          <el-option v-for="item in list[active].children" :label="item.name" :value="item.name">
            <template>
              <div class="flex justify-between p-1">
                <div>{{ item.name }}</div>
                <div v-if="item.type" style="width: 200px;display: flex;align-items: center;justify-content: center;">
                  <div v-if="item.type==1 || item.type==2" class="items-center px-2" style="display: flex">
                    <div  :style="{background:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                      <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                    </div>
                    <div style="font-size: 12px">Terminal</div>
                  </div>
                  <div v-if="item.type==1 || item.type==8" class=" items-center px-2" style="display: flex">
                    <div :style="{background:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                      <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                    </div>
                    <div style="font-size: 12px">Barge</div>
                  </div>
                </div>
              </div>
            </template>
          </el-option>
        </el-select>
      </div>
    </template>
    <template v-else>
      <div class="hidden content md:block" style="border-top-left-radius: 10px;border-top-right-radius: 10px;">
        <div class="flex">
          <div style="border-top: none;border-bottom: none;flex-wrap: wrap"
               @click="activeAfter=index;activeCom=item.component;updateForm()"
               v-for="(item,index) in listAfter" :key="item.name"
               class="w-full text-center item  cursor-pointer p-2"
               :class="{activeChild:activeAfter===index,start:index===0,end:index===listAfter.length-1,light:statusObj[item.status]===1}">
            <div>{{ item.name }}</div>
            <div v-if="item.type" style="width: 100%;display: flex;align-items: center;justify-content: center">
              <template v-if="item.type==1">
                <div class="items-center" style="display: flex">
                  <div :style="{background:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                    <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                  </div>
                  <div style="font-size: 12px">Terminal</div>
                </div>
                <div class=" items-center ml-6" style="display: flex">
                  <div :style="{background:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                    <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                  </div>
                  <div style="font-size: 12px">Barge</div>
                </div>
              </template>
              <template v-if="item.type==2">
                <div class="items-center" style="display: flex">
                  <div :style="{background:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                    <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                  </div>
                  <div style="font-size: 12px">Terminal</div>
                </div>
              </template>
              <template v-if="item.type==8">
                <div class=" items-center" style="display: flex">
                  <div :style="{background:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                    <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                  </div>
                  <div style="font-size: 12px">Barge</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="block md:hidden w-full" style="border-top-left-radius: 10px;border-top-right-radius: 10px;background-color:#fff;">
        <el-select v-model="val1" class="w-full" @change="changeAfter">
          <el-option v-for="(item,index) in listAfter" :label="item.name" :value="item.name">
            <template>
              <div class="flex justify-between p-1">
                <div>{{ item.name }}</div>
                <div v-if="item.type" style="width: 200px;display: flex;align-items: center;justify-content: center;">
                  <div v-if="item.type==1 || item.type==2" class="items-center px-2" style="display: flex">
                    <div  :style="{background:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==2||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                      <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                    </div>
                    <div style="font-size: 12px">Terminal</div>
                  </div>
                  <div v-if="item.type==1 || item.type==8" class=" items-center px-2" style="display: flex">
                    <div :style="{background:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':'#fff',borderColor:statusObj[item.status]==3||statusObj[item.status]==1?'#1CBB19':''}" class="circle" style="display: flex">
                      <i class="el-icon-check" style="color: #fff;font-size: 12px"></i>
                    </div>
                    <div style="font-size: 12px">Barge</div>
                  </div>
                </div>
              </div>
            </template>
          </el-option>
        </el-select>
      </div>
    </template>
    <div class="pl-2 pr-2" style="background-color: white; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
      <component :is="activeCom" ref="form" :nominationData="data" :disabled="disabled" :modifyReason="modifyForm.modifyReason" :logisticsModify="allowLogisticsModifyForm" :sign="sign" :beforeAfterFlag="showAfterOrBefore ? 0 : 1" @update="update"></component>
    </div>
    <sign-document ref="signRef" :visible.sync="signShow" @close="signShow=false" @signSubmit="signSubmit"></sign-document>
    <el-dialog title="Reason to Modify" :visible.sync="modifyReasonDialogVisible" :width="isMobileScreen ? '100%' : '500px'" @click="resetLogisticsModifyForm()">
      <div>
        <el-form :model="modifyForm" label-width="auto" size="mini" label-position="top" ref="modifyForm" :rules="rules">
          <el-form-item label="Please state the reason to modify:" prop="modifyReason" >
            <el-input type="textarea" v-model="modifyForm.modifyReason" clearable size="small" :style="{ width: '100%' }" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="resetLogisticsModifyForm()">{{ $t("btn.close") }}</el-button>
          <el-button type="primary" size="mini" @click="submitLogisticsModifyForm()">{{ $t("btn.submit") }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import StatusLine from "@/view/barging/components/statusLine.vue";
import PollutionPrevention from "@/view/barging/components/formComponents/PollutionPrevention.vue";
import AfterMooring from "@/view/barging/components/formComponents/AfterMooring.vue";
import PilotMovement from "@/view/barging/components/formComponents/PilotMovement.vue";
import PolotLadderDeclaration from "@/view/barging/components/formComponents/PilotLadderDeclaration.vue";

import PreTransfer from "@/view/barging/components/formComponents/PreTransfer.vue";
import RepetitiveChecksTanker from "@/view/barging/components/formComponents/RepetitiveChecksTanker.vue";
import SafatyRequirements from "@/view/barging/components/formComponents/SafatyRequirements.vue";
import RequiredOnDeck from "@/view/barging/components/formComponents/RequiredOnDeck.vue";

import RepetitiveChecksTerminal from "@/view/barging/components/formComponents/RepetitiveChecksTerminal.vue";
import PurchaseAndSale from "@/view/barging/components/formComponents/PurchaseAndSale.vue";
import WalkieTalkie from "@/view/barging/components/formComponents/WalkieTalkie.vue";
import RecordingReport from "@/view/barging/components/formComponents/RecordingReport.vue";
import UllageReport from "@/view/barging/components/formComponents/UllageReport.vue";
import DeclarationOfSecurity from "@/view/barging/components/formComponents/DeclarationOfSecurity.vue";
import SignDocument from "@/view/barging/components/signDocument.vue";

import ReceiptOfWalkie from "@/view/barging/components/formComponents/ReceiptOfWalkie.vue";
import MeterReadingReport from "@/view/barging/components/formComponents/MeterReadingReport.vue";
import UllageReportEs from "@/view/barging/components/formComponents/UllageReportEs.vue";
import TimeSheet from "@/view/barging/components/formComponents/TimeSheet.vue";
import PreLoading from "@/view/barging/components/formComponents/PreLoading.vue";
import ReceiptOfSamples from "@/view/barging/components/formComponents/ReceiptOfSamples.vue";
import CargoNomination from "@/view/barging/components/formComponents/CargoNomination.vue";
import {signForm} from "@/services/form";

export default {
  name: 'BargeForm',
  components: {SignDocument, StatusLine},
  props: {
    status: {
      type: String,
      default: '-1'
    },
    showAfter: {
      type: Boolean,
      default: false
    },
    showBefore: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
    }
  },
  data() {
    return {
      statusObj: {},
      signShow: false,
      val: '',
      val1: '',
      list: [
        {
          name: 'Checklist',
          children: [
            {
              name: 'Pollution Prevention',
              component: PollutionPrevention,
              status: 'checkPollutionPreventionStatus',
              type:'1'
            },
            {
              name: 'After Mooring',
              component: AfterMooring,
              status: 'checkPollutionMooringStatus',
              type:'1'
            },
            {
              name: 'Pre-Transfer',
              component: PreTransfer,
              status: 'checkPollutionTransferStatus',
              type:'1'
            },
            {
              name: 'Time Sheet',
              component: TimeSheet,
              status: 'beforeSheetStatus',
              type:'1'
            },
            {
              name: 'Repetitive Checks - Tanker',
              component: RepetitiveChecksTanker,
              status: 'checkPollutionTankerBeforeStatus',
              type:'8'
            },
            {
              name: 'Repetitive Checks - Terminal',
              component: RepetitiveChecksTerminal,
              status: 'checkPollutionTerminalBeforeStatus',
              type:'2'
            },
          ]
        },
        {
          name: 'Declaration',
          children: [
            {
              name: "Vessel's Checklist for Pilot Movement",
              component: PilotMovement,
              status: 'declarationMovementStatus',
              type:'8'
            },
            {
              name: 'Pilot Ladder Declaration',
              component: PolotLadderDeclaration,
              status: 'declarationDeclarationStatus',
              type:'8'
            },
            {
              name: 'Safety Requirements',
              component: SafatyRequirements,
              status: 'declarationRequirementsStatus',
            },
            {
              name: 'Number of Watch Crew Required on Deck',
              component: RequiredOnDeck,
              status: 'declarationDeckStatus',
              type:'8'
            },
            {
              name: 'Declaration on the Use,Purchase and Sale',
              component: PurchaseAndSale,
              status: 'declarationSaleStatus',
              type:'8'
            },
            {
              name: 'Issue of Walkie Talkie',
              component: WalkieTalkie,
              status: 'declarationTalkieStatus',
              type:'2'
            },
            {
              name: 'Totaliser Recording Report',
              component: RecordingReport,
              status: 'declarationReportStatus',
              type:'2'
            },
            {
              name: 'Ullage Report',
              component: UllageReport,
              status: 'declarationUllageStatus',
              type:'1'
            },
            {
              name: 'Pre-Loading/Pre-Discharging Information',
              component: PreLoading,
              status: 'afterInformationStatus',
              type:'1'
            },
            {
              name: 'Cargo Nomination',
              component: CargoNomination,
              status: 'afterNominationStatus',
              type:'1'
            },
            {
              name: 'Declaration of Security',
              component: DeclarationOfSecurity,
              status: 'declarationSecurityStatus',
              type:'1'
            },
          ]
        }
      ],
      listAfter: [
        {
          name: 'Receipt of Walkie Talkie',
          component: ReceiptOfWalkie,
          status: 'afterTalkieStatus',
          type:'2'
        },
        {
          name: 'Total Quantity Loaded',
          component: MeterReadingReport,
          status: 'afterReadingStatus',
          type:'2'
        },
        {
          name: 'Ullage Report',
          component: UllageReportEs,
          status: 'afterReportStatus',
          type:'1'
        },
        {
          name: 'Time Sheet',
          component: TimeSheet,
          status: 'afterSheetStatus',
          type:'1'
        },
        {
          name: 'Receipt of Samples',
          component: ReceiptOfSamples,
          status: 'afterSamplesStatus',
          type:'1'
        },
        
        {
          name: 'Repetitive Checks - Tanker',
          component: RepetitiveChecksTanker,
          status: 'checkPollutionTankerAfterStatus',
          type:'8'
        },
        {
          name: 'Repetitive Checks - Terminal',
          component: RepetitiveChecksTerminal,
          status: 'checkPollutionTerminalAfterStatus',
          type:'2'
        },
      ],
      active: 0,
      activeChild: 0,
      activeCom: '',
      activeAfter: 0,
      isShow: false,
      sign: {
        terminal:{},
        barge:{},
        terminalB:{},
        bargeB:{}
      },
      loading:false,
      allowLogisticsModifyForm: false,
      modifyReasonDialogVisible: false,
      modifyForm: {
        modifyReason: "",
      },
      rules: {
        modifyReason: [
          {required: true, message: 'Reason cannot be empty', trigger: 'blur'},
          {required: true, transform: (values) => values && values.trim() ,message: 'Reason cannot be empty', trigger: 'blur'},
        ],
      },
    }
  },
  watch: {
    status: {
      handler(newV) {
        /*
        if (this.status === '2' || this.status === '2-1' || this.status === '2-2' || this.status === '2-3') {
          this.active = 0
          const num = 0
          this.activeChild = num
          this.activeCom = this.list[this.active].children[num].component
          this.val = this.list[this.active].children[num].name
          this.isShow = true
        }
        if (this.status === '2-4' || this.status === '2-5' || this.status === '2-6' || this.status === '2-7') {
          const num = 0
          this.activeAfter = num
          this.activeCom = this.listAfter[num].component
          this.isShow = false
        }
        */
        let loadingType = this.data.loadingType
        if ( loadingType < 4 ) {
          this.active = 0
          const num = 0
          this.activeChild = num
          this.activeCom = this.list[this.active].children[num].component
          this.val = this.list[this.active].children[num].name
          this.isShow = true
        } else if ( loadingType < 8) {
          const num = 0
          this.activeAfter = num
          this.activeCom = this.listAfter[num].component
          this.isShow = false
        }
      },
      immediate: true
    },
    data: {
      handler(newV) {
        if (newV) {
          this.statusObj = {
            ...newV.nominationFormStatus
          }
          if (this.showAfterOrBefore) {
            this.sign.terminal=this.data.formsSigns.filter(item => item.signType == 1&&item.type==2)[0]||{}
            this.sign.barge=this.data.formsSigns.filter(item => item.signType == 1&&item.type==1)[0]||{}
          } else {
            this.sign.terminalB=this.data.formsSigns.filter(item => item.signType == 1&&item.type==2)[0]||{}
            this.sign.bargeB=this.data.formsSigns.filter(item => item.signType == 1&&item.type==1)[0]||{}
            this.sign.terminal=this.data.formsSigns.filter(item => item.signType == 2&&item.type==2)[0]||{}
            this.sign.barge=this.data.formsSigns.filter(item => item.signType == 2&&item.type==1)[0]||{}
          }
        }
      },
      deep: true,
      immediate: true
    },
    showAfterOrBefore: {
      handler(newV) {
        if (newV) {
          this.sign.terminal=this.data.formsSigns.filter(item => item.signType == 1&&item.type==2)[0]||{}
          this.sign.barge=this.data.formsSigns.filter(item => item.signType == 1&&item.type==1)[0]||{}
        } else {
          this.sign.terminalB=this.data.formsSigns.filter(item => item.signType == 1&&item.type==2)[0]||{}
          this.sign.bargeB=this.data.formsSigns.filter(item => item.signType == 1&&item.type==1)[0]||{}
          this.sign.terminal=this.data.formsSigns.filter(item => item.signType == 2&&item.type==2)[0]||{}
          this.sign.barge=this.data.formsSigns.filter(item => item.signType == 2&&item.type==1)[0]||{}
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    userInfo() {      
      return this.$store.state.userInfo
    },
    disabled() {
      /*
      if (this.status === '2' || this.status === '2-1' || this.status === '2-2' || this.status === '2-3' || this.status === '2-4' || this.status === '2-5' || this.status === '2-6' || this.status === '2-7') {
        if (this.userInfo.role === '2' || this.userInfo.role === '8') {
          return false
        }
      }
      */
      const editableForm = this.activeCom.name == "RecordingReport" || this.activeCom.name == "MeterReadingReport" || this.activeCom.name == "UllageReport" || this.activeCom.name == "TimeSheet";
      const isLogisticsRole = this.$store.state.userInfo.role == '1';
      const isCompltedNomination = this.data.status == '4';
      if (editableForm && isLogisticsRole && isCompltedNomination) {
        this.allowLogisticsModifyForm = true
      }

      if(this.activeCom.name == "MeterReadingReport" || this.activeCom.name == "RecordingReport"){
        if(this.allowLogisticsModifyForm){
          return false
        }
        // equivalent to :disabled="disabled||$store.state.userInfo.role!='11'"
        if(this.userInfo.role === '11' && (this.data.status == 2 || this.data.status == 3)){
          return false
        }
        return true
      }
      if(this.allowLogisticsModifyForm){
        return false
      }
      if(this.data.status == 2 || this.data.status == 3) {
        if (this.userInfo.role === '11' || this.userInfo.role === '8') {
          return false
        }
      }
      
      return true
    },
    showAfterOrBefore() {
      if (this.showAfter) {
        const num = 0
        this.activeAfter = num
        this.activeCom = this.listAfter[num].component
        this.val1 = this.listAfter[num].name
        return false
      }
      if (this.showBefore) {
        const num = 0
        this.activeChild = num
        this.activeCom = this.list[this.active].children[num].component
        this.val = this.list[this.active].children[num].name
        return true
      }
      if (this.isShow) {
        const num = 0
        this.activeChild = num
        this.activeCom = this.list[this.active].children[num].component
        this.val = this.list[this.active].children[num].name
        return true
      } else {
        const num = 0
        this.activeAfter = num
        this.activeCom = this.listAfter[num].component
        return false
      }
    },
  },
  methods: {
    update(){
      this.updateForm()
      this.loading=false
    },
    updateForm() {
      this.$emit('update')
    },
    returnClass(item, index) {
      const arr = []
      if (this.active === index) {
        arr.push('active')
      }
      if (index === 0) {
        arr.push('start')
      }
      if (index === this.list.length - 1) {
        arr.push('end')
      }
      return arr
    },
    clickBeforeChild(item, index) {
      this.allowLogisticsModifyForm = false;
      this.val = item.name;
      this.activeChild = index;
      this.activeCom = item.component
      this.updateForm()
    },
    clickBefore(item, index) {
      this.allowLogisticsModifyForm = false;
      this.val = item.children[0].name;
      this.active = index;
      this.activeChild = 0;
      this.activeCom = this.list[index].children[0].component
    },
    goBack() {
      this.$router.push('/barging/index')
    },
    save() {
      this.loading=true
      this.$refs.form && this.$refs.form.save()
    },
    changeCom(val) {
      this.allowLogisticsModifyForm = false;
      const item = this.list[this.active].children.find(item => item.name === val)
      this.activeCom = item.component
    },
    changeAfter(val) {
      this.allowLogisticsModifyForm = false;
      const item = this.listAfter.find(item => item.name === val);
      const index = this.listAfter.indexOf(val);
      this.activeAfter = index;
      this.activeCom = item.component;
      this.updateForm();
    },
    // submit() {
    //   this.$refs.form.submit()
    // },
    doSign() {
      this.signShow = true
      this.$nextTick(() => {
        this.$refs.signRef.clear()
      })
    },
    signSubmit(form) {
      let loadingType = this.data.loadingType
      const data = {
        name: form.name,
        eSignature: form.eSignature,
        signType: '',
        nominationId: this.$route.query.id
      }
      /*
      if (this.status === '2' || this.status === '2-1' || this.status === '2-2' || this.status === '2-3') {
        data.signType = 1
      }
      if (this.status === '2-4' || this.status === '2-5' || this.status === '2-6' || this.status === '2-7') {
        data.signType = 2
      }
      */
      if(this.data.status == 2 || this.data.status == 3) {
        if( loadingType < 4 ) {
          data.signType = 1
        } else if( loadingType < 8 ) {
          data.signType = 2
        }
      }
      signForm(data).then(res => {
        this.$message.success('Sign Success!')
        this.signShow = false
        this.$emit('update')
      })
    },
    showSave() {
      if (this.$store.state.userInfo.role !== '11' && this.$store.state.userInfo.role !== '8') {
        return false
      }
      /*
      if (this.status === '2-4' || this.status === '2-5' || this.status === '2-6' || this.status === '2-7' || this.status === '2' || this.status === '2-1' || this.status === '2-2' || this.status === '2-3') {
        return true
      }
      */
      if( this.data.status == '2' || this.data.status == '3') {
        return true
      }
      
    },
    showSignBtn() {
      if (this.$store.state.userInfo.role !== '11' && this.$store.state.userInfo.role !== '8') {
        return false
      }
      let loadingType = this.data.loadingType
      if (this.showAfterOrBefore) {
        //before
        const arr = []
        this.list.map(item => {
          item.children.map(it => {
            arr.push({
              key: it.status,
              value: ''
            })
          })
        })
        arr.map(item => {
          item.value = this.statusObj[item.key]
        })
        if (this.data && this.data.formsSigns) {
          const item = this.data.formsSigns.find(item => item.createBy == this.$store.state.userInfo.userId && item.signType == 1)
          if (item) {
            return false
          }
        }
        /*
        if (this.status === '2' || this.status === '2-1' || this.status === '2-2' || this.status === '2-3') {
          if (arr.every(item => item.value === 1)) {
            return true
          }
        }
        */
        if(this.data.status == 2 || this.data.status == 3) {
          if(loadingType < 4) {
            if (arr.every(item => item.value === 1)) {
              return true
            }
          }
        }
      } else {
        //after
        const arr = []
        this.listAfter.map(item => {
          arr.push({
            key: item.status,
            value: ''
          })
        })
        arr.map(item => {
          item.value = this.statusObj[item.key]
        })
        if (this.data && this.data.formsSigns) {
          const item = this.data.formsSigns.find(item => item.createBy == this.$store.state.userInfo.userId && item.signType == 2)
          if (item) {
            return false
          }
        }
        /*
        if (this.status === '2-4' || this.status === '2-5' || this.status === '2-6' || this.status === '2-7') {
          if (arr.every(item => item.value === 1)) {
            return true
          }
        }
        */
        if(this.data.status == 2 || this.data.status == 3) {
          if(loadingType > 3 && loadingType < 8) {
            if (arr.every(item => item.value === 1)) {
              return true
            }
          }
        }
      }
    },
    saveLogisticsModifyForm(){
      this.modifyReasonDialogVisible = true;
    },
    async submitLogisticsModifyForm(){
      const isValid = await new Promise((resolve) => {
        this.$refs.modifyForm.validate((valid) => {
          if (!valid) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
      });
      if (!isValid) {
        return;
      }
      this.save();
      this.resetLogisticsModifyForm();
    },
    resetLogisticsModifyForm(){
      this.$refs.modifyForm.clearValidate();
      this.modifyReasonDialogVisible = false;
      this.modifyForm.modifyReason = "";
    },
  }
}
</script>
<style scoped lang="scss">
* {
  font-size: 12px;
}

.content {
  border: 1px solid #D5DDED;
  background-color: #fff;
}

.item {
  border: 1px solid #D5DDED;
  position: relative;
  min-height: 20px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border-right: none;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.active {
  background-color: #203447;
  color: #fff;
  font-weight: 700;
}

.active.start {
  border-top-left-radius: 10px;
}

.active.end {
  border-top-right-radius: 10px;
}

.activeChild {
  div{
    font-weight: 600 !important;
  }

  .name {
    color: #008CDB;
  }
}

.activeChild::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background-color: #008CDB;
  transition: all .15s;
}

.start {
  border-left: none;
}

.end {
  border-right: none;
}

::v-deep .el-table {
  border: 1px solid #EBEEF5;
  border-bottom: none;
}

::v-deep .el-table th.el-table__cell.is-leaf, .el-table td.el-table__cell {
  border-bottom: 1px solid #EBEEF5 !important;
}

::v-deep .el-table td.el-table__cell {
  border-bottom: 1px solid #EBEEF5 !important;

  .cell {
    font-size: 12px;
    font-weight: 600;
    color: #82889C;
  }
}

::v-deep th.el-table__cell {
  background-color: #F4F8FB;
}

::v-deep th.el-table__cell {
  .cell {
    background: #F4F8FB;
    color: #4C565C;
    font-size: 13px;
    font-weight: 500
  }
}

.light {
  //background-color: #8BFF89 !important;

  div {
    //color: #0CB509 !important;
  }
}
.circle{
  width: 12px;
  height: 12px;
  border: 2px solid #CFCFCF;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: #fff;
  }
}
.before-form{
  margin-top: -1.25rem;
}
</style>
