<template>
<div class="form p-12">
  <div class="f-500 black">
    Totaliser Readings (Before Loading)
  </div>
  <div class="w-full xl:w-3/5">
    <div class="item mt-4">
      <div class="label fs-12"><span style="color:red">*</span> Hose:</div>
      <div class="value w-full mt-2  xl:max-w-xs xl:mt-0">
        <el-select v-model="declarationRecordReport.hose" style="width: 100%;" @change="getLatestHoseAfterLoadingReading" :disabled="disabled" size="mini">
          <el-option v-for="(hose) in hoses" :key="hose" :label="hose" :value="hose"></el-option>
        </el-select>
      </div>
    </div>
    <div class="item mt-4">
      <div class="label fs-12"><span style="color:red">*</span> JL:</div>
      <div class="value w-full mt-2  xl:max-w-xs xl:mt-0">
        <el-select v-model="declarationRecordReport.jl" style="width: 100%;" :disabled="disabled" size="mini">
          <el-option v-for="(jl) in jlOptions" :key="jl" :label="jl" :value="jl"></el-option>
        </el-select>
      </div>
    </div>
    <div class="item mt-4">
      <div class="label fs-12">
        <span style="color:red">*</span> Reading Before Loading:
      </div>
      <div class="value w-full mt-2  xl:max-w-xs xl:mt-0">
        <el-input v-model="declarationRecordReport.readBeforeLoad" :disabled="disabled" :step="0.1" size="mini" type="number" >
          <template slot="append">
            m³
          </template>
        </el-input>
        <p v-if="latestHoseReading"><small>The last Reading After Loading was: {{ latestHoseReading }}</small></p>
        <p v-if="latestHoseReading && readingNumberNotValid" style="color: red;"><small>"Reading Before Loading" value must be equal or higher than the last completed nomination’s "Reading After Loading"</small></p>

      </div>
    </div>
    <div class="item mt-4">
      <div class="fs-12 label">Image</div>
      <div v-if="! isLoadingOrCoSigning && declarationRecordReport?.beforeImage == null">No Image</div>
      <div v-else>
        <div>
          <el-image
            v-if="declarationRecordReport.beforeImage"
            style="width: 170px; height: 170px;"
            :src="declarationRecordReport.beforeImage"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :initial-index="4"
            fit="fill"
          >
          </el-image>
          <input
            type="file"
            ref="beforeImage"
            accept="image/*"
            :multiple="false"
            :disabled="disabled"
            @change="handleFileChange"
            style="opacity: 0; position: fixed; z-index: -1"
          />
        </div>
        <div>
          <el-button size="mini" plain @click="() => $refs.beforeImage.click()" :disabled="disabled">
            <svg-icon :icon-class="'upload'"></svg-icon>
          </el-button>
          <el-button v-if="declarationRecordReport.beforeImage" size="mini" plain @click="handlePreviewImage">
            <svg-icon :icon-class="'zoom-in'"></svg-icon>
          </el-button>
          <el-button v-if="declarationRecordReport.beforeImage" size="mini" plain @click="handleRemoveFile" :disabled="disabled">
            <svg-icon :icon-class="'delete'"></svg-icon>
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <ul class="mt-4">
    <li>I have examined and taken the readings of the meter before and after the loading operation.</li>
    <li>I hereby confirm that the quantity of product loaded on board my vessel as stated in this Meter Reading Report is correct.</li>
  </ul>
  <div class="mt-4 f-500" style="color:#4C565C ">
    Signature
  </div>
  <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
    <div class="w-full sm:w-1/2 ">
      <div class="f-600">Terminal Representative</div>
      <div class="mt-4 f-600" style="font-size: 12px">
        <span>Name:</span>
        <span class="ml-2">{{sign.terminal.name}}</span>
      </div>
      <div class="mt-4 f-600" style="font-size: 12px">
        <span>Date and Time:</span>
        <span class="ml-2">{{sign.terminal.createTime}}</span>
      </div>
      <div class="mt-4 f-600 flex" style="font-size: 12px">
        <span>Signature:</span>
        <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
      </div>
    </div>
    <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
      <div class="f-600">Barge Representative</div>
      <div class="mt-4 f-600" style="font-size: 12px">
        <span>Name:</span>
        <span class="ml-2">{{sign.barge.name}}</span>
      </div>
      <div class="mt-4 f-600" style="font-size: 12px">
        <span>Date and Time:</span>
        <span class="ml-2">{{sign.barge.createTime}}</span>
      </div>
      <div class="mt-4 f-600 flex" style="font-size: 12px">
        <span>Signature:</span>
        <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
      </div>
    </div>
  </div>
  <el-dialog :visible.sync="dialogVisible" :width="isMobileScreen ? '90vw' : '500px'" title="Preview">
    <div class="text-center w-full">
      <img w-full :src="dialogImageUrl" alt="Preview Image" style="margin: 0px auto" />
    </div>
  </el-dialog>
</div>
</template>
<script>
import {saveOrUpdateForms, selectFromById, getLatestHoseReading} from "@/services/form";
import {singleleUploadFile} from "@/services";
import { jettyHoseList } from "@/utils/constants";

export default {
  name: "RecordingReport",
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
    },
    nominationData: {
      type:Object
    },
    modifyReason:{
      type: String,
    },
    logisticsModify:{
      type: Boolean,
    }
  },
  data(){
    return {
      declarationRecordReport:{},
      formType:'',
      params:{},
      hoses: [],
      jlOptions:[],
      dialogVisible: false,
      dialogImageUrl: '',
      jl:'',
      latestHoseReading: null,
      readingNumberNotValid: null,
    }
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods:{
    save(){
      if(typeof this.latestHoseReading === "number" && this.declarationRecordReport.readBeforeLoad < this.latestHoseReading){
        this.readingNumberNotValid = true
        this.$emit('update')
        this.$message.error('Failed to Save')
      }else{
        this.readingNumberNotValid = false
        const data = {
          declarationRecordReport:{
            ...this.declarationRecordReport,
            nominationId : this.params.id
          },
          nominationId : this.params.id,
          formType:this.formType,
          reasonToModifyTotaliserRecord: this.modifyReason
        }
        saveOrUpdateForms(data).then(res=>{
          this.readingNumberNotValid = false
          this.getDetail()
          this.$emit('update')
          this.$message.success('Save Success')
        }).catch(()=>{
          this.$emit('update')
        })
      }
    },
    getDetail(){
      selectFromById({
        nId: this.params.id,
        fId: 12
      }).then(res=>{
        this.declarationRecordReport = res.data.declarationRecordReport
        this.formType = res.data.formType
        this.getLatestHoseAfterLoadingReading();
        this.getJLHostList();
      })
    },
    getJLHostList(){
      if(this.nominationData.jettyNo){
        const jetty = jettyHoseList.find(item => item.jettyNo === this.nominationData.jettyNo);
        this.jlOptions = jetty ? jetty.jl.sort() : [];
        this.hoses = jetty ? jetty.hoses.sort() : [];
      } else {
        this.jlOptions = [];
        this.hoses = [];
      }
    },
    handlePreviewImage() {
      this.dialogImageUrl = this.declarationRecordReport.beforeImage
      this.dialogVisible = true
    },
    handleFileChange(event) {
      let files = event.target.files
      if (files.length) {
        singleleUploadFile( files[0] ).then((res) => {
          if( res.code == 200 ) {
            this.declarationRecordReport.beforeImage = res.url
            this.dialogImageUrl = res.url
          } 
        })
      }
    },
    handleRemoveFile() {
      this.declarationRecordReport.beforeImage = ''
    },
    async getLatestHoseAfterLoadingReading(){
      if(!this.declarationRecordReport.hose || this.declarationRecordReport.hose == ""){
        // this.latestHoseReading = "Not found"
        return
      }
      const stauts = this.logisticsModify? 1 : 3
      const data = {
        nominationId: this.params.id,
        status: stauts,
        hose: this.declarationRecordReport.hose
      }
      await getLatestHoseReading(data).then(res => {
        if(res.data){
          this.latestHoseReading = res.data
        }else{
          this.latestHoseReading = "Not found"
        }
      })

    },
  },
  computed: {
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    },
    isLoadingOrCoSigning() {
      return [2,3].includes(this.nominationData.status)
    },
  }
}
</script>


<style scoped lang="scss">
.form {
  font-size: 15px;
  color: #82889C;
  background-color: #fff;
}
.item{
  display: flex;
  flex-wrap: wrap;
  .label{
    width: 150px;
  }
  .el-textarea{
    flex: 1;
  }
}

ul{
 list-style: decimal;
  padding-left: 15px;
}
</style>
