<template>
  <div class="form p-12">
    <div class="f-600">Equipment Check</div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-2/5 ">

        <div class="mb-4">
          <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
          <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
        </div>
        
        <el-table :data="list">
          <el-table-column label="Equipment" prop="formDescription">
            <template slot-scope="scope">
              <span style="color:red">*</span>{{scope.row.formDescription}}
            </template>
          </el-table-column>
          <el-table-column label="In Good Working Order">
            <template slot-scope="scope">
              <el-checkbox-group v-model="scope.row.check" :disabled="disabled||$store.state.userInfo.role!='8'" @change="(e)=>changeCheck(e,scope.row)">
                <el-checkbox label="1">Yes</el-checkbox>
                <el-checkbox label="0">No</el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
        </el-table>

        <div class="mt-4">
          <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
          <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
        </div>
        
      </div>
      <div class="w-full xl:flex-1 xl:ml-4">
        <div class="f-400">
          Any defects in, and any matter peculiar to the ship and its machinery and equipment of which the Master knows
          and which might materially affect the navigation of the ship:
        </div>
        <el-input v-model="pilotMovementMatter" :disabled="disabled||$store.state.userInfo.role!='8'" :maxlength="600" :rows="5" class="mt-2" show-word-limit type="textarea"></el-input>
        <div class="f-600 mt-4">
          NOTE: The Master shall at times bear the full responsibility of the vessel’s movements in and out of the
          terminal and any liability arising in connection therewith. The Pilot shall act only as a navigation adviser
          to the Master of the vessel for movements of the vessel in and out of the terminal but the Master remains
          solely and entirely responsible for any and all steps, action and omission connected with such movements.
          Where a tug is engaged to assist in the berthing or unberthing of the vessel, the tug, her owners and/or the
          charterers shall not be liable for any loss or damage whatsoever and howsoever caused by the acts and
          omissions (including any act or omission of a negligent nature) on the part of her Mater and crew, the Pilot
          on board, her owners and charterers. It is further understood that this provision is intended for the benefit
          of the tug, her owners and charterers, for the purposes of the Contracts (Rights of Third Parties) Act, even
          if these parties may not be in any contractual relationship with the vessel, her owners or charterers, cargo
          interests or any other party interested in the shipment.
        </div>
        <div class="f-400 mt-4">I hereby declare that the information provided above is to the best of my knowledge true
          and correct.
        </div>
      </div>
    </div>
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";

export default {
  name: "PilotMovement",
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
     }
  },
  data() {
    return {
      list: [],
      formType: '',
      pilotMovementMatter: '',
      params:{},
      checkedYes: false,
      checkedYesDisabled: false,
    }
  },
  created() {
    let role = this.getRole()
    this.checkedYesDisabled = this.disabled || ( role != '8' )
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    getRole() {
      return this.$store.state.userInfo.role;
    },
    save(){
      const data = {
        list:this.list.map(item=>{
          return {
            ...item,
            check:item.check?item.check.join(','):'',
            nominationId:this.params.id
          }
        }),
        formType:this.formType,
        pilotMovementMatter:this.pilotMovementMatter,
        nominationId:this.params.id
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    changeCheck(e,item) {
      item.check=  [item.check[item.check.length-1]]
    },
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 6
      }).then(res => {
        this.list = res.data.list.map(item=>{
          return {
            ...item,
            check:item.check?item.check.split(','):[]
          }
        })
        this.formType = res.data.formType
        this.pilotMovementMatter = res.data.pilotMovementMatter
      })
    },
    checkedTanker() {
      this.list.map(item => {
        if(item.check.length == 0 || item.check[0] != '0') {
          item.check = this.checkedYes ? ['1'] : []
        }
      })
    },
    checkedAllYes() {
      if( this.checkedYesDisabled != true ) {
        this.checkedYes = ! this.checkedYes
        this.checkedTanker();
      }
    },
  }
}
</script>


<style scoped lang="scss">
.form {
  background-color: #fff;
  font-size: 15px;
  color: #82889C;
}


</style>
