import { render, staticRenderFns } from "./RequiredOnDeck.vue?vue&type=template&id=9d2864d4&scoped=true&"
import script from "./RequiredOnDeck.vue?vue&type=script&lang=js&"
export * from "./RequiredOnDeck.vue?vue&type=script&lang=js&"
import style0 from "./RequiredOnDeck.vue?vue&type=style&index=0&id=9d2864d4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d2864d4",
  null
  
)

export default component.exports