<template>
  <div class="p-4 form xl:p-12 " style="background-color:#fff;">
    <div class="f-400" style="color: #82889C">It is the Master’s responsibility at all times to ensure that every
      precaution is taken to prevent pollution.
    </div>
    <div class="mt-4">
      <span style="font-size: 12px;color: #82889C" class="f-600"><span style="color:red">*</span>Operation Type:</span>
      <el-select v-model="operationType" class="ml-1" filterable  size="mini" :disabled="disabled">
        <el-option value="Discharging" label="Discharging"></el-option>
        <el-option value="Loading" label="Loading"></el-option>
        <el-option value="Ballast" label="Ballast"></el-option>
        <el-option value="Bunkering" label="Bunkering"></el-option>
      </el-select>
    </div>
    <div class="mt-4">
      <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all as Yes</el-button>
      <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Unchecked all as Yes</el-button>
    </div>
    
    <el-table :data="list" class="hidden mt-4 md:block">
      <el-table-column align="center" label="Item" prop="formItem" width="80"></el-table-column>
      <el-table-column label="Description">
        <template slot-scope="scope">
          <span style="color:red">*</span>{{ scope.row.formDescription }}
        </template>
      </el-table-column>
      <el-table-column label="Yes" width="100" align="center">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.yes" :disabled="disabled" false-label="0" true-label="1"
                       @change="(e)=>changeCheck('yes',scope.$index)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="No" width="100" align="center">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.no" :disabled="disabled" false-label="0" true-label="1"
                       @change="(e)=>changeCheck('no',scope.$index)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="Comments" width="250">
        <template slot-scope="scope">
          <el-input v-model="scope.row.comments" :disabled="disabled"  size="mini"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-form class="block mt-4 md:hidden">
      <el-form-item v-for="(item,index) in list">
        <div class="black" style=" padding-left: 1rem;text-indent: -1rem;line-height: 1.2rem">
          {{ item.formItem + '.' }}<span>{{ item.formDescription }}</span></div>
        <div class="content black flex pl-4 ">
          <div class="text-center">
            <div style="height: 1rem;margin-bottom: 1rem">Yes</div>
            <el-checkbox v-model="item.yes" :disabled="disabled" false-label="0" true-label="1"
                         @change="(e)=>changeCheck('yes',index)"></el-checkbox>
          </div>
          <div class="ml-4 mr-4 text-center">
            <div style="height: 1rem;margin-bottom: 1rem">No</div>
            <el-checkbox v-model="item.no" :disabled="disabled" false-label="0" true-label="1"
                         @change="(e)=>changeCheck('no',index)"></el-checkbox>
          </div>
          <div class="flex-1">
            <div style="height: 1rem;margin-bottom: 1rem">Comments</div>
            <el-input v-model="item.comments" :disabled="disabled"  size="mini"></el-input>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="flex mt-4">
      <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Checked all as Yes</el-button>
      <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Unchecked all as Yes</el-button>
    </div>
    <div class="flex mt-4">
      <span style="font-size: 12px" class="f-600">Remarks:</span>
      <el-input v-model="remarks" :maxlength="600" :rows="3" class="ml-1" show-word-limit type="textarea"></el-input>
    </div>
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{ sign.terminal.name }}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{ sign.terminal.createTime }}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;"/>
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{ sign.barge.name }}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{ sign.barge.createTime }}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveChecklistPollutionPrevention, saveFormByKey, saveOrUpdateForms, selectFromById} from "@/services/form";

export default {
  name: "PollutionPrevention",
  props: {
    disabled: {
      type: Boolean
    },
    sign: {
      type: Object
    }
  },
  data() {
    return {
      list: [],
      remarks: '',
      operationType: '',
      params: {},
      formType: '',
      checkedYes: false,
      checkedYesDisabled: false,
    }
  },
  created() {
    this.checkedYesDisabled = this.disabled
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    save() {
      const data = {
        operationType: this.operationType,
        formType: this.formType,
        remarks: this.remarks,
        list: this.list,
        nominationId: this.params.id
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 1
      }).then(res => {
        this.list = res.data.list
        this.list.map(item => {
          item.nominationId = this.params.id
          if (item.check === '0') {
            this.$set(item, 'no', '1')
            this.$set(item, 'yes', '')
          } else if (item.check === '1') {
            this.$set(item, 'yes', '1')
            this.$set(item, 'no', '0')
          } else {
            this.$set(item, 'yes', '')
            this.$set(item, 'no', '')
          }
        })
        this.formType = res.data.formType
        this.remarks = res.data.remarks
        this.operationType = res.data.operationType
      })
    },
    changeCheck(key, index) {
      if (key === 'yes') {
        this.list[index].no = this.list[index].yes === '1' ? '0' : this.list[index].no
      } else {
        this.list[index].yes = this.list[index].no === '1' ? '0' : this.list[index].yes
      }
      this.list[index].check = this.list[index].yes === '1' ? '1' :this.list[index].no==='1'?'0':''
    },
    tickYes() {
      this.list.map( (item, index) => {
        if(item.no !== '1') {
          item.yes = '1'
          this.changeCheck('yes', index)
        }
      })
    },
    removeTickYes() {
      this.list.map( (item, index) => {
        if(item.yes === '1') {
          item.yes = '0'
          this.changeCheck('yes', index)
        }
      })
    },
    checkedAllYes() {
      this.checkedYes = ! this.checkedYes
      this.checkedYes ? this.tickYes() : this.removeTickYes()
    },
  }
}
</script>


<style scoped lang="scss">
.form {
  font-size: 15px;
  color: #82889C;
}


</style>
