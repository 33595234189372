
<template>
  <div class="p-4 form xl:p-12" style="background-color:#fff;">
    <div>
      Parts 3 & 4-Tanker & Terminal: checks-After Mooring
    </div>
    <!-- 表格 -->
    <div class="mt-4">
      <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
      <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
    </div>
    <el-table :data="list" :header-cell-style="{ background: '#F2F6FC' }" class="hidden mt-4 md:block" row-key="item">
      <el-table-column align="center" label="Item" prop="formItem" width="80"></el-table-column>
      <el-table-column label="Check" prop="formDescription">
        <template slot-scope="scope">
          <span style="color:red">*</span>{{scope.row.formDescription}}
        </template>
      </el-table-column>
      <el-table-column label="Terminal" prop="terminal" width="100" align="center">
        <template slot-scope="scope">
          <el-checkbox v-if="scope.$index<4" v-model="scope.row.terminal" :disabled="$store.state.userInfo.role!=='11'||disabled" false-label="0" true-label="1"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="Tanker" prop="tanker" width="100" align="center">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.tanker" :disabled="$store.state.userInfo.role!=='8'||disabled" :false-label="2" true-label="1"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="Remarks" prop="remarks" width="250">
        <template slot-scope="scope">
          <el-input v-model="scope.row.remarks" :disabled="disabled"  size="mini"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-form class="block mt-4 md:hidden">
      <el-form-item v-for="(item, index) in list" >
        <div class="black" style=" padding-left: 1rem;text-indent: -1rem;line-height: 1.2rem">{{item.formItem+'.'}}<span >{{ item.formDescription }}</span></div>
        <div class="content black flex pl-4">
          <div class="text-center">
            <div style="height: 1rem;margin-bottom: 1rem">Terminal</div>
            <el-checkbox v-if="index<4" v-model="item.terminal" :disabled="$store.state.userInfo.role!=='11'||disabled" false-label="0" true-label="1"></el-checkbox>
          </div>
          <div class="ml-4 mr-4 text-center" >
            <div style="height: 1rem;margin-bottom: 1rem">Tanker</div>
            <el-checkbox v-model="item.tanker" :disabled="$store.state.userInfo.role!=='8'||disabled" false-label="0" true-label="1"></el-checkbox>
          </div>
          <div class="flex-1">
            <div style="height: 1rem;margin-bottom: 1rem">Remarks</div>
            <el-input v-model="item.remarks" :disabled="disabled"  size="mini"></el-input>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="mt-4">
      <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
      <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
    </div>
    <!-- 签名 -->
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import {saveFormByKey, saveOrUpdateForms, selectFromById} from "@/services/form";

export default {
  name: 'AfterMooring',
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
     }
  },
  data() {
    return {
      list:[],
      params:{},
      formType:'',
      checkedYes: false,
      checkedYesDisabled: false,
    }
  },
  created() {
    let role = this.getRole()
    this.checkedYesDisabled = this.disabled || ( role != '11' && role != '8' )
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    getDetail(){
      selectFromById({
        nId: this.params.id,
        fId: 2
      }).then(res=>{
        this.list = res.data.list.map(item=>{
          return {
            ...item,
            nominationId:this.params.id
          }
        })
        this.formType = res.data.formType
      })
    },
    getRole() {
      return this.$store.state.userInfo.role;
    },
    save(){
      const data = {
        formType:this.formType,
        list:this.list,
        nominationId:this.params.id
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    checkedTanker() {
      this.list.map(item => {
        item.tanker = this.checkedYes ? '1' : '0'
      })
    },
    checkedTerminal() {
      this.list.map( (item, index) => {
        if( index < 4 ) {
          item.terminal = this.checkedYes ? '1' : '0'
        }
      })
    },
    checkedAllYes() {
      if( this.checkedYesDisabled != true ) {
        this.checkedYes = ! this.checkedYes
        let role = this.getRole()
        if( role ==='11' ) {
          this.checkedTerminal();
        } else if( role ==='8' ) {
          this.checkedTanker();
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  font-size: 14px;
  color: #82889C;
}
</style>
